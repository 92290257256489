<template>
    <div class="mapContainer" >    
      <v-card flat>
        
        <v-card-title v-if="p_title">
          <v-icon left color="orange lighten-1">mdi-map marker</v-icon>
          {{ $t('map') }}
        </v-card-title>
        
        <v-card-text>
          <vl-map data-projection="EPSG:4326" 
            :controls="true" 
            :load-tiles-while-animating="true" 
            :load-tiles-while-interacting="true" 
            style="height: 370px"
            >
            <vl-view :zoom.sync="p_zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>

            <vl-layer-tile id="osm">
              <vl-source-osm></vl-source-osm>
            </vl-layer-tile>

            <vl-feature v-for="point in points" :key="point.name">
              <vl-geom-point :coordinates="[point.longitude, point.latitude]"></vl-geom-point>
              <vl-style-box>
                <vl-style-circle :radius="10">
                  <vl-style-fill :color="getStatusColor(point.status)"></vl-style-fill>
                  <vl-style-stroke color="gray"></vl-style-stroke>
                </vl-style-circle>
                <vl-style-text :text="point.name" :offsetY="-15" font="14px sans-serif">
                  <vl-style-fill color="#000000"/>
                  <vl-style-stroke color="gray"/>
              </vl-style-text>
              </vl-style-box>
            </vl-feature>
            
            <vl-feature>
              <vl-geom-circle :coordinates="[longitude, latitude]" :radius="p_radius"></vl-geom-circle>
            </vl-feature>

          </vl-map>
        </v-card-text>
        
        
      </v-card>
    </div>
</template>

<style scoped>
  .mapContainer {
    height: 100%;
  }
</style>

<script>

export default {
  props: {
    longitude: Number,
    latitude : Number,
    radius: Number,
    points: Array,
    zoom: Number,
    showTitle: {
      type: Boolean,
      default: true
    }, 
  },
  data() {
    return {
      center: [this.longitude, this.latitude],
      rotation: 0,
      // private variables, not linked to prop
      // Use private ones to avoid reset on parent component rerender
      p_zoom: this.zoom || 2, 
      p_radius : this.radius*1000, // input in km, must be converted in meters
      p_title: this.showTitle
    };
  },
  watch: {
    radius: function (val) {
      this.p_radius = val*1000;  // input in km, must be converted in meters
    }, 
    longitude : function(val){
      this.center = [val, this.latitude];
    },
    latitude : function(val){
      this.center = [this.longitude, val];
    }
  },
  methods: {
    getStatusColor: function(status){
      return {
        'NONE': 'gray',
        'ALERT': 'red',
        'NORMAL': 'green'
      }[status] || 'gray';
    },
  },
  created: function() {
    // maybe some concurrence issue, I need to set again after a while, in order to get center on map
    window.setTimeout(() => {
      this.center = [this.longitude, this.latitude];
    }, 1000)
  },
}
</script>
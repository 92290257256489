import SysUtility from './SysUtility.js'

/**
 * Creates a custom points component
 * @class
 */
export default class SysPoint{

    #pointContainer
    #ref
    #transitionTime
    #tooltip
    #dataset
    #axisMode

    /**
     * 
     * @param {*} params - allowed params: 
     *  ref {Object} - graph object
     *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects
     *  axisMode {Number} - used to discriminate axis available. Allowed values 2,3
     */
    constructor(params){
      this.#ref = params.ref || null;
      this.#pointContainer = null;
      this.#transitionTime = params.transitionTime || 500;
      this.#axisMode = params.axisMode || this.#ref.getAxisMode();
      this.#tooltip = this.#ref.getTooltip();
      this.#dataset = null;

      this.init();
    }

    /**
     * Creates a point layer
     */
    init(){
      this.#pointContainer = this.#ref.getSVG()
        .append("g")
        .attr("clip-path", `url(#slc_${this.#ref.getExternalId()}_clip)`)
        .attr("id", "pointsContainer");
    }

    /**
     * Gets updated data from reference
     */
    updateReferenceData(){
      this.#dataset = this.#ref.getDataset();  
    }

    /**
     * Draws points 
     */
    drawPoints(){

      this.updateReferenceData();

      this.#pointContainer
        .selectAll("pointsContainer")
        .data(this.#dataset)
        .enter()
        .append("g")
        .attr("class", d => `${d.groupName}`)
        .attr("stroke", d => this.#ref.getGroupColor(d.groupName) )
        .attr("fill", d => this.#ref.getGroupColor(d.groupName) )
        .selectAll("pointsContainer")
        .data(d => d.data)
        .enter()
        .append("circle")
          .style("opacity", 0)
          .style("r", this.#ref.getConfigParam("pointRadius"))  
          .attr("class", "sys-lc-circle")
          .attr("r", this.#ref.getConfigParam("pointRadius")) // firefox compatibility
          .attr("cx", (d) => this.#ref.getXScale()(d.date) )
          .attr("cy", (d) => {
            if(this.#axisMode == 2){
              return this.#ref.getYScaleLeft()(+d.value);
            } else {
              if(d.axis == 'left'){
                return this.#ref.getYScaleLeft()(+d.value);
              } else {
                return this.#ref.getYScaleRight()(+d.value);
              }
            }
          })
          .attr("data-group", d => d.group )
          .attr("data-date", (d) => SysUtility.formatTime(this.#ref.timeOutFormat, d.date) )
          .attr("data-value", (d) => SysUtility.parseValue(d.value) )
          .attr("data-type", (d) => d.type )
          .on("mouseover", this.#tooltip.pointMouseover)
          .on("mousemove", this.#tooltip.dataPointMousemove)
          .on("mouseleave", this.#tooltip.pointMouseleave)
          .transition().duration(this.#transitionTime).style("opacity", 1);
    }
    
    /**
     * Updates points
     */
    updatePoints(){
      this.#pointContainer
      .selectAll(".sys-lc-circle")
      .transition()
      .duration(this.#transitionTime)
      .attr("r", this.#ref.getConfigParam("pointRadius")) // firefox compatibility
      .attr("cx", (d) => this.#ref.getXScale()(d.date) )
      .attr("cy", (d) => {
        if(this.#axisMode == 2){
          return this.#ref.getYScaleLeft()(+d.value);
        } else {
          if(d.axis == 'left'){
            return this.#ref.getYScaleLeft()(+d.value);
          } else {
            return this.#ref.getYScaleRight()(+d.value);
          }
        }
       });
    }

    /**
     * Deeletes all points from layer
     */
    clearAll(){
      this.#pointContainer.selectAll("*").transition().duration(this.#transitionTime).style("opacity", 0).remove();
    }

    /**
     * Updates all points colors in container
     * @param {*} group 
     * @param {*} color 
     */
    updateColor(group, color){
      this.#pointContainer.selectAll(`.${group}`)
      .transition().duration(this.#transitionTime)
      .attr("stroke", color )
      .attr("fill", color )
    }

    /**
     * Updates radius width on all points
     * @param {*} radius 
     */
    updateRadius(radius){
      this.#pointContainer.selectAll('.sys-lc-circle')
      .style("r", radius) 
      .attr("r", radius); // firefox compatibility
    }
}
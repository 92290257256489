<template>
    <v-dialog
        v-model="plantsDialog"
        width="500"
        
      >
        <v-card :loading="loading">
            <v-toolbar
                color="indigo"
                dark
            >
                <v-toolbar-title>{{ $t("plants") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="onClose" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-data-table
                style="cursor: pointer;"
                :headers="[
                {
                    value: 'customers.name',
                }, 
                {
                    value: 'label',
                },
                ]"
                :items="$store.state.plants"
                :items-per-page="10"
                hide-default-header
                :hide-default-footer="$store.state.plants && $store.state.plants.length <= 5"
                @click:row="selectPlant"
                single-select
                v-model="selected"
            >
            </v-data-table> 
        </v-card>
      </v-dialog>
  </template>
  
  <script>
  export default {
    props: [
        'show',
        'onClose',
    ],
    data: function() {    
      return { 
        plantsDialog: false,
        loading: false,
        selected: []
      }
    },
    watch:{
        show: function(newVal){
            this.plantsDialog = newVal
            if(newVal){
                this.initialize()
            }
        },
        plantsDialog: function(newVal){
            if(newVal == false){
                this.onClose()
            }
        }
    },
    methods: {  
    async initialize() {
        this.loading = true
        const plants = await this.$store.dispatch('_getUserPlants')
        this.selected.push(plants.find(plant => plant.id == this.$store.state.plantId))
        this.loading = false
    }, 
    selectPlant(plant){
        this.$store.dispatch('_setPlant', plant)
        this.selected = []
        this.onClose()
      }
    }  
  }
  </script>
  
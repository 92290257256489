<template>
    <div>    
      <v-card flat>

        <v-card-title primary-title>
          <v-icon left color="red darken-2">mdi-alert</v-icon>
          {{ $t('alerts') }}
        </v-card-title>

        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab key="structure-alerts">
              Structure Alerts
            </v-tab>
            <v-tab key="system-alerts">
              System Alerts
            </v-tab>
          </v-tabs>
  
          <v-tabs-items v-model="tab">
            <v-tab-item key="structure-alerts">
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="structureAlerts"
                    :items-per-page="5"
                    class="tableRow"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                  >
                  <template v-slot:[`item.aknowledged`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.aknowledged"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item key="system-alerts">
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="systemAlerts"
                    :items-per-page="5"
                    class="tableRow"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                  >
                  <template v-slot:[`item.aknowledged`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.aknowledged"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </v-card-text>
      </v-card>
    </div>
</template>

<script>
export default {
  props:{
    entityId: String,
    entityType: {
      type: String,
      default: 'area'
    }
  },
  data () {
    return {
      tab: null,
      headers: [
        {
          text: 'Date',
          align: 'start',
          value: 'date',
        },
        { text: 'Label', value: 'label' },
        { text: 'Source', value: 'sourceLabel' },
        // { text: 'Type', value: 'type' },
        { text: 'Aknowledged', value: 'aknowledged' }
      ],
      structureAlerts:[],
      systemAlerts:[],
      loading: false
    }
  },
  watch: {
    entityId: function () {
      
      this.loadData();
    }
  },
  methods: {
    
    splitRecord: function (input = []) {
      
      let structureAlerts = [];
      let systemAlerts = [];
      
      input.forEach(element => {

        if(!element.system){
          structureAlerts.push(this.mapResult(element));
        } else {
          systemAlerts.push(this.mapResult(element));
        }
      });

      this.structureAlerts = structureAlerts;
      this.systemAlerts = systemAlerts;
      
    },

    mapResult: function (input) {
      
      const CONF = this.$store.state.user.configParams;

      // format timestamp according to selected type
      return {
        date: this.$date.fromISO(input.timestamp).toFormat(CONF.dateTimeFormat || 'dd/MM/yyyy'),
        label: input.label || input.alertId,
        sourceLabel: input.sourceLabel || input.sourceId,
        // type: input.alertType,
        aknowledged: input.timestamp !== '' ? false : true 
      };
    },

    loadData: async function() {
      this.loading = true;
      this. structureAlerts= [];
      this.systemAlerts = [];
      if(parseInt(this.$props.entityId) !== 0){
       const data = await this.$store.dispatch('_getAreaAlerts', {areaId:this.$props.entityId });
       this.splitRecord(data);
      }
      this.loading = false;
    }
  },
  created: function() {
    this.loadData();
  }
}
</script>

import * as d3 from 'd3'
import SysUtility from './SysUtility.js'

/**
 * Creates a custom Note component 
 * @class
 */
export default class SysNote{

  #noteContainer
  #ref
  #transitionTime

  /**
   * 
   * @param {*} params - allowed params: 
   *  ref {Object} - graph object
   *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects 
   */
  constructor(params){

    this.#noteContainer = null;
    this.#ref = params.ref;
    this.#transitionTime = params.transitionTime || 500;

    this.init();

  }

  /**
   * Creates a note layer
   */
  init(){

    this.#noteContainer = this.#ref.getSVG()
      .append("g")
      .attr("clip-path", `url(#slc_${this.#ref.getExternalId()}_clip)`)
      .attr("id", "noteContainer");
    
  }

  /**
   * Renders notes, getting data from dataset
   * @param {*} dataset 
   */
  render(dataset){
    for(let i=0, l=dataset.length; i<l; i++){
      let note = dataset[i];
      this.addNote(note.noteId, note.xCoord, note.yCoord, note.content, note.xData, note.yData);
    }
  }

  /**
   * Creates note, catching mouse pointer 
   * @param {event} e 
   */
  createNote(e){

    const noteId = SysUtility.randomId();
    const coords = d3.pointer(e);
    const xCoord = coords[0];
    const yCoord = coords[1];
    const content = "Click to edit";
    const xData = this.#ref.getXScale().invert(xCoord);
    const yData = this.#ref.getYScaleLeft().invert(yCoord);

    this.addNote(noteId, xCoord, yCoord, content, xData, yData);

    this.#ref.handleNoteInsert(noteId, xCoord, yCoord, content, xData, yData);

  }
  
  /**
   * Adds a new note on graph. adds also note events 
   * @param {String} noteId - random generated id 
   * @param {*} xCoord - coord on x axis
   * @param {*} yCoord - coord on y left axis
   * @param {*} content - note content
   * @param {*} xData - fake date calculated from xCoord, used to relocate the note on the right positions on reloads/updates 
   * @param {*} yData - fake value calculated from yCoord, used to relocate the note on the right positions on reloads/updates
   */
  addNote(noteId, xCoord, yCoord, content, xData, yData){
    const that = this;

    // event functions
    function dragMove(e){

      const coords = d3.pointer(e, svg);

      let x = coords[0] - 80; // why ???
      let y = coords[1] - 60; // why ???

      d3.select(this.parentNode.parentNode).attr("transform", `translate(${x},${y})`);
      foreignObject.setAttribute("xCoord", x);
      foreignObject.setAttribute("yCoord", y);
    }

    /**
     * Event raised on the end of drag events. Saves note position on reference object
     * @param {Event} e 
     */
    function dragEnd(){
      let xCoord = foreignObject.getAttribute("xCoord");
      let yCoord = foreignObject.getAttribute("yCoord");
      let xData = that.#ref.getXScale().invert(xCoord);
      let yData = that.#ref.getYScaleLeft().invert(yCoord);
      foreignObject.setAttribute("xData", xData);
      foreignObject.setAttribute("yData", yData);

      that.#ref.handleNoteUpdate(noteId, xCoord, yCoord, textnode.nodeValue, xData, yData);
    }

    /**
     * Event raised on key up event, when note content editing is over
     * @param {Event} e 
     */
    function updateContent(e){
      // enter
      if(e.key === "Enter" && e.shiftKey === false){
        
        e.target.blur()
        
        let xCoord = foreignObject.getAttribute("xCoord");
        let yCoord = foreignObject.getAttribute("yCoord");
        let xData = that.#ref.getXScale().invert(xCoord);
        let yData = that.#ref.getYScaleLeft().invert(yCoord);
        that.#ref.handleNoteUpdate(noteId, xCoord, yCoord, textnode.nodeValue, xData, yData);
        

      }
    }
    
    // create DOM element
    const draggable = d3.drag(d3.select("svg")).on("drag", dragMove).on("end", dragEnd);
    const svg = document.getElementById(`slc_${this.#ref.getExternalId()}_SVG`);
    const noteContainer = svg.querySelector("#noteContainer");
    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
    const contentDiv = document.createElement("div");
    const dragDiv = document.createElement("div");
    const textdiv = document.createElement("div");
    const textnode = document.createTextNode(content);

    // set element attributes
    foreignObject.setAttributeNS(null, "transform", `translate(${xCoord},${yCoord})`);
    foreignObject.setAttribute("class", "sys-note-container");
    foreignObject.setAttribute("id", noteId);
    foreignObject.setAttribute("xCoord", xCoord);
    foreignObject.setAttribute("yCoord", yCoord);
    foreignObject.setAttribute("xData", xData);
    foreignObject.setAttribute("yData", yData);
    contentDiv.setAttribute("class", "sys-note");
    dragDiv.setAttribute("class", "sys-drag-handler");
    textdiv.setAttribute("contentEditable", "true");
    textdiv.setAttribute("class", "sys-note-content");
    textdiv.addEventListener("keypress", updateContent);

    // render element
    foreignObject.appendChild(contentDiv);
    contentDiv.appendChild(dragDiv);
    contentDiv.appendChild(textdiv);
    textdiv.appendChild(textnode);
    noteContainer.appendChild(foreignObject);

    // set note draggable
    d3.select(foreignObject).select(".sys-drag-handler").call(draggable)

    // remove note on doble click
    d3.select(dragDiv).on("dblclick", e=> {
      this.removeNote(e);
    });
  }

  /**
   * Removes note. Event raised from right click
   * @param {Event} e 
   */
  removeNote(e){
    let noteId = e.target.parentNode.parentNode.id;
    d3.select(e.target.parentNode.parentNode).remove();
    this.#ref.handleNoteDelete(noteId);
  }

  /**
   * Update notes (position)
   */
  updateNotes(){
    let notes = this.#noteContainer.node().getElementsByTagName("foreignObject");
    // console.log(notes)
    
    for (let note of notes) {
      
      let xCoord = this.#ref.getXScale()(new Date(note.getAttribute("xData")) );
      let yCoord = this.#ref.getYScaleLeft()(note.getAttribute("yData"));

      note.style.transition = `transform ${this.#transitionTime/1000}s`;
      note.setAttribute("transform", `translate(${xCoord},${yCoord})`);

    }

  }

  /**
   * Deeletes all points from layer
   */
  clearAll(){
    this.#noteContainer.selectAll("*").transition().duration(this.#transitionTime).style("opacity", 0).remove();
  }

  /**
   * Shows / hides note
   * @param {*} opacity 
   */
  toggleVisibility(opacity){
    this.#noteContainer.transition().duration(this.#transitionTime).style("opacity", opacity);
  }
  
}

import * as d3 from 'd3'
/**
 * Creates a custom wrapper for D3.js grid
 * @class
 */
export default class SysGrid{

    #gridContainer
    #ref
    #transitionTime
    #axisMode
    #xGrid
    #yGridLeft
    #yGridRight

    /**
     * 
     * @param {Object} params - allowed params: 
     *  ref {Object} - graph object
     *  transitionTime {Number} - expressed in milliseconds, used in transition effects  
     *  axisMode {Number} - number of axis. Allowed params: 2,3
     */
    constructor(params){
  
      this.#ref = params.ref || null;
      this.#gridContainer = null;
      this.#transitionTime = params.transitionTime || 500;
      this.#axisMode = params.axisMode || this.#ref.getAxisMode();
      this.#xGrid = null;
      this.#yGridLeft = null;
      this.#yGridRight = null;

      this.init();
    }

    /**
     * Creates a grid container
     */
    init(){

      this.#gridContainer = this.#ref.getSVG()
        .append("g")
        .attr("id", "gridContainer")
    }

    /**
     * Draws all grids
     * @param {Objects} xScale - D3.js scale object 
     * @param {Objects} yScaleLeft - D3.js scale object 
     * @param {Objects} yScaleRight - D3.js scale object 
     */
    drawGrids(xScale, yScaleLeft, yScaleRight){
      // add X gridlines
      this.drawXGrid(xScale);

      // add Y gridlines for left axis
      this.drawYGridLeft(yScaleLeft);

      // I need to initialize anyway, indipendently from axis mode
      // add Y gridlines for right axis
      this.drawYGridRight(yScaleRight);

      if(this.#axisMode == 2){
        this.#yGridRight.style("opacity", 0);
      }

    }

    /**
     * Draws x grid
     * @param {Object} xScale - D3.js scale object 
     */
    drawXGrid(xScale){
      this.#xGrid = this.#gridContainer.append("g")
      .attr("class", "sys-lc-grid")
      .attr("transform", `translate(0,${this.#ref.innerHeight})`)
      .call(d3.axisBottom(xScale).tickSize(-this.#ref.innerHeight).tickFormat(""));     

    }

    /**
     * Draws y left grid
     * @param {Object} yScaleLeft - D3.js scale object 
     */
    drawYGridLeft(yScaleLeft){
      this.#yGridLeft = this.#gridContainer.append("g")
      .attr("class", "sys-lc-grid-left")
      .call(d3.axisLeft(yScaleLeft).tickSize(-this.#ref.innerWidth).tickFormat(""));
      
    }

    /**
     * Draws y right grid
     * @param {Object} yScaleRight - D3.js scale object 
     */
    drawYGridRight(yScaleRight){
      this.#yGridRight = this.#gridContainer.append("g")
      .attr("class", "sys-lc-grid-right")
      .attr("transform", `translate(${this.#ref.innerWidth},0)`)
      .call(d3.axisRight(yScaleRight).tickSize(-this.#ref.innerWidth).tickFormat(""));
      
    }

    /**
     * @todo
     */
    updateGrids(){
      console.log("TODO");
    }

    /**
     * Updates x grid
     * @param {Object} xScale - D3.js scale object
     */
    updateXGrid(xScale){
      this.#xGrid.transition().duration(this.#transitionTime)
        .call(d3.axisBottom(xScale).tickSize(-this.#ref.innerHeight).tickFormat(""));
    }

    /**
     * Updates y left grid
     * @param {Object} yScaleLeft - D3.js scale object 
     */
    updateYGridLeft(yScaleLeft){
      this.#yGridLeft.transition().duration(this.#transitionTime)
      .call(d3.axisLeft(yScaleLeft).tickSize(-this.#ref.innerWidth).tickFormat(""));

    }

    /**
     * Updates y right grid
     * @param {Object} yScaleRight - D3.js scale object 
     */
    updateYGridRight(yScaleRight){
      this.#yGridRight.transition().duration(this.#transitionTime)
      .call(d3.axisRight(yScaleRight).tickSize(-this.#ref.innerWidth).tickFormat(""))

    }

    /**
     * Sets visibility for y left grid
     * @param {Boolean} opacity 
     */
    toggleVisbilityYGridLeft(opacity){
      this.#yGridLeft.transition().duration(this.#transitionTime).style("opacity", opacity);
    }

    /**
     * Sets visibility for y right grid
     * @param {Boolean} opacity 
     */
    toggleVisbilityYGridRight(opacity){
      this.#yGridRight.transition().duration(this.#transitionTime).style("opacity", opacity);
    }

}
<template>
  <v-container>
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <StatisticsList/>
            </v-col>  
          </v-row>
        </v-col>          
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <AlertsGrid/>
            </v-col>  
          </v-row>
          <v-row>
            <v-col cols="12">
              <BatteriesGrid/>             
            </v-col>  
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <NewsGrid/> 
            </v-col>  
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12"> 
              <LinkList/>
            </v-col>  
          </v-row>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import StatisticsList from '@/components/home/StatisticsList';
import AlertsGrid from '@/components/home/AlertsGrid';
import BatteriesGrid from '@/components/home/BatteriesGrid';
import NewsGrid from '@/components/home/NewsGrid';
import LinkList from '@/components/home/LinkList';

export default {
  name: 'Home',
  components: {
    StatisticsList,
    AlertsGrid,
    BatteriesGrid,
    NewsGrid,
    LinkList
  },
  data: function() {
    return {
      breadcrumbsItems: [ 
        { href: '/home', disabled: true, text: this.$t("home").toUpperCase(), to: '/home'}
      ],
    }
  },
  created: function() {
    this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);
  },
  methods: {}
}
</script>

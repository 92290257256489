import api from "@/_helpers/api";

export default {
  _setManagementSetup: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/management/setup`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _setManagementCalibration: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/management/calibration`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getManagementExport: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/management/exports`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _setManagementSetupResend: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/management/setup/resend/${args.sensorId}`,
      method: "post",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

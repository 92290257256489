import api from "@/_helpers/api";

export default {
  _getStructure: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/structures/${args.structureId}`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
  _getStructureEnsemble: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/structures/${args.id}/ensembles`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
  _createStructure: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/structures/`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
  _updateStructure: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/structures/${args.structureId}`,
      method: "put",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getStructures: async (context) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/structures`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _deleteStructure: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/structures/${args.structureId}`,
      method: "DELETE",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

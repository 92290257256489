<template>
      <v-card flat>
        
        <v-card-title primary-title>
          <v-icon left color="info">mdi-information</v-icon>
          {{ $t('info') }}
        </v-card-title>

        <v-card-text>  
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field label="Name" v-model="elementEdited.label"  :rules="[rules.required]"></v-text-field>
            <v-select
              v-model="elementEdited.structureId"
              :items="structures"
              label="Structure"
              dense
              item-text="label"
              item-value="structureId"
              :rules="[rules.required]"
            >
            </v-select>
            <Points 
              pointsType="all"
              ref="pointsRef" 
              :itemsPerPage="5" 
              :customHeaders="[
                { text: 'Point', value: 'label'},
              ]"
              :selected="elementEdited.children"
              />
          </v-form>
          
       </v-card-text>
        <v-card-actions>
            <div class="btnContainer">
              <v-btn :loading="loading" :disabled="loading || !valid" color="success" @click="save()"><v-icon>mdi-floppy</v-icon>&nbsp;Save</v-btn>
              <v-btn :loading="loading" :disabled="loading" color="secondary" @click="cancel()" class="ml-5"><v-icon>mdi-arrow-left</v-icon>&nbsp;Cancel</v-btn>
              <v-btn :loading="loading" :disabled="loading" color="error" @click="deleteItem()" class="ml-5"><v-icon>mdi-delete</v-icon>&nbsp;Delete</v-btn>
            </div>
          </v-card-actions>
      </v-card>
</template>

<script>
import Points from '@/components/shared/Points';
import { mapState } from 'vuex';

export default {
  components: {
    Points
  },
  props: [
    'action',
    'element',
    'onSave',
    'onDelete',
    'onCancel'
  ],
  computed: {
    ...mapState( [
      'dashboardSelection'
    ]),
    rules () {
      return this.$script.formRules
    },
  },
  data: () => {
    return {
      valid: false,
      elementEdited : {},
      loading: false,
      structures: []
    }
  },
  watch: {
    element: function ( newVal ) {
      this.elementEdited = newVal;
    },
    action: function ( newVal ) {
      if(newVal !== 'view'){
        this.loadStructures()
        this.loadPoints()
      }
    },
  },
 methods: {
    async save(){
      this.loading = true

      // let originalPointsId = []

      // if(this.elementEdited.children){
      //   originalPointsId = this.elementEdited.children.map(x=> x.sensorId)
      // }
      const updatedPointsId = this.$refs.pointsRef.selectedItems.map(x=> x.sensorId)

      // this.elementEdited.addPoints = updatedPointsId.filter(x => !originalPointsId.includes(x));
      // this.elementEdited.removePoints = originalPointsId.filter(x => !updatedPointsId.includes(x));

      this.elementEdited.addPoints = updatedPointsId;

      await this.onSave(this.elementEdited)

      this.loading = false
    },
    validateField () {
      this.$refs.form.validate()
    },
    async loadStructures(){
      this.structures = await this.$store.dispatch('_getStructures');
    },
    async deleteItem(){
       this.onDelete()
    },
    loadPoints  (){
      this.$refs.pointsRef.onFilter({})
    },
    cancel() {
      this.loading = true
      this.onCancel()
      this.loading = false
    }
  },
  created: function() {
    if(this.action !== 'view'){
      this.loadStructures()
    }
  },
  mounted: function() {
    if(this.action !== 'view'){
      this.loadPoints()
    }
  },
  
}
</script>

<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12">          
        <v-row>
          <v-col cols="6">
            <Info v-show="action === 'view'" :infoItems="infoItems" :loading="loading" :resendSetup="resendSetup"></Info>
            <InfoEdit v-show="action !== 'view'" :action="action" :point="point" :onSave="save" :onCancel="cancel" :onDelete="()=> showDeleteConfirm = true" ref="infoEdit" ></InfoEdit>
          </v-col>
          <v-col cols="6">
            <DeploymentPlan></DeploymentPlan>
          </v-col>
        </v-row>
        <v-row v-if="action === 'view'">
          <v-col cols="6">
            <LastMessages :loading="loadingMessages || loading" :messages="messages"></LastMessages>
          </v-col>
          <v-col cols="6">
            <Setup :loading="loadingSetup || loading" :setupsCurrent="setupsCurrent" :setupsNext="setupsNext"></Setup>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <SecondaryMenu routeFrom="/dashboard/point"/>

  </v-container>
</template>

<script>
  import Info from '@/components/dashboard/point/Info';
  import DeploymentPlan from '@/components/dashboard/point/DeploymentPlan';
  import LastMessages from '@/components/dashboard/point/LastMessages';
  import Setup from '@/components/dashboard/point/Setup';
  import SecondaryMenu from '@/components/shared/SecondaryMenu';
  import InfoEdit from '@/components/dashboard/point/InfoEdit';

  export default {
    name: 'Point',
    components: {
      Info,
      DeploymentPlan,
      LastMessages,
      Setup,
      SecondaryMenu,
      InfoEdit,
    },
    props:{
      entityId: String,
      action: String,
    },
    data () {
      return {
        loading: false,
        point: {},
        infoItems: [],
        messages: [],
        loadingMessages: false,
        setupsCurrent: [],
        setupsNext: [],
        loadingSetup: true,
        dateFormat: this.$store.state.user.configParams.dateFormat,
        dateTimeFormat: this.$store.state.user.configParams.dateTimeFormat
      }  
    },
    watch: {
      entityId: function () {
        this.loadData();
      }
    },
    mounted: function() {
      this.loadData()
    },
    methods: {
      mapData(input){
        const firstMessage = input.firstMessage ?
                this.$date.fromISO(input.firstMessage)
                .toFormat(this.dateFormat || 'dd/MM/yyyy') : this.$t('point.info.placeholder');

        const lastMessage = input.lastMessage ?
                this.$date.fromISO(input.lastMessage)
                .toFormat(this.dateFormat || 'dd/MM/yyyy') : this.$t('point.info.placeholder');        

        // const CONF = this.$store.state.user.configParams;
        return [
          {  id:1,  text: "Name", value: input.label},
          {  id:2,  text: "Battery", value: input.battery, type: 'battery', batteryIcon: 'mdi-battery', batteryColor: 'green darken-2' },
          {  id:3,  text: "First Message", value: firstMessage, firstMessage : true},
          {  id:4,  text: "Last Message", value: lastMessage , lastMessage : true},
          {  id:9,  text: "Status", value: input.connected? 'CONNECTED': 'NOT CONNECTED'}, 
          {  id:10, text: "Setup Status", value: input.setupStatus, type: 'setup', setupStatus: true} ,
          {  id:11, text: "Type", value: input.sensorModel} 
        ];
      }, 
      async loadData(){
        this.loading = true
        if(this.$props.entityId && this.$props.entityId !== '0'){
          this.point = await this.$store.dispatch('_getPoint', {pointId: this.$props.entityId});
          this.infoItems = this.mapData(this.point);
        }else{
          this.point = {}
          this.infoItems = this.mapData(this.point);
        }
        this.loading = false
        this.loadMessages()
        this.loadSetup()
      },
      async loadMessages(){
        
        this.loadingMessages = true
        this.messages = []
        if(this.$props.entityId && this.$props.entityId !== '0'){
          const data  = await this.$store.dispatch('_getPointLastMessages', {pointId: this.$props.entityId});
          if(data){
            this.messages = data.map(x=> ({
              ...x,
              messageTime: x.messageTime ?  this.$date.fromISO(x.messageTime).toFormat(this.dateTimeFormat || 'dd/MM/yyyy hh:mm:ss') : '',
            }))
          }
        }
        this.loadingMessages = false
      },
      async loadSetup(){
        this.loadingSetup = true
        this.setupsCurrent = []
        this.setupsNext = []
        if(this.$props.entityId && this.$props.entityId !== '0'){
          const data  = await this.$store.dispatch('_getPointSetup', {pointId: this.$props.entityId});
          if(data){
            this.setupsCurrent = data.setupCurrent ? Object.keys(data.setupCurrent).map((k) =>  ({text: k, value: data.setupCurrent[k] })) : []
            this.setupsNext = data.setupsNext ? Object.keys(data.setupsNext).map((k) =>  ({text: k, value: data.setupCurrent[k] })) : []
          }
        }
        this.loadingSetup = false
      },
       async save(item){
         await this.$store.dispatch('_updatePoint', {pointId: this.$props.entityId, data: {
            label: item.label,
            description: item.description,
            absStrain: item.absStrain,
            absVerticalTilt: item.absVerticalTilt,
            elementId: item.parentId,
          }});
    
          this.$router.push(`/dashboard/point/${this.$props.entityId}`);
          this.loadData()
      },
      async resendSetup(){
        this.point = await this.$store.dispatch('_setManagementSetupResend', {sensorId: this.$props.entityId });
        this.infoItems = this.mapData(this.point);
      },
      async cancel() {
        this.$router.push(`/dashboard/point/${this.$props.entityId}`);
        this.loadData()
      }
    }
  }
</script>

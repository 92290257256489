<template>
  <div>
    <v-card flat>
      <v-card-title primary-title>
        <v-icon left color="info">mdi-newspaper</v-icon>
        {{ $t('news') }}
      </v-card-title>

      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="newsHeaders"
          :items="newsItems" 
          :single-expand= true
          show-expand
          :expanded.sync="newsExpanded"
          :items-per-page="4"     
          item-key="id"
          class="tableRow" 
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td class="my-3 py-3" :colspan="headers.length"><span v-html="item.content"></span></td>
          </template>                       
        </v-data-table>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: function() {    
    return {
      newsItems:[],
      newsExpanded: [],        
      newsHeaders: [ 
        { text: this.$t("Id"),      value: "newsId",    sortable: true, }, 
        { text: this.$t("Date"),    value: "timestamp", sortable: true, },
        { text: this.$t("Title"),   value: "title",     sortable: false, },
        { text: '', value: 'data-table-expand' },
      ]   
    }
  },
  methods: {},
  created: async function() {
    this.loading = true
    this.newsItems = await this.$store.dispatch('_getNews')
    this.loading = false
  }
}
</script>

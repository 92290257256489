import api from "@/_helpers/api";
import { DateTime } from "luxon";

export default {
  _getStatistics: async () => {
    const response = await api.call({
      url: `/home/statistics`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getAlerts: async (context) => {
    const response = await api.call({
      url: `/home/alerts`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    const CONF = context.state.user.configParams ;
    // TOFIX: set default to origin
    const dateTimeFormat = CONF.dateTimeFormat || 'dd/MM/yyyy';

    // format timestamp according to selected type
    data.forEach((el) => {
      el.timestamp = DateTime.fromISO(el.timestamp).toFormat(
        dateTimeFormat
      );
    });

    return data;
  },

  _getBatteriesStatus: async (context, args) => {
    const response = await api.call({
      url: `/home/batteries-status`,
      method: "get",
      query: args,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getNews: async (context) => {
    const response = await api.call({
      url: "/home/news",
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    const CONF = context.state.user.configParams ;
    // TOFIX: set default to origin
    const dateTimeFormat = CONF.dateTimeFormat || 'dd/MM/yyyy';


    return data.map((x) => ({
      newsId: x.id,
      timestamp: DateTime.fromISO(x.creationDate).toFormat(dateTimeFormat),
      title: x.title,
      content: x.content,
    }));
  },

  _getLinks: async () => {
    const response = await api.call({
      url: "/home/links",
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

<template>
    <div>    
      <v-card flat>
        <!-- <v-card-title primary-title>
          <v-icon left color="green darken-2">mdi-floor-plan</v-icon>
          {{ $t('deployment_plan') }}
        </v-card-title> -->
        <v-card-text>
        <v-tabs v-model="tab">
          <!-- <v-tab key="pdd"> PDD </v-tab> -->
          <v-tab key="image"> Image </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="pdd">
            <v-card flat>
                <v-card-text>
                  <!-- <Pdd /> -->
                </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="image">
            <v-card flat>
                <v-card-text>
                </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>
</template>

<script>
// import Pdd from '@/components/shared/Pdd';
export default {
   components: {
      // Pdd,
    },
  data: function() { return {
    tab: null
  }},
}
</script>
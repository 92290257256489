import api from "@/_helpers/api";

export default {
  reset_snackbar: (context) => {
    context.commit("RESET_SNACKBAR");
  },

  _updateBreadcrumbs: (context, breadcrumbs) => {
    context.commit("UPDATE_BREADCRUMBS", breadcrumbs);
  },

  _snackbarMessage: (context, payload) => {
    context.commit("SNACKBAR_MESSAGE", payload);
  },

  _setLoading: (context, payload) => {
    context.commit("SET_LOADING", payload);
  },

  _setTheme: (context, payload) => {
    payload = ["dark", "light"].indexOf(payload) > -1 ? payload : "light";
    context.commit("SET_THEME", payload);
  },

  _getCommonTree: async (context) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/common/tree`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

<template>
  <div>
    <v-card flat>
      
      <v-card-title primary-title>
          <v-icon left color="grey">mdi-cog</v-icon>
          {{ $t("setup") }}
      </v-card-title>

      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab key="current-tab"> Current </v-tab>
          <v-tab key="next-tab"> Next </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="current-tab">
            <v-card flat>
              <v-card-text>
                <v-data-table
                  :items="setupsCurrent"
                  :headers="headers"
                  :items-per-page="5"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  >
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="next-tab">
            <v-card flat>
              <v-card-text>
                 <v-data-table
                  :items="setupsNext"
                  :headers="headers"
                  :items-per-page="5"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  >
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: [
    'setupsCurrent',
    'setupsNext',
    'loading'
  ],
  data() {
    return {
      tab: null,
      headers: [
          {
            text: 'Param',
            value: 'text',
          },
          {
            text: 'Value',
            value: 'value',
          },
       ],
    };
  },
};
</script>

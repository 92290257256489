/**
 * Creates a custom exception component
 * @class
 */
export default class SysException{

    #name;

    /**
     * 
     * @param {String} name - error name 
     * @param {String} message - error message 
     * @param {String} value - error value, if a value to check is defined 
     */
    constructor(name, message, value = ''){
      this.#name = name;
      this.message = message;
      this.value = value;
      this.toString = () =>{
        let output = `${this.#name} - Error: ${this.message}`;
        if(this.value !== '') output += ` (value: ${this.value})`;
        return output;
      };
    }
}
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import it from './it.json'
import en from './en.json'

Vue.use(VueI18n)

const messages = {
  it,
  en
}

const locale = '';

const i18n = new VueI18n({
  locale,
  messages
})

const vuexLocalStorage = JSON.parse(localStorage.getItem('vuex'))

if(vuexLocalStorage && vuexLocalStorage.user && vuexLocalStorage.user.configParams && vuexLocalStorage.user.configParams.language){
  i18n.locale = vuexLocalStorage.user.configParams.language;
}else{
  i18n.locale = getLocale();
}

function getLocale() {
  let lang = 'en'
  let browserLocale = navigator.language.substring(0, 2)

  if (Object.keys(messages).indexOf(browserLocale) != -1) lang = browserLocale

  return lang
}

export function setLanguage(language) {
  i18n.locale = language;
}

export default i18n



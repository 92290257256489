<template>
    <div>    
      <v-card flat :loading="loading">
        <v-card-title>
            <v-icon left color="warning">mdi-chart-bar</v-icon>
            {{ $t('statistics') }}
        </v-card-title>

        <v-card-text>

          <v-simple-table class="tableRow">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in statisticsItems"
                  :key="item.id"
                >
                  <td>{{ item.text }}:</td>
                  <td>
                      <span v-if="item.type == 'alerts' && item.value > 0">
                        {{ item.value }}&nbsp;&nbsp;
                        <v-icon color="red" >mdi-circle</v-icon>
                        &nbsp;&nbsp;
                        <v-btn small fab text color="primary" :to="item.route">
                          <v-icon>mdi-alert-outline</v-icon>
                        </v-btn>
                      </span>

                      <span v-else-if="item.type == 'alerts' && item.value == 0">
                        {{ item.value }}&nbsp;&nbsp;
                        <v-icon color="green">mdi-circle</v-icon>
                      </span>  

                      <span v-else>{{ item.value }}</span>           
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </v-card>
    </div>
</template>

<script>
export default {
  data: () => {
    return {
      loading : true,
      statisticsItems: undefined
    }
  },
  created: async function() {
    this.loading = true
    this.statisticsItems = await this.$store.dispatch('_getStatistics')
    this.loading = false
  }
}
</script>

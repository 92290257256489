<template>
  <v-container class="px-0">
    <EnsembleList/>
  </v-container>
</template>

<script>
  import EnsembleList from '@/views/ensembles/EnsembleList'

  export default {
    name: 'Ensembles',
    components:{
      EnsembleList
    },
    data () {
      return {
        breadcrumbsItems: [ 
          { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home'},
          { href: '/ensembles', disabled: true, text: this.$t("ensembles").toUpperCase()}
        ]  
      }  
    },
    created: function() {
      this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);
    },
    methods: {}
  }
</script>
<template>
    <div>    
      <v-card flat>
        
        <v-card-title primary-title>
          <v-icon left>mdi-tooltip-account</v-icon>
          {{ $t('users') }}
        </v-card-title>

        <v-card-text>                    
          <v-data-table
            v-model="selectedItems"
            :headers="headers"
            :items="action === 'view' ? selected : items"
            :show-select="action !== 'view'"
            :items-per-page="5"
            :loading="loading"
            loading-text="Loading... Please wait"
            >
        </v-data-table>
        </v-card-text>
      </v-card>
    </div>
</template>

<script>
export default {
   props: [
    'items',
    'loading',
    'selected',
    'action'
  ],
  data() {
    return {
      selectedItems: this.$props.selected || [],
      headers: [
        { text: 'Name', value: 'name'},
        // { text: 'Type', value: 'type'},
      ],
    };
  },
  watch: {
    selected: function ( newVal ) {
      if(this.action === 'view'){
        this.selectedItems = undefined;
      }else{
        this.selectedItems = newVal;
      }
    },
    action: function ( newVal ) {
      if(newVal === 'view'){
        this.selectedItems = undefined;
      }else{
        this.selectedItems = this.selected;
      }
    }
  },
  methods: {
  },
  created: function() {}
}
</script>
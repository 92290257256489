<template>
  <v-row justify="center">
     <v-dialog 
      :value="show" 
      width="500" 
      overlay-opacity="0.4"
      overlay-color="#ffffff"
    >
      
      <v-card flat>
        <v-card-title class="text-h5 blue darken-4">
         {{title}}
        </v-card-title>

        <v-card-text>
          <br> {{message}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn :loading="loading" :disabled="loading" color="error" @click="onConfirm()">
            <v-icon>mdi-delete</v-icon>&nbsp;Delete
          </v-btn>
          
          <v-btn :disabled="loading" color="primary" class="ml-5" @click="onCancel()">
            <v-icon>mdi-close</v-icon>&nbsp;Close
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'Confirm',
    props: [
    'show',
    'title',
    'message',
    'onConfirm',
    'onCancel'
  ],
    data () {
      return {
        loading: false
      };
    },
  }
</script>

<template>
    <v-container class="pa-0">
       <v-card v-show="action === 'view'" flat>
        <v-card-title primary-title>
          <v-icon left color="info">mdi-information</v-icon>
          {{ $t('info') }}
        </v-card-title>
        <v-card-text>
          <v-simple-table class="tableRow">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in infoItems"
                  :key="item.id"
                >
                  <td>{{ item.text }}:</td>
                  <td>
                    <span v-if="item.type == 'alerts' && item.value > 0">
                      {{ item.value }} <v-icon color="red" >mdi-circle</v-icon>
                      &nbsp;
                      <v-btn fab small color="primary" :to="item.route">
                        <v-icon>mdi-alert-outline</v-icon>
                      </v-btn>
                    </span>
                    <span v-else-if="item.type == 'alerts' && item.value == 0">
                      {{ item.value }} <v-icon color="green">mdi-circle</v-icon>
                    </span>
                    <span v-else>{{ item.value }}</span>            
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <InfoEdit ref="infoEdit" v-show="action !== 'view'" :action="action" :structure="structure" :onSave="onSave" :onDelete="onDelete" :onCancel="onCancel" :updateConfigurator="updateConfigurator"></InfoEdit>
  </v-container>
</template>

<script>
import InfoEdit from '@/components/dashboard/structure/InfoEdit';

export default {
  props: [
    'infoItems',
    'action',
    'structure',
    'onSave',
    'onDelete',
    'updateConfigurator',
    'onCancel'
  ],
  components: {
    InfoEdit,
  },
   methods: {
      setLoading(loading){
         this.$refs.infoEdit.loading = loading
      },
    }
}
</script>

export default {
  RESET_SNACKBAR: (state) => {
    state.snackbar.color = "";
    state.snackbar.text = "";
  },

  UPDATE_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },

  SNACKBAR_MESSAGE: (state, payload) => {
    state.snackbar.color = payload.status;
    state.snackbar.text = payload.message;
  },

  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_THEME(state, payload) {
    state.theme = payload;
  },
};

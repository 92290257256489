<template>
      <v-card flat class="treeContainer" >
        <v-card-title>
          <div class="tree-title">
            <v-icon left color="green darken-2">mdi-file-tree</v-icon>
            {{ $t('tree') }}
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="!openAll" fab x-small icon color="primary" @click="toggleFold">
            <v-icon>mdi-unfold-more-horizontal</v-icon>
          </v-btn>
          <v-btn v-else fab x-small icon color="primary" @click="toggleFold">
            <v-icon>mdi-unfold-less-horizontal</v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" fab x-small icon color="success" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-list class="">
              <v-list-item link to="/dashboard/area/0/new">
                <v-list-item-title>
                  <v-icon left>mdi-texture-box</v-icon>Area
                </v-list-item-title>
              </v-list-item>
              <v-list-item link to="/dashboard/structure/0/new">
                <v-list-item-title>
                  <v-icon left>mdi-domain</v-icon>Structure
                </v-list-item-title>
              </v-list-item>
              <v-list-item  link to="/dashboard/element/0/new">
                <v-list-item-title>
                  <v-icon left>mdi-hexagon-multiple</v-icon>Element
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="treeSearch" :label="$t('filter')" clearable dense @input="handleSearch"></v-text-field>
          <div style="overflow: auto;height: 85%;">
            <v-treeview
              :items="treeItems"
              :search="treeSearch"
              :open.sync="open"
              :active="active"   
              activatable
              hoverable 
              dense 
              ref="tree" 
              :open-all="openAll===true"
              active-class="indigo lighten-5"
              >
              <template slot="label" slot-scope="props">
                <div @click="selectItem(props.item)" style="cursor:pointer">
                  <v-icon>{{ props.item.icon }}</v-icon>
                  {{ props.item.name }}
                </div>
              </template>
            </v-treeview>
          </div>
          </v-card-text>
      </v-card>
</template>

<style scoped>
  .v-card__text{
    height: inherit;
  }    
  .treeContainer{
    height: calc(100vh - 80px);
  }

  .floatLeft{
    float: left;
  }
  .floatRight{
    float: right;
  }
  .floatClear{
    clear: both;
  }
  .treeLink{
    text-decoration: none;
  }
</style>

<script>
import mocks from "@/_helpers/mocks";

export default {
  props:{
    entityId: String
  },
  data: () => ({
    treeSearch: null,
    treeItems: [],
    openAll: false,
    open: [],
    allOpened: false,
    lastOpen: [],
    active: [],
  }),
  methods: {
    handleSearch: function (val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },
    toggleFold: function(){
      this.openAll = !this.openAll; 
      this.$refs.tree.updateAll(this.openAll);
    },
    loadTree: async function(){
      this.loading = true;
      this.treeItems =  await this.$store.dispatch('_getCommonTree');
      this.loadSelected()
      this.loading = false;
      this.$store.dispatch('_updateDashboardTree', this.treeItems);
    },
    findParents: function (tree, id) {
      let path = [];
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id === id) {
          return [tree[i]];
        }
        if (tree[i].children) {
          path = this.findParents(tree[i].children, id);
          if (path.length) {
            path.unshift(tree[i]);
            return path;
          }
        }
      }
      return path;
    },
    loadSelected: function(){
      if(this.entityId){
        const id = parseInt(this.entityId)
        this.active = [id];
        const parents = this.findParents(this.treeItems,  id);
        this.open = parents.map(p=>p.id).filter(p=>p!==id);
      }
    },
    selectItem: function(item) {
      //--- MOCK
        item.type = mocks.utils.getTypeFromTo(item.to);
      //--- MOCK

      this.$store.dispatch('_dashboardSelection', item);
      this.$router.push(item.to+'/'+item.id);
    },
  },
  created: function() {
    this.loadTree();
  }
}
</script>

import { router } from "@/_helpers";

export default {
  LOGIN_USER_DATA: (state, payload) => {
    if (!payload) {
      return;
    }
    const data = payload.data;
    state.snackbar.color = data.login.state;
    state.snackbar.text = data.login.msg;

    if (data.login.state === "success") {
      state.loggedIn = true;
      state.user = data.login;
      router.push("/");
    } else {
      state.loggedIn = false;
      state.user = {};
      state.machines = [];
    }

    state.caller = payload.data.caller;
  },
  LOGOUT_USER_DATA: (state, payload) => {
    if (!payload) {
      return;
    }
    // var data = payload.data.logout;
    // state.snackbar.color = data.state;
    // state.snackbar.text = data.msg;

    // if(data.state == 'success'){
    state.loggedIn = false;
    state.user = {};
    state.machines = [];

    if (router.history.current.path !== "/login") {
      router.push("/login");
    }
    state.caller = payload.data.caller;
  },
  UPDATE_USER_DATA: (state, payload) => {
    const userState = state.user;
    state.user = { ...userState, ...payload };
  },
  UPDATE_USER_PLANTS: (state, payload) => {
    state.plants = payload;
  },
  SET_TENANT: (state, tenant) => {
    state.tenant = tenant;
  },
};

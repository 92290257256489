<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <Info v-show="action === 'view'" :infoItems="infoItems"></Info>
            <InfoEdit
              v-show="action !== 'view'"
              :entityId="entityId"
              :area="area"
              :onDelete="() => (showDeleteConfirm = true)"
              :onSave="save"
              :onCancel="cancel"
            />
            <Confirm
              ref="confirmRef"
              :show="showDeleteConfirm"
              message="Do you really want to delete this item?"
              title="Delete Area"
              :onConfirm="onConfirmDelete"
              :onCancel="() => (showDeleteConfirm = false)"
            />
          </v-col>
          <v-col v-if="area && area.geoCoord" cols="8">
            <Map
              :longitude="area.geoCoord.longitude"
              :latitude="area.geoCoord.latitude"
              :radius="area.radius"
              :points="structures"
              :zoom="10"
            ></Map>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <Alerts :entityId="entityId" entityType="areas"></Alerts>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Info from "@/components/dashboard/area/Info";
import Map from "@/components/shared/Map";
import Alerts from "@/components/shared/Alerts";
import InfoEdit from "@/components/dashboard/area/InfoEdit";
import Confirm from "@/components/shared/Confirm";

export default {
  name: "Area",
  components: {
    Info,
    Map,
    Alerts,
    InfoEdit,
    Confirm,
  },
  props: {
    entityId: String,
    entityName: String,
    action: String,
  },
  data() {
    return {
      breadcrumbsItems: [],
      structures: [],
      infoItems: [],
      area: {},
      showDeleteConfirm: false,
      emptyArea: {
        geoCoord: { latitude: 0, longitude: 0 },
        children: [],
        radius: 10,
      },
    };
  },
  watch: {
    entityId: function () {
      this.loadData();
    },
  },
  created: function () {
    this.loadData();
  },
  methods: {
    /**
     * Maps input data into a more friendly format for Info component
     */
    mapData(input) {
      return [
        { id: 1, text: "Name", value: input.label },
        // {  id:2,  text: "Location", value: "TODO"}, // TODO:
        { id: 3, text: "Lat", value: input.geoCoord.latitude },
        { id: 4, text: "Lon", value: input.geoCoord.longitude },
        { id: 5, text: "Radius", value: `${input.radius} mt` },
        { id: 6, text: "Structures", value: input.nChildren },
        {
          id: 9,
          text: "Structure Alerts",
          value: input.structureAlerts,
          type: "alerts",
          route: "/alerts",
        },
        {
          id: 10,
          text: "System Alerts",
          value: input.systemAlerts,
          type: "alerts",
          route: "/alerts",
        },
      ];
    },

    /**
     * Converts input data in a more convenient format for Map component
     */
    mapStructures(input) {
      let output = [];

      input.forEach((element) => {
        output.push({
          name: element.label,
          longitude: element.geoCoord.longitude,
          latitude: element.geoCoord.latitude,
          status:
            !element.hasAnyAlert && !element.childrenHaveAlerts
              ? "NORMAL"
              : "ALERT",
        });
      });

      return output;
    },
    async loadData() {
      if (parseInt(this.$props.entityId) === 0) {
        this.area = this.emptyArea;
      } else {
        this.area = await this.$store.dispatch("_getArea", {
          entityId: this.$props.entityId,
        });
      }

      if(this.area){
        this.infoItems = this.mapData(this.area);
        this.structures = this.mapStructures(this.area.children);
      }else{
        this.area = {}
      }
    },
    async onConfirmDelete() {
      console.log("delete this log");
      this.$refs.confirmRef.loading = true;
      await this.$store.dispatch("_deleteArea", {
        areaId: this.$props.entityId,
      });
      this.$refs.confirmRef.loading = false;
      this.showDeleteConfirm = false;
      this.area = this.emptyArea;
      this.$router.push("/dashboard");
    },
    async save(data) {
      const action = this.entityId !== "0" ? "_updateArea" : "_createArea";

      const result = await this.$store.dispatch(action, {
        areaId: this.$props.entityId,
        data,
      });
      this.$router.push(`/dashboard/area/${result.areaId}`);
      this.loadData();
    },
    async cancel() {
      const result = await this.$store.dispatch("_getArea", {
        entityId: this.$props.entityId,
      });
      this.$router.push(`/dashboard/area/${result.areaId}`);
      this.loadData();
    },
  },
};
</script>

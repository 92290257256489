<template>
  <div class="pdd-container">
    <v-card :disabled="disabledPdd" flat>
      <v-card-text>
        <div class="btn-container my-2">
          <v-btn class="mx-1" fab light small color="primary" :disabled="zoom > 3"
            @click="() => (zoom < 3 ? (zoom += 0.2) : '')">
            <v-icon dark> mdi-magnify-plus-outline </v-icon>
          </v-btn>

          <v-btn class="mx-1" fab light small color="primary" :disabled="zoom < 0.3"
            @click="() => (zoom > 0.3 ? (zoom -= 0.2) : '')">
            <v-icon dark> mdi-magnify-minus-outline </v-icon>
          </v-btn>
        </div>
        <v-btn fab light small color="primary" class="btn-expand mx-1 my-2" @click="expand">
          <v-icon dark> mdi-arrow-expand </v-icon>
        </v-btn>
        <div class="zoomable" ref="container">
          <div class="zoom-content" :style="{ transform: `scale(${zoom})` }">
            <img ref="image" v-src-auth="`storage/plant/img/${plantId}`" style="max-width: none"
              @image-loaded="imageLoaded" />
            <v-chip v-for="(sensor, index) in positionedSensors" :key="index" label class="ma-2" :style="{
              backgroundColor: sensor.connected
                ? 'rgba(50, 250, 50, 0.8)'
                : 'rgba(250, 50, 50,0.8)',
              position: 'absolute',
              left: `${sensor.posX}px`,
              top: `${sensor.posY}px`,
            }">
              {{ sensor.label }}
            </v-chip>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" fullscreen persistent>
      <v-card style="position: relative">
        <v-card-text>
          <div style="position: fixed" class="btn-container mx-4 my-2">
            <v-btn class="mx-1" fab light big color="primary" :disabled="zoom > 3"
              @click="() => (zoom < 3 ? (zoom += 0.2) : '')">
              <v-icon dark> mdi-magnify-plus-outline </v-icon>
            </v-btn>

            <v-btn class="mx-1" fab light big color="primary" :disabled="zoom < 0.3"
              @click="() => (zoom > 0.3 ? (zoom -= 0.2) : '')">
              <v-icon dark> mdi-magnify-minus-outline </v-icon>
            </v-btn>
          </div>
          <v-btn fab light big color="primary" class="btn-expand mx-5 my-2" style="position: fixed" @click="collapse">
            <v-icon dark> mdi-arrow-collapse </v-icon>
          </v-btn>
          <div class="zoomable dialog" ref="container-dialog">
            <div class="zoom-content" :style="{ transform: `scale(${zoom})` }">
              <img ref="image-dialog" v-src-auth="`storage/plant/img/${plantId}`" style="max-width: none"
                @image-loaded="imageLoadedDialog" />
              <v-chip v-for="(sensor, index) in positionedSensors" :key="index" label class="ma-2" :style="{
                backgroundColor: sensor.connected
                  ? 'rgba(50, 250, 50, 0.8)'
                  : 'rgba(250, 50, 50,0.8)',
                position: 'absolute',
                left: `${sensor.posX}px`,
                top: `${sensor.posY}px`,
              }">
                {{ sensor.label }}
              </v-chip>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.pdd-container {
  height: 100%;
}

.zoomable {
  position: relative;
  width: 100%;
  height: 370px;
  overflow: auto;
}

.zoomable.dialog {
  height: 100vh;
}

.zoom-content {
  position: relative;
  transition: transform 0.3s ease;
  transform-origin: top left;
  width: 100%;
  height: 100%;
}

.btn-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.btn-expand {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      zoom: 1,
      positionedSensors: [],
      dialog: false,
      imgLoaded: false,
      imgDialogLoaded: false,
      disabledPdd: false,
    };
  },
  computed: {
    ...mapState(["plantId"]),
  },
  created: function () {
    this.getData();
  },
  watch: {
    plantId: function () {
      this.getData();
    },
    dialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.onDialogEnter();
        });
      }
    },
  },
  methods: {
    getData: async function () {
      const response = await this.$store.dispatch(
        "_get2DInspectionData",
        this.plantId
      );
      this.plant = response.label;
      this.img = response.img;
      this.positionedSensors = response.sensors.filter((x) => x.posX && x.posY);
    },
    expand() {
      this.dialog = true;
    },
    onDialogEnter() {
      if (this.imgDialogLoaded) {
        this.imageLoadedDialog();
      }
    },
    collapse() {
      this.dialog = false;
      if (this.imgLoaded) {
        this.imageLoaded();
      }
    },
    imageLoaded(evt) {
      const img = this.$refs.image;
      if (evt && evt.detail?.notFound) {
        this.disabledPdd = true;
      } else {
        const container = this.$refs.container;
        this.imgLoaded = true;
        this.adaptImageZoom(img, container);
      }
    },
    imageLoadedDialog() {
      const img = this.$refs["image-dialog"];
      const container = this.$refs["container-dialog"];
      this.imgDialogLoaded = true;
      this.adaptImageZoom(img, container);
    },
    adaptImageZoom(img, container) {
      if (!img || !container) {
        return;
      }
      // Calcolare lo zoom in base alle dimensioni dell'immagine e del contenitore
      const containerWidth = container.offsetWidth;
      const imageWidth = img.naturalWidth;

      if (imageWidth > containerWidth) {
        this.zoom = containerWidth / imageWidth;
      } else {
        this.zoom = 1; // Se l'immagine è più piccola, zoom al 100%
      }
    },
  },
};
</script>

<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <Info
              ref="info"
              :action="action"
              :structure="structure"
              :infoItems="infoItems"
              :onSave="save"
              :onDelete="() => (showDeleteConfirm = true)"
              :onCancel="cancel"
              :updateConfigurator="updateConfigurator"
            ></Info>
          </v-col>
          <v-col cols="8">
            <v-tabs v-model="tab">
              <v-tab key="map"> 
                  <v-icon left color="orange lighten-1">mdi-map marker</v-icon>
                  {{ $t('map') }}
              </v-tab>
              <v-tab key="pdd">
                <v-icon left color="red lighten-1">mdi-map-marker-multiple </v-icon>
                PDD
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item key="map">
                <Map
                :longitude="longitude"
                :latitude="latitude"
                :radius="radius"
                :points="structures"
                :zoom="10"
                :show-title="false"
              ></Map>
              </v-tab-item>
              <v-tab-item key="pdd">
                <Pdd />
              </v-tab-item>
          </v-tabs-items>
          </v-col>
        </v-row>
        <v-row v-if="action === 'view'">
          <v-col cols="12">
            <Alerts :entityId="entityId"></Alerts>
          </v-col>
        </v-row>
        <v-row v-if="action === 'view'">
          <v-col cols="12">
            <Ensembles entityType="structure" :entityId="entityId"></Ensembles>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="add-bottom-spacer">
            <Users
              :action="action"
              ref="users"
              :loading="loadingUsers"
              :items="filteredUsers"
              :selected="assignedUsers"
            ></Users>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Confirm
      :show="showDeleteConfirm"
      message="Do you really want to delete this item?"
      title="Delete Structure"
      :onConfirm="onConfirmDelete"
      :onCancel="() => (showDeleteConfirm = false)"
    />
  </v-container>
</template>
<style scoped>
.add-bottom-spacer {
  margin-bottom: 2em;
}
</style>
<script>
import Info from "@/components/dashboard/structure/Info";
import Map from "@/components/shared/Map";
import Pdd from '@/components/shared/Pdd';
import Alerts from "@/components/shared/Alerts";
import Ensembles from "@/components/shared/Ensembles";
import Users from "@/components/dashboard/structure/Users";
import { mapState } from "vuex";
import Confirm from "@/components/shared/Confirm";

export default {
  name: "Structure",
  components: {
    Info,
    Map,
    Alerts,
    Ensembles,
    Users,
    Confirm,
    Pdd,
  },
  props: {
    entityId: String,
    action: String,
  },
  computed: {
    ...mapState(["dashboardSelection", "user"]),
  },
  data() {
    return {
      breadcrumbsItems: [],
      infoItems: [],
      longitude: 0,
      latitude: 0,
      radius: 0,
      structures: [],
      areaId: "",
      structure: {},
      showDeleteConfirm: false,
      users: [],
      filteredUsers: [],
      loadingUsers: false,
      assignedUsers: [],
      tab: "map",
    };
  },
  watch: {
    entityId: function () {
      this.loadData();
    },
    action: function (newVal) {
      if (newVal !== "view") {
        this.loadUsers();
      }
    },
  },
  mounted: async function () {
    await this.loadData();
    if (this.action !== "view") {
      this.loadUsers();
    }
  },
  created: function () {},
  methods: {
    /**
     * Maps input data into a more friendly format for Info component
     */
    mapData(input) {
      const startDate = input.monitoringDateTimeStart
        ? this.$date
            .fromISO(input.monitoringDateTimeStart)
            .toFormat(
              this.$store.state.user.configParams.dateFormat || "dd/MM/yyyy"
            )
        : this.$t("structure.info.placeholder");
      return [
        { id: 1, text: "Name", value: input.label },
        { id: 2, text: "Structure Type", value: input.structureType },
        { id: 11, text: "Configurator", value: input.configurator },
        // {  id:2, text: "Location", value: "TODO"},
        { id: 3, text: "Latitude", value: input.geoCoord.latitude },
        { id: 4, text: "Longitude", value: input.geoCoord.longitude },
        { id: 6, text: "Start Date", value: startDate },
        {
          id: 9,
          text: "Structure Alerts",
          value: input.structureAlerts,
          type: "alerts",
          route: "/alerts",
        },
        {
          id: 10,
          text: "System Alerts",
          value: input.systemAlerts,
          type: "alerts",
          route: "/alerts",
        },
      ];
    },
    /**
     * load entity data async
     */
    async loadData() {
      this.assignedUsers = [];

      if (parseInt(this.$props.entityId) === 0) {
        this.resetData();
      } else {
        try {
          const data = await this.$store.dispatch("_getStructure", {
            structureId: this.$props.entityId,
          });

          if (!data) {
            return;
          }

          // info box: set data
          this.structure = data;
          this.structure.location = data.geoCoord;

          this.infoItems = this.mapData(this.structure);

          this.areaId = data.parentId;

          // map: set radius and location for area circle
          this.longitude = data.geoCoord.longitude;
          this.latitude = data.geoCoord.latitude;

          this.assignedUsers = data.assignedUsers;

          // map: set structures
          this.structures = [
            {
              name: data.label,
              longitude: data.geoCoord.longitude,
              latitude: data.geoCoord.latitude,
              status: !data.hasAnyAlert ? "NORMAL" : "ALERT",
            },
          ];
          this.$store.dispatch("_setLoading", false);
        } catch (e) {
          console.error(e);
          this.$store.dispatch("_setLoading", false);
        }
      }
    },
    resetData() {
      this.structure = {
        label: "",
        note: "",
        configurator: "",
        structureType: "",
        monitoringDateTimeStart: this.$date.now().toMillis(),
        healthyPeriodStartDate: this.$date.now().toMillis(),
        healtyPeriodEndDate: this.$date.now().toMillis(),
        location: {
          latitude: 0,
          longitude: 0,
        },
        areaId: this.dashboardSelection.area,
      };
      this.infoItems = [];
      this.longitude = 0;
      this.latitude = 0;
      this.radius = 0;
      this.structures = [];
      this.areaId = this.dashboardSelection.area || "";
    },

    async save(item) {
      const action = item.structureId ? "_updateStructure" : "_createStructure";

      let originalUsersId = [];

      if (item.assignedUsers) {
        originalUsersId = item.assignedUsers.map((x) => x.id);
      }

      const updatedUsersId = this.$refs.users.selectedItems.map((x) => x.id);

      const addUsers = updatedUsersId.filter(
        (x) => !originalUsersId.includes(x)
      );
      const removeUsers = originalUsersId.filter(
        (x) => !updatedUsersId.includes(x)
      );

      console.log("save monitoringDateTimeStart", item.monitoringDateTimeStart);

      const data = {
        label: item.label,
        name: item.label,
        note: item.note,
        monitoringDateTimeStart: item.monitoringDateTimeStart,
        startHealthPeriodDateTime: item.healthyPeriodStartDate,
        endHealthPeriodDateTime: item.healtyPeriodEndDate,
        configurator: item.configurator,
        structureTypeId: item.structureTypeId,
        addUsers,
        removeUsers,
        configuratorId: item.configuratorId,
        geoCoord: {
          latitude: parseFloat(item.location.latitude),
          longitude: parseFloat(item.location.longitude),
        },
        areaId: item.areaId || item.parentId,
      };

      await this.$store.dispatch(action, {
        structureId: this.$props.entityId,
        data,
      });

      if (item.structureId) {
        this.$router.push(`/dashboard/structure/${item.structureId}`);
        this.loadData();
      } else {
        this.$router.push(`/dashboard/area/${item.areaId || item.parentId}`);
        this.resetData();
      }
    },

    async onConfirmDelete() {
      this.showDeleteConfirm = false;
      this.$refs.info.setLoading(true);

      await this.$store.dispatch("_deleteStructure", {
        structureId: this.structure.structureId,
      });

      this.$refs.info.setLoading(false);
      this.$router.push(`/dashboard/area/${this.structure.parentId}`);
    },

    async loadUsers() {
      this.loadingUsers = true;
      this.users = await this.$store.dispatch("_getMembers");
      if (this.structure.configuratorId) {
        this.filteredUsers = this.users.filter(
          (x) => x.id !== this.structure.configuratorId
        );
      } else {
        this.filteredUsers = this.users;
      }
      this.loadingUsers = false;
    },
    updateConfigurator(configuratorId) {
      this.filteredUsers = this.users.filter((x) => x.id !== configuratorId);
    },
    cancel() {
      this.$router.push(`/dashboard/structure/${this.$props.entityId}`);
      this.loadData();
    },
  },
};
</script>

<template>
  <v-form>
    <v-container>

    <v-row v-if="filtersType === '2'">
        <v-col cols="4" md="4">
          <v-radio-group
            v-model="type"
            row
            label="SERIES"
            @change="filterItems"
          >
          <v-radio
            v-for="item in itemsSeries"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="4" md="4">
          <v-text-field label="Sensor" v-model="sensor" clearable @input="onSearchSensor"></v-text-field>
        </v-col>

      </v-row>

      <v-row>
        <v-col :cols="filtersType === '2' ? 4 : 4" :md="filtersType === '2' ? 4 : 4">
          <v-select
            v-model="area"
            :items="itemsArea"
            label="Area"
            dense
            item-text="label"
            item-value="label"
            clearable
            return-object
            @change="changeArea"
            >
          </v-select>
        </v-col>

        <v-col :cols="filtersType === '2' ? 4 : 4" :md="filtersType === '2' ? 4 : 4">
           <v-select
            v-model="structure"
            :items="itemsStructureFiltered"
            label="Structure"
            dense
            item-text="label"
            item-value="label"
            clearable
            return-object
            @change="changeStructure"
            >
          </v-select>
        </v-col>

        <v-col :cols="filtersType === '2' ? 4 : 4" :md="filtersType === '2' ? 4 : 4">
           <v-select
            v-model="element"
            :items="itemsElementFiltered"
            label="Element"
            dense
            item-text="label"
            item-value="label"
            clearable
            return-object
            filterItems
            @change="filterItems"
            >
          </v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-form>

</template>


<script>
import { mapState } from 'vuex';
export default {
  name: 'Filters',
  props: [
    'onFilter',
    'filtersType'
  ],
  data: () => ({
    valid: false,
    area:'',
    structure:'',
    element:'',
    itemsArea: [],
    itemsSeries: [],
    itemsStructure: [],
    itemsStructureFiltered: [],
    itemsElement: [],
    itemsElementFiltered: [],
    sensor: '',
    type: 1
  }),
  computed: {
    ...mapState(['plantId'])
  },
  watch: {
    plantId(newVal, oldVal) {
      if(newVal !== oldVal){
        this.initialize()
        this.clearFilters()
      }
    }
  },
  methods: {
    initialize(){
      this.loadSeries()
      this.loadArea()
      this.loadStructure()
      this.loadElement()
    },
    async loadSeries(){
      this.itemsSeries =  await this.$store.dispatch('_getSeries');
      if(this.itemsSeries.length){
        this.type = this.itemsSeries[0].id
      }
    },
    async loadArea(){
      this.itemsArea =  await this.$store.dispatch('_getAreas');
    },
    async loadStructure(){
      this.itemsStructure =  await this.$store.dispatch('_getStructures');
      
      this.itemsStructureFiltered =  this.itemsStructure;
    },
    async loadElement(){
      this.itemsElement =  await this.$store.dispatch('_getElements');
      this.itemsElementFiltered = this.itemsElement;
    },
    filterItems(){
      let sensorSeries;
      if(this.type){
        sensorSeries = this.itemsSeries.find(x=> x.id === this.type)
        sensorSeries = sensorSeries && sensorSeries.name
      }
      this.onFilter({
        area: this.area,
        element: this.element,
        structure: this.structure,
        sensor: this.sensor,
        type: this.type,
        sensorSeries
      })
    },
    changeArea(area){
      if(!area){
        this.itemsStructureFiltered = this.itemsStructure
        this.itemsElementFiltered = this.itemsElement
      } else {
        this.itemsStructureFiltered = this.itemsStructure.filter(x=> x.parentId === area.areaId )
      }

      this.element = null
      this.filterItems()
    },
    changeStructure(structure){
      if(!structure){
        this.itemsElementFiltered = this.itemsElement
      } else {
        this.itemsElementFiltered = this.itemsElement.filter(x=> x.parentId === structure.structureId )
      }
      this.filterItems()
    },
    onSearchSensor(){
      clearTimeout( this._timerId );
      this._timerId = setTimeout( () => {
        this.filterItems( );
      }, 500 );
    },
    clearFilters(){
      this.area = '';
      this.element = '';
      this.structure = '';
      this.sensor = '';
      this.type = 1;
    }
  },
  created: function() {
   this.initialize()
  },
  
}
</script>

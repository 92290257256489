import * as d3 from 'd3'
/**
 * Creates a custom loader component
 * @class
 */
export default class SysLoader{

    #ref;
    #loader;
    #content;
    #transitionTime;
    #target;
    #targetArea;
    #path;
    
    /**
     * 
     * @param {*} params - allowed params: 
     *  ref {Object} - graph object
     *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects
     *  targetArea {String} - id of HTML/SVG element to cover during loading 
     */
    constructor(params){
      this.#ref = params.ref;
      this.#target = this.#ref.getTarget();
      // can be both HTML or SVG element
      this.#targetArea = (params.targetArea || null) !== null ? d3.select(`#${this.#target}`).select(params.targetArea) :  d3.select(`#${this.#target}`);
      this.#transitionTime = params.transitionTime || 500;
      this.#path = this.#ref.getPath(); 
      
      this.create();
      this.renderContent();
  
    }
  
    /**
     * Creates a loading layer
     */
    create(){
  
      // create status
      this.#loader = d3.select(`#${this.#target}`)
        .append("div")
        .attr("class", "sys-status")
        .style("top", -500)
        .style("left", -500)
        .style("width", 0)
        .style("height", 0)
        .style("opacity", 0)
        
  
      this.#content = this.#loader
        .append("div")
        .attr("class", "sys-status-content");
    
    }
  
    /**
     * Renders loading image
     */
    renderContent(){

      let html = `<img src="${this.#path}/images/loader_2.png"/>`;

      this.#content.html(html);
    }
  
    /**
     * Opens loader
     */
    open(){

      let targetObject = d3.select(`#${this.#target}`);
      let targetAreaObject = this.#targetArea; 
      // main html container
      let targetBbox = targetObject.node().getBoundingClientRect();
      // target area box
      let bbox;
      let top, left, width, height;

      // target area box, can be HTML or SVG element
      // I need to check which box model use
      // SVG
      if(typeof targetAreaObject.node().getBBox === "function"){
        bbox = targetAreaObject.node().getBBox();
      // HTML
      } else {
        bbox = targetAreaObject.node().getBoundingClientRect();
      }
       
      // target and area target are the same, cannot consider margins 
      if(targetObject.attr("id") === targetAreaObject.attr("id")){
        top = targetObject.node().offsetTop; // ??
        left = targetObject.node().offsetLeft; // ??
        width = this.#ref.outerWidth; 
        height = this.#ref.outerHeight;
      // area target is an element inside target
      } else {
        top = targetBbox.top + this.#ref.margin.top -7; // ??
        left = targetBbox.left + this.#ref.margin.left + 1; // ??
        width = bbox.width;
        height = bbox.height;
      }

      this.#loader
      .style("width", `${width}px`)
      .style("height", `${height}px`)
      .style("top", `${top}px`)
      .style("left", `${left}px`)
      .transition().duration(this.#transitionTime).style("opacity", 0.9);
    }
    
    /**
     * Closes loader
     */
    close(){
      var that = this;
      
      this.#loader.transition().duration(this.#transitionTime).style("opacity", 0).on("end", () => {
        that.#loader
          .style("width", 0)
          .style("height", 0)
          .style("top", -500)
          .style("left", -500);
      });
      
    }
  
  }
<template>
  <div>
    <div @click="deleteWidget(data.widgetId)" class="sys-lc-delete-btn" title="Delete widget">
      <img src="../../plugins/sysCharts/images/ic_delete_24px_white.svg" class="sys-lc-btn">
    </div>

    <div :id="idTarget">

    </div>
    <div v-if="ready && data.graphWidgetTypeId === 3">
      <div class="d-flex align-center" style="padding-top:5px; height: 65px;">
        <v-select v-model="selectedEnsemblesSeismic" :items="ensemblesSeismic" label="Ensembles" multiple chips
          item-text="label" item-value="ensembleId" clearable dense>
          <template v-slot:selection="{ item, index }">
            <v-chip small v-if="index === 0">
              <span>{{ item.label }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedEnsemblesSeismic.length - 1 }} others)
            </span>
          </template>
        </v-select>
        <v-btn @click="loadSeismicData" fab small color="primary" :disabled="!selectedEnsemblesSeismic.length"
          style="margin-right: 40px;">
          <v-icon>mdi-find-replace</v-icon>
        </v-btn>
      </div>
      <v-data-table :loading="loadingSeismTable" :headers="tableSeismHeaders" :items="tableSeismItems" item-key="id"
        :items-per-page="-1" hide-default-footer fixed-header :height="tableHeight">
        <template v-slot:[`item.meanFrequency`]="{ item }">
          <div class="d-flex flex-column align-end">
            <span>{{ item.meanFrequencyX }}</span>
            <span>{{ item.meanFrequencyY }}</span>
            <span>{{ item.meanFrequencyZ }}</span>
          </div>
        </template>
        <template v-slot:[`item.peakAccelPos`]="{ item }">
          <div class="d-flex flex-column align-end">
            <span>{{ item.peakAccelerationPositiveX }}</span>
            <span>{{ item.peakAccelerationPositiveY }}</span>
            <span>{{ item.peakAccelerationPositiveZ }}</span>
          </div>
        </template>
        <template v-slot:[`item.peakAccelNeg`]="{ item }">
          <div class="d-flex flex-column align-end">
            <span>{{ item.peakAccelerationNegativeX }}</span>
            <span>{{ item.peakAccelerationNegativeY }}</span>
            <span>{{ item.peakAccelerationNegativeZ }}</span>
          </div>
        </template>
        <template v-slot:[`item.peakAccelPosTime`]="{ item }">
          <div class="d-flex flex-column align-end">
            <span>{{ item.peakAccelerationPositiveXTime }}</span>
            <span>{{ item.peakAccelerationPositiveYTime }}</span>
            <span>{{ item.peakAccelerationPositiveZTime }}</span>
          </div>
        </template>
        <template v-slot:[`item.peakAccelNegTime`]="{ item }">
          <div class="d-flex flex-column align-end">
            <span>{{ item.peakAccelerationNegativeXTime }}</span>
            <span>{{ item.peakAccelerationNegativeYTime }}</span>
            <span>{{ item.peakAccelerationNegativeZTime }}</span>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style src="../../plugins/sysCharts/css/sys-lc-white.css"></style>

<script>
import SysLineChart from '../../plugins/sysCharts/src/SysLineChart';

export default {
  name: 'Widget',
  props: [
    'idTarget',
    'deleteWidget',
    'data',
    'vuetify',
    'store',
  ],
  data: function () {
    return {
      count: 0,
      widget: null,
      loadingSeismTable: false,
      tableSeismHeaders: [
        { text: 'Sensor', value: 'sensor' },
        { text: 'Time', value: 'time' },
        { text: 'Duration', value: 'duration' },
        { text: 'Main Mean Frequency (x,y,z)', value: 'meanFrequency' },
        { text: 'Peak Acceleration Positive (x,y,z)', value: 'peakAccelPos' },
        { text: 'Peak Acceleration Negative (x,y,z)', value: 'peakAccelNeg' },
        { text: 'Peak Acceleration Positive Time (x,y,z)', value: 'peakAccelPosTime' },
        { text: 'Peak Acceleration Negative Time (x,y,z)', value: 'peakAccelNegTime' },
      ],
      tableSeismItems: [
      ],
      ready: false,
      tableHeight: 0,
      selectedEnsemblesSeismic: [],
      ensemblesSeismic: [],
      filters: {}
    }
  },
  mounted: function () {
    // non carica vuetify e devo passarlo come props ma solo dopo che è stato caricato con ready = true 
    this.$vuetify = this.vuetify;
    this.$store = this.store;
    setTimeout(() => { //TODO:; togliere capire perchè senza non è ancora disbonible il target es: let target = d3.select(`#${this.#ref.getTarget()}`);
      this.loadChart()
      this.ready = true
    }, 200);
  },
  methods: {
    loadChart() {
      this.setFilters();
      if (this.data.graphWidgetTypeId === 3) {
        this.loadSeismicWidget();
      } else {
        this.loadSysChart();
      }
    },
    setFilters() {
      const baseUrl = (window.shbox2?.baseUrl ?? process.env.VUE_APP_API_URL).replace(/\/$/, '');

      const _storage = JSON.parse(localStorage.getItem("vuex"));
      const plantId = _storage.plantId;
      const token = _storage.user.token;
      let dateFrom = this.$date.now();
      let dateTo = this.$date.now().plus({ days: 1 });

      // can manage both formats: 'yyyy-MM-dd' and milliseconds
      if (_storage.user.sessionParams) {
        if (_storage.user.sessionParams.dateFrom) {
          // string format yyyy-MM-dd
          if (!isNaN()) {
            dateFrom = this.$date.fromJSDate(new Date(_storage.user.sessionParams.dateFrom));
            // timestamp format
          } else {
            dateFrom = this.$date.fromJSDate(new Date(_storage.user.sessionParams.dateFrom * 1));
          }
        }

        if (_storage.user.sessionParams.dateTo) {
          // string format yyyy-MM-dd
          if (!isNaN()) {
            dateTo = this.$date.fromJSDate(new Date(_storage.user.sessionParams.dateTo));
            // timestamp format
          } else {
            dateTo = this.$date.fromJSDate(new Date(_storage.user.sessionParams.dateTo * 1));
          }
        }
      }

      this.filters = {
        dateFrom,
        dateTo,
        plantId,
        token,
        baseUrl,
      };
    },
    loadSysChart() {
      const {
        baseUrl,
        plantId,
        token,
        dateFrom,
        dateTo,
      } = this.filters;

      const div = document.querySelector('.grid-stack-item-content');

      const containerWidth = div.offsetWidth - 10;
      const containerHeight = div.offsetHeight;

      this.widget = new SysLineChart({
        target: this.idTarget,
        width: containerWidth,
        height: containerHeight,
        axisMode: this.data?.graphWidgetTypeId === 2 ? 2 : 3, // type 2 => 2 axis; type 1 = 3 axis
        externalId: this.data.widgetId,
        menu: true,
        filter: true,
        path: './',  // TOFIX: get the right path for images
        baseUrl,
        plantId, // TOFIX:  used only to correct wrong data from TW
        searchUrl: `${baseUrl}/plant/${plantId}/widget/${this.data.widgetId}/data`,
        checkUrl: `${baseUrl}/plant/${plantId}/widget/${this.data.widgetId}/getParams`,
        saveUrl: `${baseUrl}/plant/${plantId}/widget/${this.data.widgetId}/params`,
        filterCallback: this.filterCallback,
        searchCallback: this.searchCallback,
        handleFilterCallback: this.handleFilterCallback,
        headers: [{ Authorization: `Bearer ${token}` }],
        externalParams: {
          plantId: plantId,
          startDate: dateFrom.toISO(),
          endDate: dateTo.toISO()
        }
      });
    },
    async filterCallback() {
      // Filtra tutti gli ensembles che non hanno l'algoritmo "SEISMIC"
      const filteredData = Object.fromEntries(
        Object.entries(this.data.ensembles).filter((x) => x[1].algorithm !== "SEISMIC")
      );

      return filteredData;
      // return this.data.ensembles;
    },
    async searchCallback(params){
      const data = await this.$store.dispatch('_getWidgetData', {
        widgetId: this.data.widgetId,
        body: params
      })
      return data;
    },
    async handleFilterCallback(params){
      await this.$store.dispatch('_deleteWidgetCache', {widgetId: this.data.widgetId});
      const data = await this.searchCallback(params);
      return data;
    },
    async loadSeismicWidget() {
      // Filtra tutti gli ensembles che hanno l'algoritmo "SEISMIC"
      this.ensemblesSeismic = Object.values(this.data.ensembles).filter(x => x.algorithm === 'SEISMIC');

      const div = document.querySelector('.grid-stack-item-content');
      this.tableHeight = div.offsetHeight - 65 - 10; // altezza select - padding-bottom

      // carico eventuali parametri salvati
      const savedParams = await this.$store.dispatch('_getWidgetParams', {
        widgetId: this.data.widgetId,
        body: {
          widgetId: this.data.widgetId
        }
      });

      if (savedParams && savedParams.params && savedParams.params.query && savedParams.params.query.params) {
        this.selectedEnsemblesSeismic = savedParams.params.query.params.ensembleList;
        this.loadSeismicData(false);
      }

    },
    async loadSeismicData(saveParams = true) {
      this.loadingSeismTable = true;

      const {
        dateFrom,
        dateTo,
      } = this.filters;

      if (saveParams) {
        //salvo i parametri per il prossimo caricamento
        await this.$store.dispatch('_setWidgetParams', {
          widgetId: this.data.widgetId,
          body: {
            params: {
              query: {
                params: {
                  ensembleList: this.selectedEnsemblesSeismic
                }
              },
            },
            widgetId: this.data.widgetId
          }
        });
      }

      const response = await this.$store.dispatch('_getWidgetSeismic', {
        widgetId: this.data.widgetId,
        body: {
          startDate: dateFrom.toISO(),
          endDate: dateTo.toISO(),
          ensembleList: this.selectedEnsemblesSeismic
        }
      });

      const CONF = this.$store.state.user.configParams;

      this.tableSeismItems = response.map(x =>
      ({
        ...x,
        time: this.$date.fromISO(x.time).toFormat(CONF.dateTimeFormat),
      })
      );

      this.loadingSeismTable = false;
    },
  },
}

</script>

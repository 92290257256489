<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="2">
        <Tree ref="treeMenu" :entityId="p_entityId"/> 
      </v-col>
      <v-col cols="10">
        <v-row>
          <v-col cols="12">
            <div v-if="section=='area'">
              <Area :action="action" :entityId="p_entityId" :entityName="p_entityName"/>
            </div>
            <div v-if="section=='structure'">
                <Structure :action="action" :entityId="p_entityId"/>
            </div>
            <div v-if="section=='element'">
                <Element :action="action" :entityId="p_entityId"/>
            </div>
            <div v-if="section=='point'">
                <Point :action="action" :entityId="p_entityId"/>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SecondaryMenu :entityId="p_entityId" :routeFrom="`/dashboard/${section}`"/>
 </v-container>
</template>

<script>
  import Tree from '@/components/shared/Tree';
  import Area from '@/views/dashboard/Area';
  import Structure from '@/views/dashboard/Structure';
  import Element from '@/views/dashboard/Element';
  import Point from '@/views/dashboard/Point';
  import SecondaryMenu from '@/components/shared/SecondaryMenu';
  import DashboardBreadcrumbsManager from '@/mixins/dashboardBreadcrumbsManager';
  import { mapState } from 'vuex';

  export default {
    name: 'Dashboard',
    components: {
      Tree,
      Area,
      Structure,
      Element,
      Point, 
      SecondaryMenu
    },
    props:{
      entityId:{
        type: String,
        default: ''
      },
      entityName:{
        type: String,
        default: ''
      },
      action:{
        type: String,
        default: 'view' 
      },
    },
    mixins: [DashboardBreadcrumbsManager],
    computed: {
    ...mapState(['plantId'])
    },
    data: function(){
      return {
        // cannot edit directly entityId, I need to pass through a "private" variable 
        p_entityId: this.entityId,
        p_entityName: this.entityName,
        section: 'area'
      }
    },
    methods: {
      /**
       * Get laste selected leaf from tree menu
       */
      getSelected: function(){
        return this.$store.state.user.configParams.selectedLeaf || null;
      },

      /**
       * get action from route path
       */
      getAction: function(){
        return this.$route.params.action || 'view';
      }
      
    },
    created: function(){
      // when p_entityId is not defined
      if(this.p_entityId === '' || this.p_entityId === undefined){
        // if there's a route param, use it
        if(this.$route.params.entityId !== '' && this.$route.params.entityId !== undefined){
          this.p_entityId = this.$route.params.entityId;
        // no route param, use user default instead
        } else {
          this.p_entityId = this.getSelected();
        }
      }

      // this.section = this.getType(this.p_entityId);
      this.section = this.$route.params.section || '';
      this.loadBreadcrumbs(this.p_entityId);
      
    },
    watch: {
      // every change on entity id, must be passed to private variable
      entityId: function (val) {
        this.p_entityId = val;
        this.loadBreadcrumbs(val);
      },

      // trace any change to path
      $route: function(to, from){
        if(to.params.section && to.params.section !== this.section){
          this.section = to.params.section
        }

        if(!to.params.section){
          this.section = null
        }
        
        // if I'm coming from an edit page, reload tree menu
        if(from.params.action && ["edit", "new"].indexOf(from.params.action) !== -1){
          this.$refs.treeMenu.loadTree();
        }
        this.loadBreadcrumbs(this.p_entityId);
      },
      plantId(newVal, oldVal) {
        if(newVal !== oldVal){
          if(this.$route.path !== '/dashboard'){
            this.$router.push(`/dashboard`);
          }
          this.$refs.treeMenu.loadTree();
        }
      }

    }
  }
</script>

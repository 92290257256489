<template>
  <v-container class="px-0">
    <Filters ref="filtersRef" :onFilter="onFilter" filtersType="2" />
    <v-row>
      <v-col cols="6">
        <Points ref="pointsRef" :updateSelected="updateSelected" />
      </v-col>
      <v-col cols="6">
        <v-card flat>
          <v-card-title primary-title>
            <v-icon left color="green darken-2">mdi-file-excel</v-icon>
            {{ $t("export") }}
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <Date-picker
                id="dateFrom"
                :rules="[rules.required]"  
                :timestamp="dateFrom" 
                label="Date From" 
                @update:timestamp="changeFilters"
              />
              <Date-picker
                id="dateTo" 
                :timestamp="dateTo" 
                label="Date To"
                @update:timestamp="changeFilters" 
              />
              <v-select
                v-model="groupBy"
                :items="groupByItems"
                label="Group By"
                dense
                item-text="text"
                item-value="value"
                clearable
                return-object
                required
              >
              </v-select>
              <!-- <v-select 
                v-model="selectedFormat" 
                label="Format"
                :items="formats"
                :rules="[rules.required]" 
              >
              </v-select>
              <v-text-field  :rules="[rules.required]" label="Comma separator" v-model="commaSeparator"></v-text-field>
              <v-text-field :rules="[rules.required]"  label="Field separator" v-model="fieldSeparator"></v-text-field> -->
            </v-form>
            <v-btn
              :disabled="
                selectedPoints.length === 0 || !valid || loading || !groupBy
              "
              @click="managementPreview()"
              color="success"
            >
              <v-icon>mdi-file-find</v-icon>&nbsp;Preview </v-btn
            >&nbsp;
            <v-btn
              :disabled="!exportData"
              @click="managementExport()"
              color="success"
            >
              <v-icon>mdi-export</v-icon>&nbsp;Export
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card flat class="mt-4">
      <v-card-text>
        <p class="text-h5 text--primary">
          <v-icon left color="green darken-2">mdi-file-find</v-icon>
          Preview
        </p>

        <v-row>
          <v-col cols="12">
            <v-data-table :items="dataItems" :headers="headers"></v-data-table>
            <v-tabs v-if="exportData" v-model="selectedTab">
              <v-tab v-for="(item, index) in exportData" :key="index">
                {{ index }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>



<script>
import Filters from "@/components/shared/Filters";
import Points from "@/components/shared/Points";
import DatePicker from "@/components/shared/DatePicker";
import * as XLSX from "xlsx";
import { mapState } from 'vuex';

export default {
  name: "Export",
  components: {
    Filters,
    Points,
    DatePicker,
  },
  computed: {
    ...mapState(['plantId']),
    rules() {
      return this.$script.formRules;
    },
  },
  watch: {
    selectedTab(newVal) {
      if (newVal !== undefined) {
        let index = newVal;
        const messageTypes = Object.keys(this.exportData.data ? this.exportData.data : this.exportData);


        if (messageTypes.length <= index) {
          index = 0;
        }

        const currentType = messageTypes[index];
        this.headers = [];

        if(Array.isArray(this.exportData[currentType])){
          this.exportData[currentType].forEach((elem) => {
            Object.keys(elem).forEach((singleHeader) => {
              if (!this.headers.find((x) => x.value === singleHeader)) {
                this.headers.push({
                  text: singleHeader,
                  value: singleHeader,
                  align: "start",
                });
              }
            });
          });
        }

        this.dataItems = this.exportData[currentType];
      }
    },
    plantId(newVal, oldVal) {
      if(newVal !== oldVal){
        this.onFilter({
          type: this.$refs.filtersRef.type,
      });
      }
    },
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          href: "/home",
          disabled: false,
          text: this.$t("home").toUpperCase(),
          to: "/home",
        },
        { href: "./", disabled: true, text: this.$t("export").toUpperCase() },
      ],
      headers: [],
      dateTo: null,
      dateFrom: null,
      groupBy: null,
      allItems: [],
      groupByItems: [
        { text: "Tab Name", value: "messageTypeName" },
        { text: "Sensor", value: "sensorName" },
      ],
      formats: [{ text: "csv", value: "csv" }],
      selectedFormat: { text: "csv", value: "csv" },
      commaSeparator: ";",
      fieldSeparator: String.raw`\n`,
      loading: false,
      valid: false,
      selectedPoints: [],
      exportData: null,
      selectedTab: null,
      dataItems: [],
    };
  },
  mounted: function () {
    this.$store.dispatch("_updateBreadcrumbs", this.breadcrumbsItems);
    this.onFilter({
      type: this.$refs.filtersRef.type,
    });
  },
  methods: {
    async onFilter({ area, element, structure, sensor, type }) {
      this.$refs.pointsRef.onFilter({
        area,
        element,
        structure,
        sensor,
        type,
      });
    },
    async managementPreview() {
      if (!this.$refs.form.validate()) return;
      let dateTimeFormat = this.$store.state.user.configParams.dateTimeFormat || 'dd/MM/yyyy hh:mm:ss';
      this.exportData = {result: 1, data:[]};  // clean preview
      this.loading = true;
      
      let result = await this.$store.dispatch("_getManagementExport", {
        data: {
          pointIdList: this.selectedPoints.map((x) => {
            return x.sensorId;
          }),
          dateFrom: new Date(this.dateFrom).toISOString(),
          dateTo: new Date(this.dateTo).toISOString(),
          groupBy: this.groupBy.value,
        },
      });

      // converts date from ISO to user format
      for(let group in result){
        result[group].forEach((record)=>{
          record.measureTime = this.$date.fromISO(record.measureTime).toFormat(dateTimeFormat);
          //record.messageTime = this.$date.fromISO(record.messageTime).toFormat(dateTimeFormat);
        });
      }

      this.exportData = result;

      this.loading = false;
    },

    async changeFilters(event){
        
        // update date values
        if(event.id === "dateFrom" && this.dateFrom !== event.timestamp){
          this.dateFrom = event.timestamp;
        }

        if(event.id === "dateTo" && this.dateTo !== event.timestamp){
          this.dateTo = event.timestamp;
        }
    },    
    managementExport() {
      const fileName = `SensorManagement_${this.$date
        .now()
        .toFormat("yyyy-MM-dd_HH-mm")}.xlsx`;

      // creo un nuovo foglio
      const wb = XLSX.utils.book_new();
      const wsList = [];

      // per ogni gruppo di exportData genero un foglio e lo popolo
      Object.keys(this.exportData).forEach((key) => {
        // costruisco gli headers
        let Heading = [];
        this.exportData[key].forEach((elem) => {
          Object.keys(elem).forEach((singleHeader) => {
            if (!Heading.find((x) => x === singleHeader)) {
              Heading.push(singleHeader);
            }
          });
        });

        // setto il foglio con titolo, headers e dati
        const ws = XLSX.utils.json_to_sheet(this.exportData[key], {
          skipHeader: false,
          header: Heading,
        });
        wsList.push({ ws, key });
      });

      // Aggiungo i fogli di lavoro a wb
      wsList.forEach((wsObj) => {
        XLSX.utils.book_append_sheet(wb, wsObj.ws, wsObj.key);
      });

      // download
      XLSX.writeFileXLSX(wb, fileName);
    },
    updateSelected(items) {
      this.selectedPoints = items;
    },
  },
};
</script>

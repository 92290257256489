<template>
      <v-card flat>
        <v-card-title primary-title>
          <v-icon left color="info">mdi-information</v-icon>
          {{ $t('info') }}
        </v-card-title>
        <v-card-text>
          <v-simple-table class="tableRow">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in infoItems"
                  :key="item.id"
                >
                  <td>{{ item.text }}:</td>
                  <td>
                    <span v-if="item.type == 'battery'">{{ `${item.value}V` }} <v-icon :color="item.batteryColor">{{item.batteryIcon}}</v-icon></span> 
                    <span v-else-if="item.type == 'setup'">
                      {{ item.value }} 
                      &nbsp;
                      <v-btn v-if="item.value.includes('ERROR')" :loading="loading" @click="sendSetup" fab small color="primary">
                        <v-icon>mdi-arrow-u-right-top</v-icon>
                      </v-btn>                      
                    </span>
                    <span v-else>{{ item.value }}</span>            
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>

</template>

<script>

export default {
  props: [
    'infoItems',
    'resendSetup',
  ],
  data () {
      return {
        loading: false,
      }  
  },
  watch: {
    infoItems: function () {
        this.loading = false;
      }
    },
   methods: {
    sendSetup(){
      this.loading = true
      this.resendSetup()
    }
  },
}
</script>

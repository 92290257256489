import api from "@/_helpers/api";

export default {
  _getPoint: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/points/${args.pointId}`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getPointLastMessages: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/points/${args.pointId}/last-messages`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getPointSetup: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/points/${args.pointId}/setup`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _updatePoint: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/points/${args.pointId}`,
      method: "put",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getPoints: async (context) => {
    const response = await api.call({
      url: `/points`,
      method: "get",
      query: { customerId: context.state.user.customerId },
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getPlantPoints: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/points`,
      method: "get",
      query: args,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getSeries: async () => {
    const response = await api.call({
      url: `/sensor-series`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pdd-container"},[_c('v-card',{attrs:{"disabled":_vm.disabledPdd,"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"btn-container my-2"},[_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","light":"","small":"","color":"primary","disabled":_vm.zoom > 3},on:{"click":() => (_vm.zoom < 3 ? (_vm.zoom += 0.2) : '')}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify-plus-outline ")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","light":"","small":"","color":"primary","disabled":_vm.zoom < 0.3},on:{"click":() => (_vm.zoom > 0.3 ? (_vm.zoom -= 0.2) : '')}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify-minus-outline ")])],1)],1),_c('v-btn',{staticClass:"btn-expand mx-1 my-2",attrs:{"fab":"","light":"","small":"","color":"primary"},on:{"click":_vm.expand}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-expand ")])],1),_c('div',{ref:"container",staticClass:"zoomable"},[_c('div',{staticClass:"zoom-content",style:({ transform: `scale(${_vm.zoom})` })},[_c('img',{directives:[{name:"src-auth",rawName:"v-src-auth",value:(`storage/plant/img/${_vm.plantId}`),expression:"`storage/plant/img/${plantId}`"}],ref:"image",staticStyle:{"max-width":"none"},on:{"image-loaded":_vm.imageLoaded}}),_vm._l((_vm.positionedSensors),function(sensor,index){return _c('v-chip',{key:index,staticClass:"ma-2",style:({
            backgroundColor: sensor.connected
              ? 'rgba(50, 250, 50, 0.8)'
              : 'rgba(250, 50, 50,0.8)',
            position: 'absolute',
            left: `${sensor.posX}px`,
            top: `${sensor.posY}px`,
          }),attrs:{"label":""}},[_vm._v(" "+_vm._s(sensor.label)+" ")])})],2)])],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"position":"relative"}},[_c('v-card-text',[_c('div',{staticClass:"btn-container mx-4 my-2",staticStyle:{"position":"fixed"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","light":"","big":"","color":"primary","disabled":_vm.zoom > 3},on:{"click":() => (_vm.zoom < 3 ? (_vm.zoom += 0.2) : '')}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify-plus-outline ")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","light":"","big":"","color":"primary","disabled":_vm.zoom < 0.3},on:{"click":() => (_vm.zoom > 0.3 ? (_vm.zoom -= 0.2) : '')}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify-minus-outline ")])],1)],1),_c('v-btn',{staticClass:"btn-expand mx-5 my-2",staticStyle:{"position":"fixed"},attrs:{"fab":"","light":"","big":"","color":"primary"},on:{"click":_vm.collapse}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-collapse ")])],1),_c('div',{ref:"container-dialog",staticClass:"zoomable dialog"},[_c('div',{staticClass:"zoom-content",style:({ transform: `scale(${_vm.zoom})` })},[_c('img',{directives:[{name:"src-auth",rawName:"v-src-auth",value:(`storage/plant/img/${_vm.plantId}`),expression:"`storage/plant/img/${plantId}`"}],ref:"image-dialog",staticStyle:{"max-width":"none"},on:{"image-loaded":_vm.imageLoadedDialog}}),_vm._l((_vm.positionedSensors),function(sensor,index){return _c('v-chip',{key:index,staticClass:"ma-2",style:({
              backgroundColor: sensor.connected
                ? 'rgba(50, 250, 50, 0.8)'
                : 'rgba(250, 50, 50,0.8)',
              position: 'absolute',
              left: `${sensor.posX}px`,
              top: `${sensor.posY}px`,
            }),attrs:{"label":""}},[_vm._v(" "+_vm._s(sensor.label)+" ")])})],2)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-card flat>
      <v-card-title primary-title>
        <v-icon left color="red darken-2">mdi-alert-outline</v-icon>
        {{ $t('alerts') }}
      </v-card-title>

      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="alertHeaders"
          :items="alertItems" 
          item-key="id"
          :items-per-page="4"
          class="tableRow"
        >
          
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  
  data: function() {    
    return {  
        alertItems:[],      
        alertHeaders: [
          { text: this.$t("Date"),        value: "timestamp",   sortable: true, },
          { text: this.$t("Label"),       value: "label",       sortable: false, },
          { text: this.$t("Source"),      value: "sourceLabel", sortable: false, },     
          { text: this.$t("Type"),        value: "alertType",        sortable: false, }
        ]      
      }
  },
  methods: {       
    
  },
  created: async function() {
    this.loading = true
    this.alertItems = await this.$store.dispatch('_getAlerts')
    this.loading = false
  }
}
</script>

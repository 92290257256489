import api from "@/_helpers/api";

export default {
  _listAlertActive: async (context) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/alerts/actives`,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getAlertActiveDetail: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/alerts/actives/${args.idActiveAlert}`,
      method: "get"
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _listAlertDefinitions: async (context) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/alerts/definitions`,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getAlertDefinitionDetail: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/alerts/definitions/${args.idDefinedAlert}`,
      method: "get"
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _ackAlert: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/alerts/acks/${args.alertId}`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getAlertsOperators: async () => {
    const response = await api.call({
      url: `/alerts/operators`,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getAlertsLevels: async () => {
    const response = await api.call({
      url: `/alerts/levels`,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _saveAlert: async (context, payload) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/alerts`,
      method: "POST",
      data: payload,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _deleteAlert: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/alerts/${args.alertId}`,
      method: "DELETE"
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

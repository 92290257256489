import * as d3 from 'd3'
/**
 * Wrapper for D3.js axis 
 * @class
 * @requires D3.js
 */
export default class SysAxis{

    #axisContainer
    #ref
    #transitionTime
    #axisMode
    #xAxis
    #yAxisLeft
    #yAxisRight
    #leftAxisLabel
    #rightAxisLabel
    #dataset

    /**
     * Builds an axis object
     * @param {*} params - allowed params: 
     * ref {Object} - graph reference
     * transitionTime {Number} - time in milliseconds, used in transitions
     * axisMode {Number} - used to define number of axis. Allowed values: 2, 3
     */
    constructor(params){
      this.#ref = params.ref || null;
      this.#axisContainer = null;
      this.#transitionTime = params.transitionTime || 500;
      this.#axisMode = params.axisMode || this.#ref.getAxisMode();

      this.init();
    }

    /**
     * Adds an axis container to graph
     */
    init(){

      this.#axisContainer = this.#ref.getSVG()
        .append("g")
        .attr("id", "axisContainer");

    }

    /**
     * Gets updated data from reference
     */
    updateReferenceData(){
      this.#dataset = this.#ref.getDataset();  
    }

    /**
     * Draws axis on graph
     * @param {Object} xScale - D3.js scale object
     * @param {Object} yScaleLeft - D3.js scale object
     * @param {Object} yScaleRight - D3.js scale object
     */
    drawAxis(xScale, yScaleLeft, yScaleRight){

      this.updateReferenceData();

      this.drawXAxis(xScale);

      this.drawYAxisLeft(yScaleLeft);

      this.drawYAxisRight(yScaleRight);

      // this.drawXAxisLabel();

      this.drawYAxisLeftLabel();

      this.drawYAxisRightLabel();

      if(this.#axisMode == 2){
        this.#yAxisRight.style("opacity", 0);
        this.#rightAxisLabel.style("opacity", 0);
      }

    }

    /**
     * Draws x Axis
     * @param {Object} xScale - D3.js scale object
     */
    drawXAxis(xScale){
      this.#xAxis = this.#axisContainer.append("g")
      .attr("class", "sys-lc-axis xAxis")
      .attr("transform", `translate(0,${this.#ref.innerHeight})`)
      .call(d3.axisBottom(xScale));

    }

    /**
     * Draws y left Axis
     * @param {Object} yScaleLeft - D3.js scale object 
     */
    drawYAxisLeft(yScaleLeft){
      this.#yAxisLeft = this.#axisContainer.append("g")
      .attr("class", "sys-lc-axis yAxisLeft")
      .call(d3.axisLeft(yScaleLeft));
    }

    /**
     * Draws y right Axis
     * @param {Object} yScaleRight - D3.js scale object 
     */
    drawYAxisRight(yScaleRight){
      this.#yAxisRight = this.#axisContainer.append("g")
      .attr("class", "sys-lc-axis yAxisRight")
      .attr("transform", `translate(${this.#ref.innerWidth},0)`)
      .call(d3.axisRight(yScaleRight));
    }

    /**
     * @todo
     */
    drawXAxisLabel(){
      console.log("TODO");
    }

    /**
     * Draws y left axis label
     */
    drawYAxisLeftLabel(){
      this.#leftAxisLabel = this.#axisContainer
      .append('g')
      .append("text")
        .attr('class', "sys-lc-axisLabel")
        .attr('id', "leftAxisLabel")
        .attr("transform", "rotate(-90)")
        .attr('x', (this.#ref.innerHeight +
                    this.#ref.margin.top +
                    this.#ref.margin.bottom -
                    this.#axisContainer.select("#leftAxisLabel").node().getComputedTextLength()) / 2 * -1)
        .attr('y', -50)
        .text( `${this.#dataset[0].fieldName} ( ${this.#dataset[0].fieldUnit} )` )
        .style("fill", this.#axisMode == 2 ? "rgba(0, 0, 0, 0.54)" : this.#ref.getGroupColor(this.#dataset[0].groupName) );

    }

    /**
     * Draws y right axis label
     */
    drawYAxisRightLabel(){
      this.#rightAxisLabel = this.#axisContainer
      .append('g')
      .append("text")
        .attr('class', "sys-lc-axisLabel")
        .attr('id', "rightAxisLabel")
        .attr("transform", "rotate(-90)")
        .attr('x', (this.#ref.innerHeight +
                    this.#ref.margin.top +
                    this.#ref.margin.bottom -
                    this.#axisContainer.select("#rightAxisLabel").node().getComputedTextLength()) / 2 * -1)
        .attr('y',  this.#ref.innerWidth + 50)
        .text( `${this.#dataset[1] !== undefined ? this.#dataset[1].fieldName : ''} ( ${this.#dataset[1] !== undefined ? this.#dataset[1].fieldUnit : ''} )` )
        .style("fill", this.#ref.getGroupColor(this.#dataset[1] !== undefined ? this.#dataset[1].groupName : '') );
    }

    /**
     * Update reference data
     */ 
    updateAxis(){
      this.updateReferenceData();  
    }

    /**
     * Updates x Axis
     * @param {Object} xScale - D3.js scale object 
     */
    updateXAxis(xScale){
      this.#xAxis
        .transition()
        .duration(this.#transitionTime)
        .call(d3.axisBottom(xScale));

    }

    /**
     * Updates y left Axis
     * @param {Object} yScaleLeft - D3.js scale object  
     */
    updateYAxisLeft(yScaleLeft){
      this.#yAxisLeft
        .transition()
        .duration(this.#transitionTime)
        .call(d3.axisLeft(yScaleLeft));

    }

    /**
     * Updates y right Axis
     * @param {Object} yScaleRight - D3.js scale object  
     */
    updateYAxisRight(yScaleRight){
      this.#yAxisRight
        .transition()
        .duration(this.#transitionTime)
        .call(d3.axisRight(yScaleRight));
    }

    /**
     * @todo
     */
    updateXAxisLabel(){
      console.log("TODO");
    }

    /**
     * Updates y left axis label
     * @param {String} text 
     */
    updateYAxisLeftLabel(text){
      this.#leftAxisLabel.transition().duration(this.#transitionTime)
      .text( text );
     }

    /**
     * Updates y right axis label
     * @param {String} text 
     */
    updateYAxisRightLabel(text){
      this.#rightAxisLabel.transition().duration(this.#transitionTime)
      .text( text );
     }

    /**
     * 
     * @param {*} color 
     */
    updateColorYAxisLeft(color){
      this.#axisContainer.select("#leftAxisLabel")
      .transition().duration(this.#transitionTime)
      .attr("style", () => "fill:" + color )
    }

    /**
     * Updates y right axis color
     * @param {String} color 
     */
    updateColorYAxisRight(color){
      this.#axisContainer.select("#rightAxisLabel")
      .transition().duration(this.#transitionTime)
      .attr("style", () => "fill:" + color )
    }

    /**
     * Sets y left axis visibility
     * @param {Boolean} opacity 
     */
    toggleVisbilityYAxisLeft(opacity){
      this.#yAxisRight.transition().duration(this.#transitionTime).style("opacity", opacity);
      // // .call(d3.axisRight(this.#yAxisScaleRight))
      this.#rightAxisLabel.transition().duration(this.#transitionTime).style("opacity", opacity);
    }

    /**
     * Sets y left axis visibility
     * @param {Boolean} opacity 
     */
    toggleVisbilityYAxisRight(opacity){
      this.#yAxisRight.transition().duration(this.#transitionTime).style("opacity", opacity);
      this.#rightAxisLabel.transition().duration(this.#transitionTime).style("opacity", opacity);
    }
}
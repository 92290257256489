const mocks = {
  //Data di routes non ancora implementate
  utils: {
    getTypeFromTo: (str) => {
      const split = str.split("/");
      return split[split.length - 1].toLowerCase();
    },
  },
};

export default mocks;

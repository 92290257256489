<template>
  <v-container class="px-0">

     <v-card flat>
       <v-card-text>

        <p class="text-h5 text--primary">
          <v-icon left color="red darken-2">mdi-alert</v-icon>
          {{ $t('alerts') }}
        </p>

        <v-row>
          <v-col cols="12">  
            <v-tabs v-model="tab">
                <v-tab key="active-alerts">
                  Active Alerts
                </v-tab>
                <v-tab key="user-defined-alerts">
                  User Defined Alerts
                </v-tab>
              </v-tabs>
      
              <v-tabs-items v-model="tab">
                <v-tab-item key="active-alerts">
                  <active-alerts></active-alerts>
                </v-tab-item>
                <v-tab-item key="user-defined-alerts">
                  <alert-list :entityType="entityType" :entityId="entityId"></alert-list>
                </v-tab-item>
              </v-tabs-items>
          </v-col>  
        </v-row>

       </v-card-text>
     </v-card>

  </v-container>
</template>

<script>
import ActiveAlerts from '@/components/alerts/ActiveAlerts';
import AlertList from '@/components/alerts/AlertList.vue';

export default {
  name: 'Alerts',
  components: {
    ActiveAlerts,
    AlertList
  },
  props: {
    action: {
      type: String,
      default: ''
    },
    entityType: {
      type: String,
      default: ''
    },
    entityId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {    
      breadcrumbsItems: [
        { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home'},
        { href: '/alerts', disabled: true, text: this.$t("alerts").toUpperCase() }
      ],
      tab: null,
    }
  },
  mounted: function() {
    this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);

    if(this.action === 'edit' && ['structure', 'element'].indexOf(this.entityType) > -1){
        this.tab = 1;
    }
  },
  methods: {}
}
</script>

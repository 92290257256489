
import * as d3 from 'd3'
/**
 * Creates a line on graph
 * @class
 */
export default class SysLine{

    #lineContainer
    #ref
    #lines
    #transitionTime
    #dataset
    #brush
    #baseLine
    #axisMode

    /**
     * 
     * @param {*} params - allowed params: 
     *  ref {Object} - graph object
     *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects
     *  axisMode {Number} - numer of axes, x included. Allowed values: 2, 3
     */
    constructor(params){
      this.#lineContainer = null;
      this.#ref = params.ref || null;
      this.#lines = null;
      this.#transitionTime = params.transitionTime || 500;
      this.#brush = this.#ref.getBrush();
      // this.#baseLine = this.#ref.getBaseLine();
      this.#axisMode = params.axisMode || this.#ref.getAxisMode();

      this.init();
    }

    /**
     * Creates a line container
     */
    init(){
      this.#lineContainer = this.#ref.getSVG()
      .append('g')
      .attr("clip-path", `url(#slc_${this.#ref.getExternalId()}_clip)`)
      .attr("id", "linesContainer");
    
      this.initBaseLine();
    
    }

    /**
     * Creates a D3.js line object
     */
    initBaseLine(){
      this.#baseLine = d3.line()
      .x( (d) => this.#ref.getXScale()(d.date) )
      .y( (d) => {
        if(this.#axisMode == 2){
          return isNaN(this.#ref.getYScaleLeft()(+d.value)) ? 0 : this.#ref.getYScaleLeft()(+d.value);
        } else {
          if(d.axis == 'left'){
            return isNaN(this.#ref.getYScaleLeft()(+d.value)) ? 0 : this.#ref.getYScaleLeft()(+d.value);
          } else {
            return isNaN(this.#ref.getYScaleRight()(+d.value)) ? 0 : this.#ref.getYScaleRight()(+d.value);
          }
        }
      });    
    }

    /**
     * Returns lines container
     * @returns 
     */
    getContainer(){
      return this.#lineContainer;
    }

    /**
     * Gets updated dataset from reference
     */
    updateReferenceData(){
      this.#dataset = this.#ref.getDataset();  
    }

    /**
     * Draws lines on graph
     */
    drawLines(){

      this.updateReferenceData();

      // add lines
      this.#lines = this.#lineContainer.selectAll("linesContainer")
        .data(this.#dataset)
        .enter()
        .append("path")
        .attr("class", d => `sys-lc-line ${d.groupName}`)
        .attr("stroke", d => this.#ref.getGroupColor(d.groupName) )
        .attr("d", d => this.#baseLine(d.data, d.groupName) )
        .style("opacity", 0)
        .style("stroke-width", this.#ref.getConfigParam("lineStroke"))
        .transition().duration(this.#transitionTime).style("opacity", 1);

      this.#lineContainer.append("g")
        .attr("class", "brush")
        .call(this.#brush);

    }

    /**
     * Update lines on graph
     */
    updateLines(){
      this.#lineContainer
      .selectAll('.sys-lc-line')
      .transition()
      .duration(this.#transitionTime)
      .attr("d", d => this.#baseLine(d.data, d.groupName) );
    }

    /**
     * Clears all lines from graph
     */
    clearAll(){
      this.#lineContainer.selectAll("*").transition().duration(this.#transitionTime).style("opacity", 0).remove();
    }

    /**
     * Updates group color
     * @param {String} group 
     * @param {String} color 
     */
    updateColor(group, color){
      this.#lineContainer.selectAll(`.${group}`)
      .transition().duration(this.#transitionTime)
      .attr("stroke", color )
      .attr("fill", color )
    }
    
    /**
     * Updates line thickness
     * @param {Number} stroke 
     */
    updateStroke(stroke){
      this.#lineContainer.selectAll('.sys-lc-line').style("stroke-width", stroke);
    }
}
<template>
        
      <v-card flat>
        
        <v-card-title primary-title>
          <v-icon left color="orange lighten-1">mdi-folder-multiple-outline</v-icon>
          {{ $t('ensembles') }}
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="6">
              <v-data-table
                style="margin-top: 48px"
                :headers="headers"
                :items="ensembles"
                :items-per-page="5"
                :loading="loading"
                loading-text="Loading... Please wait"
              >

                <template v-slot:item="{ item }">
                  <tr style="cursor:pointer" :class="item.selected ? 'row-selected' : ''" @click="loadDetail(item)">
                    <td>{{item.label}}</td>
                    <td>{{item.algorithmName}}</td>
                  </tr>
                </template>
              </v-data-table>
              
            </v-col>
            <v-col cols="6">

              <v-tabs v-model="tab">
                <v-tab key="measures">
                  Measures
                </v-tab>
                <v-tab key="properties">
                  Properties
                </v-tab>
              </v-tabs>
      
              <v-tabs-items v-model="tab">
                <v-tab-item key="measures">
                  <v-data-table
                    :headers="headersMeasures"
                    :items="measures"
                    :items-per-page="10"
                    :loading="loadingDetail"
                    loading-text="Loading... Please wait"
                  >
                  <template v-slot:[`item.paramLabel`]="{ item }">
                      <div v-html="item.paramLabel"></div>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item key="properties">
                  <v-data-table
                  :headers="headersProperties"
                  :items="properties"
                  :items-per-page="5"
                  :loading="loadingDetail"
                  loading-text="Loading... Please wait"
                  >
                    
                    </v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </v-col>  
          </v-row>
        </v-card-text>
      </v-card>
    
</template>

<style scoped>
  .row-selected {
    background-color: #e8eaf6;
  }
</style>

<script>
import { DateTime } from "luxon";

export default {
  props:{
    entityId: String,
    entityType: String
  },
  data () {
    return {
      tab: 'measures',
      headers: [
        { text: 'Name', value: 'label' },
        { text: 'Algorithm', value: 'algorithmName' },
      ],
      headersMeasures: [
        { text: 'Param', value: 'paramLabel' },
        { text: 'Value', value: 'paramValue' },
        { text: 'Unit', value: 'paramUnit' },
      ],
      headersProperties: [
        { text: 'Param', value: 'label' },
        { text: 'Value', value: 'fieldValue' },
        // { text: 'Unit', value: 'unit' },
      ],
      ensembles: [],
      properties: [],
      measures: [],
      loading: false,
      loadingDetail: false,
      CONF: this.$store.state.user.configParams,
    }
  },
  watch: {
    entityId: function () {
      this.loadData();
    }
  },
  methods: {
    loadData: async function() {
      this.loading = true;

      const mapAction = {
        element: '_getElementEnsemble',
        structure: '_getStructureEnsemble'
      }

      const action = mapAction[this.entityType]

      if(action && this.$props.entityId && this.$props.entityId !== '0'){
        const data = await this.$store.dispatch(action, {
        id :this.$props.entityId
      })

        this.ensembles = data
      }
      
      this.loading = false;
    },
    loadDetail: async function(item) {

      const dateTimeFormat = this.CONF.dateTimeFormat || 'dd/MM/yyyy';

      this.loadingDetail = true;

      this.ensembles =  this.ensembles.map((e) => ({...e, selected: item.ensembleId === e.ensembleId}))

      const data = await this.$store.dispatch('_getEnsembleMeasure', {
        ensembleId:item.ensembleId,
      });

      // format date if available
      data.outputParams.forEach(element => {

        if(element.paramLabel === 'messageTime'){
          element.paramValue = DateTime.fromISO(element.paramValue).toFormat(
            dateTimeFormat
          );
        }
      });

      this.loadingDetail = false;

      this.properties = data.ensembleParams || []
      this.measures = data.outputParams || []

    },
  },
   mounted: function() {
    this.loadData();
  }
}
</script>

<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="3">
        <Tree></Tree>
      </v-col>
      <v-col cols="9">          
        <v-row>
          <v-col cols="6">
            <Info></Info>
          </v-col>
          <v-col cols="6">
            <Map></Map>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Alerts></Alerts>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SecondaryMenu/>
  </v-container>
</template>

<script>
  import Tree from '@/components/shared/Tree';
  import Info from '@/components/dashboard/area/Info';
  import Map from '@/components/shared/Map';
  import Alerts from '@/components/shared/Alerts';
  import SecondaryMenu from '@/components/shared/SecondaryMenu';

  export default {
    name: 'TreeManagement',
    components: {
      Tree,
      Info,
      Map,
      Alerts,
      SecondaryMenu
    },
    data () {
      return {
        breadcrumbsItems: [ 
          { href: '/dashboard/area', disabled: false, text: "dashboard".toUpperCase(), to: '/dashboard/area'},
        ]  
      }  
    },
    created: function() {
      this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);
    },
    methods: {}
  }
</script>

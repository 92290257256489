var script = {
  structureTypes : [
    {id:1,name: 'Building'},
    {id:2,name: 'Bridge'},
    {id:3,name: 'Wall'},
    {id:4,name: 'Tunnel'},
    {id:5,name: 'Subway'},
    {id:6,name: 'Tank'},
    {id:7,name: 'Other'},

  ],
  filterNumber(evt) {
    evt = (evt) ? evt : window.event;
    let expect = evt.target.value.toString() + evt.key.toString();
    
    if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
      evt.preventDefault();
    } else {
      return true;
    }
  },
  formRules: {
    required: v => !!v || 'Field Required.',
    isNumber: v => !isNaN(v) || 'Insert a valid number',
    maxLength50: v => v !== undefined && v.length <= 50 || 'Field must be less than 50 characters',

    maxValueTrigger: (maxValue) => (v) => {
      return v !== undefined && v <= maxValue || 'Il valore deve essere minore o uguale al numero dei sensori coinvolti'
    }
  },

  validateNumber(value, min, max) {
    const parsedValue = parseFloat(value);
    const isValid = Number.isInteger(parsedValue) && parsedValue >= min && parsedValue <= max;

    return isValid || 'Value not ammitted';
  },
};
export default script;

import * as d3 from 'd3'
/**
 * Creates a custom popup component
 * @class
 */
export default class SysPopup{

  #ref;
  #popup;
  #closeBtn;
  #content;
  #transitionTime;
  #marginWidth;
  #marginHeight;
  #path

  /**
   * 
   * @param {*} params - allowed params: 
   *  ref {Object} - graph object
   *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects 
   *  marginWidth {Number} - margin width in pixel
   *  marginWidth {Number} - margin height in pixel
   */
  constructor(params){
    this.#ref = params.ref;
    this.#transitionTime = params.transitionTime || 500;
    this.#marginWidth = params.marginWidth || 20;
    this.#marginHeight = params.marginHeight || 20;
    this.#path = this.#ref.getPath();

    this.init();
    this.renderContent();

  }

  /**
   * Starts component
   */
  init(){

    var that = this;

    // create popup if don't exists, otherwise get existent
    // if(d3.select(`.sys-popup`).node() === null){
      
      this.#popup = d3.select(`body`)
      // this.#popup = d3.select(`#${this.#ref.getTarget()}`)
        .append("div")
        .attr("class", "sys-popup")
        .style("width", 0)
        .style("height", 0)
        .style("opacity", 0)

      // add close button
      this.#popup.html(`<img src="${this.#path}/images/ic_clear_24px_white.svg" class="sys-lc-btn close" title="Close"></img>`);
      this.#closeBtn = this.#popup.select('img.close')
        .style("right", 0)
        .style("position", "absolute"); 

      this.#content = this.#popup
        .append("div")
        .attr("class", "sys-popup-content")
        .attr("id", `${this.#ref.getTarget()}_sys-cloned-content`);

      // set close menu button
      this.#closeBtn.on("click", ()=> {
        that.close.call(that);
      });

    // } else {
    //  this.#popup = d3.select(`.sys-popup`);
    //}

  }

  /**
   * Renders popup content
   * @param {*} content 
   */
  renderContent(content){
    if(content !== undefined){
      let node = content.cloneNode(true);
      node.id = "clonedNode";
      this.#content.node().appendChild(node);
    }
    
  }

  /**
   * Renders a graph into popup. In this case graph is rendered cloning reference object
   */
  renderGraph(){
    
    // create  a new instance of object instead of cloning nodes
    // In this way I can manage all the graph components
    let graph = new this.#ref.constructor({
      target : this.#content.attr("id"),
      width: document.getElementById(`${this.#ref.getTarget()}_sys-cloned-content`).parentElement.offsetWidth - this.#marginWidth,
      height: document.getElementById(`${this.#ref.getTarget()}_sys-cloned-content`).parentElement.offsetHeight - this.#marginHeight,
      axisMode : this.#ref.getAxisMode(),
      externalId: `slc_${this.#ref.getExternalId()}_clonedGraph`,
      menu: true,
      filter: true,
      filterUrl: this.#ref.getFilterUrl(),
      searchUrl: this.#ref.getSearchUrl(),
      path: this.#ref.getPath()
    });

    // copy configuration params
    graph.updateConfigParams(this.#ref.getConfigParams());
    
    // if url exist, load automatically graph content
    let url = this.#ref.getDataManager().getParam("query.url") !== undefined && this.#ref.getDataManager().getParam("query.url") !== null ?  this.#ref.getDataManager().getParam("query.url") : null;
    if(url !== null && url !== undefined){
      graph.load(url, this.#ref.getDataManager().getParam("query.params"));
    }
    
  }

  /**
   * Opens the popup
   */
  open(){
    // get visible window size
    let w = window.innerWidth - 80;
    let h = window.innerHeight - 40;

    this.#popup.style("top","1%").style("left", "1%").style("width",`${w}px`).style("height", `${h}px`).transition().duration(this.#transitionTime).style("opacity", 1);
  }
  
  /**
   * Closes the popup
   */
  close(){
    var that = this;
    
    this.#popup.transition().duration(this.#transitionTime).style("opacity", 0).on("end", () => {
      that.#popup.style("top","-100px").style("left", "-100px").style("width", 0).style("height", 0);
      that.#content.html("");
    });
    
  }

}
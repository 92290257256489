<template>
  <v-container class="px-0">

  <v-card flat>
      
      <v-card-title primary-title>
        <v-icon left color="grey">mdi-cog</v-icon>
        {{ $t('Configuration') }}
      </v-card-title>

      <v-card-text>
      <v-row>
        <v-col cols="12">  
          
          <v-form ref="configForm">

            <v-checkbox v-model="userInfo.tiltAxisMode" :label="$t('config.tilt_axis_mode')"></v-checkbox>
            <v-checkbox v-model="userInfo.systemMail" :label="$t('config.system_mail')"></v-checkbox>
            <!-- <v-checkbox v-if="['ADMIN', 'SUPERADMIN'].includes(user.role)" v-model="userInfo.emailToUsers" :label="$t('config.mail_to_users')"></v-checkbox> -->
            <v-checkbox v-model="userInfo.emailToUsers" :label="$t('config.mail_to_users')"></v-checkbox>
            <v-switch
              :label="$t('config.dark_theme')"
              :hint="$t('config.dark_theme_hint')"
              v-model="userInfo.darkTheme"
              @change="setDarkTheme"
              inset
              persistent-hint
            ></v-switch>

          </v-form>
        </v-col>  
      </v-row>
      <v-row>
        <v-col cols="12" style="text-align:right">
          <v-btn color="success" @click="save">
            <v-icon left>mdi-floppy</v-icon>
            {{ $t('config.btn.save') }}
          </v-btn>
        </v-col>
      </v-row>  


      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'Config',
    computed: {
      ...mapState( [
        'user',
      ]),
    },
    data () {
      return {
        breadcrumbsItems: [ 
          { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home'},
          { href: '/config', disabled: true, text: this.$t("config").toUpperCase()}
        ] ,
        userInfo: {}
      }  
    },
    created: async function() {
      this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);
      await this.load();
    },
    methods: {
      setDarkTheme: function (val) {
        this.$store.dispatch('_setTheme', val ? 'dark' : 'light');
        this.$vuetify.theme.dark = val;
      },
      async load() {
        this.userInfo = this.user.configParams;
      },
      async save(){
        if(!this.$refs.configForm.validate()){
          this.$store.dispatch('_snackbarMessage', {
            status: 'error',
            message: this.$t('config.form.not_valid')
          });
          return false;
        }

        try{
            await this.$store.dispatch('_updateUser', {
            data: {
            ...this.userInfo,
            configParams : {
              ...this.user.configParams || {},
              tiltAxisMode: this.userInfo.tiltAxisMode,
              systemMail: this.userInfo.systemMail,
              darkTheme: this.userInfo.darkTheme
            }
            }
          })

        }catch (e) {
          this.userInfo = JSON.parse(JSON.stringify(this.userInfoInitial));
          this.$store.dispatch('_snackbarMessage', {
            status: 'error',
            message: this.$t('config.error.sending_data')
          });
        }
      },
    }
  }
</script>

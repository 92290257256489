<template>
  <v-container class="px-0">
  </v-container>
</template>

<script>
  export default {
    name: 'Help',

     data () {
      return {
        breadcrumbsItems: [ 
          { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home'},
          { href: '/help', disabled: true, text: this.$t("help").toUpperCase()}
        ]  
      }  
    },
    created: function() {
      this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);
    },
    methods: {}

  }
</script>

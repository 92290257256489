<template>

 <v-container class="pa-0">

    <v-row v-show="action === 'view'">
      <v-col cols="12">          
        <v-row>
          <v-col cols="6">
            <Info :infoItems="infoItems"></Info>
          </v-col>
          <v-col cols="6">
             <!-- <Pdd /> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <Alerts :entityId="entityId"></Alerts>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <Ensembles entityType="element" :entityId="entityId"></Ensembles>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-row v-show="action !== 'view'">
      <v-col cols="4">
        <InfoEdit ref="infoEdit" :action="action" :element="element" :onSave="save" :onCancel="cancel" :onDelete="()=> showDeleteConfirm = true"></InfoEdit>
      </v-col>
      <v-col cols="8">
      </v-col>
    </v-row>
      <Confirm 
        :show="showDeleteConfirm"
        message="Do you really want to delete this item?"
        title="Delete Element"
        :onConfirm="onConfirmDelete"
        :onCancel="()=> showDeleteConfirm = false"
      />

  </v-container>
</template>

<script>
  import Info from '@/components/dashboard/element/Info';
  // import Pdd from '@/components/shared/Pdd';
  import Alerts from '@/components/shared/Alerts';
  import Ensembles from '@/components/shared/Ensembles';
  import InfoEdit from '@/components/dashboard/element/InfoEdit';
  import Confirm from '@/components/shared/Confirm';
  import { mapState } from 'vuex';

  export default {
    name: 'Element',
    components: {
      Info,
      // Pdd,
      Alerts,
      Ensembles,
      InfoEdit,
      Confirm
    },
    props:{
      entityId: String,
      action: String,
    },
    computed: {
    ...mapState( [
      'dashboardSelection',
      'user'
    ]),
  },
    data () {
      return {
        breadcrumbsItems: [ 
          { href: '/dashboard/area', disabled: false, text: "dashboard".toUpperCase(), to: '/dashboard/area'},
          { href: '/dashboard/area', disabled: false, text: "area".toUpperCase(), to: '/dashboard/area'},
          { href: '/dashboard/structure', disabled: false, text: "structure".toUpperCase(), to: '/dashboard/structure'},
          { href: '/dashboard/element', disabled: false, text: "element".toUpperCase(), to: '/dashboard/element'},
        ],
        infoItems: [] ,
        element: {},
        showDeleteConfirm: false
      }  
    },
    watch: {
      entityId: function () {
        this.loadData();
      }
    },
    mounted: function() {
      this.loadData();
    },    
   
    methods: {

      /**
       * Maps input data into a more friendly format for Info component
       */
      mapData(input){
        return [
          {  id:1,  text: "Name", value: input.label},
          {  id:9,  text: "Structure Alerts", value: input.structureAlerts || 0, type: "alerts", route: '/alerts'}, 
          {  id:10, text: "System Alerts", value: input.systemAlerts || 0, type: "alerts", route: '/alerts'} 
        ];
      },

      /**
       * load entity data async
       */
      async loadData(){
        if(!this.$props.entityId || this.$props.entityId === '0'){
          this.resetData()
        } else {
          const data = await this.$store.dispatch('_getElement', {elementId:this.$props.entityId });
          this.element = {
            ...data,structureId: data.parentId
          }
          this.infoItems = this.mapData(this.element);
        }
      },
      resetData(){
        this.element = {
          structureId: this.dashboardSelection.structure
        }
        this.structureId = this.dashboardSelection.structure || ''
        this.infoItems = this.mapData(this.element);
      },
      
      async save(item){

        const structureId = item.structureId || item.parentId
        const action = item.elementId ? '_updateElement' : '_createElement'

        const data = {
          name: item.name,
          label: item.label,
          structureId,
          type: item.elementType,
          addPoints: item.addPoints || [],
          removePoints: item.removePoints || []
        };

        await this.$store.dispatch(action, {elementId:this.$props.entityId, data });
     
        if(item.elementId){
          this.$router.push(`/dashboard/element/${item.elementId}`);
          this.loadData()
        }else{
          this.$router.push(`/dashboard/structure/${structureId}`);
          this.resetData()
        }
      },
      async onConfirmDelete(){
        this.showDeleteConfirm = false
         this.$refs.infoEdit.loading = true
        await this.$store.dispatch('_deleteElement', {elementId:this.$props.entityId });
        this.$refs.infoEdit.loading = false
        this.$router.push(`/dashboard/structure/${this.element.parent}`);
      },
      async cancel() {
        this.$router.push(`/dashboard/element/${this.$props.entityId}`);
        this.loadData()
      }
    }
  }
</script>

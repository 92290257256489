<template>
      <v-card flat>
        <v-card-title primary-title>
          <v-icon left color="info">mdi-information</v-icon>
          {{ $t('info') }}
        </v-card-title>
        <v-card-text>  
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field label="Name" v-model="pointEdited.label"  :rules="[rules.required]"></v-text-field>
            <v-textarea label="Description" v-model="pointEdited.description"></v-textarea>
            <v-select 
              label="Element"
              v-model="pointEdited.parentId"
              :rules="[rules.required]"
              :items="elements"
              item-text="label"
              item-value="elementId"
              >
            </v-select>
            <v-text-field type="number"   label="Absolute strain" v-model.number="pointEdited.absStrain"  :rules="[ rules.isNumber]"></v-text-field>
            <v-text-field type="number"   label="Absolute tilt" v-model.number="pointEdited.absVerticalTilt"  :rules="[ rules.isNumber]"></v-text-field>
          </v-form>
          
       </v-card-text>
        <v-card-actions>
            <div class="btnContainer">
              <v-btn :loading="loading" :disabled="loading || !valid" color="success" @click="save()"><v-icon>mdi-floppy</v-icon>&nbsp;Save</v-btn>
              <v-btn :loading="loading" :disabled="loading" color="secondary" @click="cancel()" class="ml-5"><v-icon>mdi-arrow-left</v-icon>&nbsp;Cancel</v-btn>
            </div>
          </v-card-actions>
      </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: [
    'point',
    'onSave',
    'action',
    'onCancel'
  ],
  computed: {
    ...mapState( [
      'dashboardSelection',
    ]),
    rules () {
      return this.$script.formRules
    },
  },
  data: () => {
    return {
      valid: false,
      pointEdited : {},
      loading: false,
      elements: []
    }
  },
  watch: {
    point: function ( newVal ) {
      this.pointEdited = newVal;
    },
    action: function ( newVal ) {
      if(newVal !== 'view'){
        this.loadElements()
      }
    },
    label : 'validateField',
    description : 'validateField',
    elementId : 'validateField',
  },
 methods: {
    async save(){
      this.loading = true
      await this.onSave(this.pointEdited)
      this.loading = false
    },
    validateField () {
      this.$refs.form.validate()
    },
    async loadElements(){
      this.elements = await this.$store.dispatch('_getElements');
    },
    cancel() {
      this.loading = true
      this.onCancel()
      this.loading = false
    }
  },
  created: function() {
    if(this.action !== 'view'){
      this.loadElements()
    }
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog
      :value="show"
      overlay-opacity="0.4"
      overlay-color="#ffffff"
      persistent
    >
      <v-card flat>
        <v-overlay :absolute="true" :value="loading">
          <v-row align="center" justify="center">
            <v-col align="center">
              <v-progress-circular indeterminate color="info" :size="350">
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-overlay>
        <v-card-title class="text-h5 blue darken-4 white--text">
          <v-toolbar-title>{{ "View Alert" }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              <p class="text-h5 text--primary">
                <v-icon left color="grey">mdi-cog</v-icon>
                {{ $t("Alert info") }}
              </p>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      readonly
                      label="Object Type"
                      v-model="currentItem.objectType"
                    ></v-text-field>
                    <v-text-field
                      readonly
                      label="Object Name"
                      v-model="currentItem.objectName"
                    ></v-text-field>
                    <!-- defined alert -->
                    <div
                      v-if="Object.keys(currentItem.definedAlert).length > 0"
                    >
                      <v-text-field
                        readonly
                        label="Name"
                        v-model="currentItem.definedAlert.name"
                      ></v-text-field>
                      <v-text-field
                        readonly
                        label="Algorithm"
                        v-model="currentItem.definedAlert.algorithmName"
                      ></v-text-field>
                      <v-text-field
                        readonly
                        label="Algorithm Family"
                        v-model="currentItem.definedAlert.algorithmFamilyName"
                      ></v-text-field>
                      <v-text-field
                        readonly
                        label="Algorithm Param Name"
                        v-model="
                          currentItem.definedAlert.algorithmOutputParamName
                        "
                      ></v-text-field>
                      <v-text-field
                        readonly
                        label="Algorithm Param Type"
                        v-model="
                          currentItem.definedAlert.algorithmOutputParamType
                        "
                      ></v-text-field>
                      <v-text-field
                        readonly
                        label="Operator"
                        v-model="currentItem.definedAlert.alertOperatorName"
                      ></v-text-field>
                      <v-text-field
                        readonly
                        label="Value"
                        v-model="currentItem.definedAlert.value1"
                      ></v-text-field>
                      <v-text-field
                        v-if="currentItem.definedAlert.value2"
                        readonly
                        label="Value2"
                        v-model="currentItem.definedAlert.value2"
                      ></v-text-field>
                      <v-textarea
                        readonly
                        label="Description"
                        v-model="currentItem.definedAlert.description"
                      ></v-textarea>
                    </div>
                    <!-- system alert -->
                    <div v-else>
                      <v-text-field
                        readonly
                        label="Name"
                        v-model="currentItem.systemAlert.name"
                      ></v-text-field>
                      <!-- <v-text-field
                        readonly
                        label="Raise message"
                        v-model="currentItem.systemAlert.raiseMessage"
                      ></v-text-field>
                      <v-text-field
                        readonly
                        label="Close message"
                        v-model="currentItem.systemAlert.closeMessage"
                      ></v-text-field> -->
                      <v-label>JSON Value:</v-label>
                      <pre>{{currentItem.jsonValue}}</pre>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      readonly
                      label="Value"
                      v-model="currentItem.value"
                    ></v-text-field>
                    <v-text-field
                      readonly
                      label="Viewed"
                      v-model="currentItem.viewed"
                    ></v-text-field>
                    <v-text-field
                      readonly
                      label="Alert Status"
                      v-model="currentItem.alertStatusName"
                    ></v-text-field>
                    <v-text-field
                      readonly
                      label="Creation Date"
                      v-model="currentItem.creationDate"
                    ></v-text-field>
                    <v-text-field
                      readonly
                      label="Update Date"
                      v-model="currentItem.updateDate"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ActiveAlertView",
  props: ["show", "onCancel", "selectedItem", "preconfiguredObjType", "isView"],
  components: {},
  watch: {
    selectedItem: function (newVal) {
      this.object = null;

      if(newVal && newVal.alertId){
        this.loadData(newVal);
      }
    },
  },
  computed: {
    rules() {
      return this.$script.formRules;
    },
  },
  data() {
    return {
      loading: false,
      currentItem: {
        definedAlert: {},
        systemAlert: {},
      },
      object: null,
      limitInclusive: false,
      valid: false,
      selected: [],
    };
  },
  methods: {
    async loadData(alertFromList) {
      this.loading = true;
      const alert = await this.$store.dispatch("_getAlertActiveDetail", {
        idActiveAlert: alertFromList.alertId,
      });
      this.currentItem = alert;

      this.loading = false;
    },
  },
};
</script>

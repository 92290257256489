import * as d3 from 'd3'
import SysUtility from './SysUtility.js'

/**
 * Creates a custom event component
 * @class
 */
export default class SysEvent{

    #eventContainer
    #ref
    #dataset
    #mergedDataset
    #transitionTime
    #tooltip
  
    /**
     * Creates the object
     * @param {Object} params - allowed params: 
     *  ref {Object} - graph object
     *  transitionTime {Number}
     */
    constructor(params){
  
      this.#eventContainer = null;
      this.#ref = params.ref;
      this.#transitionTime = params.transitionTime || 500; 
      this.#dataset = null;  
      this.#mergedDataset = null;  
      this.#tooltip = this.#ref.getTooltip();
  
      this.init();
  
    }
  
    /**
     * Creates an event container
     */
    init(){
      this.#eventContainer = this.#ref.getSVG()
        .append("g").attr("id", "eventContainer")
        .attr("clip-path", `url(#slc_${this.#ref.getExternalId()}_clip)`);
    }

    /**
     * Toggle event visibility
     * @param {Boolean} opacity 
     */
    toggleVisibility(opacity){
      this.#eventContainer.transition().duration(this.#transitionTime).style("opacity", opacity);
    }

    /**
     * Deletes all events from graph
     */
    clearAll(){
      this.#eventContainer.selectAll("*").transition().duration(this.#transitionTime).style("opacity", 0).remove();
    }

    /**
     * Gets updated data from reference
     */
    updateReferenceData(){
      this.#dataset = this.#ref.getDataset();  
      this.#mergedDataset = this.#ref.getMergedDataset();  
    }    

    /**
     * Draws events
     */
    drawEvents(){

      // get updated dataset from reference
      this.updateReferenceData();

      this.drawLines();

      this.drawMarkers();

      this.#eventContainer
      .style("opacity", 0)
      .transition().duration(this.#transitionTime).style("opacity",  this.#ref.getConfigParam("opacity.event"));
    }

    /**
     * Draws event lines
     */
    drawLines(){

      // let dataset = this.#ref.getAxisMode() === 3 ? this.#dataset[0].data : this.#mergedDataset;

      this.#eventContainer.selectAll("eventContainer")
        .data(this.#dataset)
        .enter()
        .append("g")
        .selectAll("eventLineContainer")
        .data(d => d.events)
        .enter()
        .append("line")// attach a line
        .attr("class", "sys-lc-eventLine")  // colour the line
        .attr("x1", d => this.#ref.getXScale()( SysUtility.parseTime(this.#ref.timeInFormat, d.date) ))     // x position of the first end of the line
        //.attr("y1", this.#ref.getYScaleLeft()( d3.min(dataset, (d) => +d.value ) ))      // y position of the first end of the line
        .attr("y1", this.#ref.getYScaleLeft()( this.#ref.getConfigParam("rescale.left.min") ))
        .attr("x2", d => this.#ref.getXScale()( SysUtility.parseTime(this.#ref.timeInFormat, d.date) ))     // x position of the second end of the line
        //.attr("y2", this.#ref.getYScaleLeft()( d3.max(dataset, (d) => +d.value ) ))
        .attr("y2", this.#ref.getYScaleLeft()( this.#ref.getConfigParam("rescale.left.max") ))
    }

    /**
     * Draws event markers
     */
    drawMarkers(){

      // let dataset = this.#ref.getAxisMode() === 3 ? this.#dataset[0].data : this.#mergedDataset;

      this.#eventContainer .selectAll("eventContainer")
        .data(this.#dataset)
        .enter()
        .append("g")
        .selectAll("eventLineContainer")
        .data(d => d.events)
        .enter()
        .append('path')
        .attr("class", "sys-lc-eventPoint")
        // -6px used to translate the base of the trinagle on the x line
        .attr("transform", d => {
          // if data is empty, rescale is empty too
          if(this.#ref.getConfigParam("rescale.left.min") !== null){
            return `translate(${this.#ref.getXScale()( SysUtility.parseTime(this.#ref.timeInFormat, d.date) )},
                                            ${(this.#ref.getYScaleLeft()( this.#ref.getConfigParam("rescale.left.min"))) - 6})`;
          } else {
            return `translate(-100,-100)`;
          }
        })  
        .attr("d", d3.symbol(d3.symbols[5], 100))
        .attr("data-group", d => d.group )
        .attr("data-date", (d) => SysUtility.formatTime(this.#ref.timeOutFormat, d.date) )
        .attr("data-type", (d) => d.type )
        .on("mouseover", this.#tooltip.pointMouseover)
        .on("mousemove", this.#tooltip.eventPointMousemove)
        .on("mouseleave", this.#tooltip.pointMouseleave)
        .style("stroke", this.#ref.getAxisMode() === 3 ? "yellow" : d => this.#ref.getGroupColor(d.group))
    }

    /**
     * Updates events
     */
    updateEvents(){

      // get updated dataset from reference
      this.updateReferenceData();

      this.updateLines();

      this.updateMarkers();
      
    }

    /**
     * Update events line
     */
    updateLines(){
      this.#eventContainer.selectAll(".sys-lc-eventLine")
        .transition()
        .duration(this.#transitionTime)
        .attr("x1", d => this.#ref.getXScale()( SysUtility.parseTime(this.#ref.timeInFormat, d.date) ))     // x position of the first end of the line
        .attr("x2", d => this.#ref.getXScale()( SysUtility.parseTime(this.#ref.timeInFormat, d.date) ))     // x position of the second end of the line
    }

    /**
     * Update events marker
     */
    updateMarkers(){

      this.#eventContainer.selectAll(".sys-lc-eventPoint")
        .transition()
        .duration(this.#transitionTime)
        //.attr("transform", d => `translate(${this.#ref.getXScale()( SysUtility.parseTime(this.timeInFormat, d.date) )},
        //                                   ${this.#ref.getYScaleLeft()( d3.min(dataset, (d) => +d.value ) )})` )
        .attr("transform", d => `translate(${this.#ref.getXScale()( SysUtility.parseTime(this.#ref.timeInFormat, d.date) )},
                                          ${this.#ref.getYScaleLeft()( this.#ref.getConfigParam("rescale.left.min") )})` )
    }

    /**
     * Update event border color on marker
     * @param {String} group 
     * @param {String} color 
     */
    updateColor(group, color){
      this.#eventContainer.selectAll(`path[data-group=${group}]`)
        .transition().duration(this.#transitionTime)
        .attr("style", () => "stroke:" + color )
    }

  }
  
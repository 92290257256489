import * as d3 from 'd3'
import SysUtility from './SysUtility.js'

/**
 * Creates a custom alert component
 * @class
 */
export default class SysAlert{

    // private params
    #alertContainer
    #ref
    #dataset
    #mergedDataset
    #transitionTime
    #tooltip

    /**
     * 
     * @param {Object} params - allowed params: 
     *  ref {Object} - graph object
     *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects
     */
    constructor(params){
  
      this.#alertContainer = null;
      this.#ref = params.ref;
      this.#transitionTime = params.transitionTime || 500; 
      this.#dataset = null;  
      this.#mergedDataset = null;  
      this.#tooltip = this.#ref.getTooltip();

      this.init();
  
    }
  
    /**
     * Starts component
     */
    init(){
      this.#alertContainer = this.#ref.getSVG()
        .append("g").attr("id", "alertContainer")
        .attr("clip-path", `url(#slc_${this.#ref.getExternalId()}_clip)`);
    }
  
    /**
     * Sets component visibility
     * @param {Boolean} opacity 
     */
    toggleVisibility(opacity){
      this.#alertContainer.transition().duration(this.#transitionTime).style("opacity", opacity);
    }
    
    /**
     * Deletes all alerts from graph
     */
    clearAll(){
      this.#alertContainer.selectAll("*").transition().duration(this.#transitionTime).style("opacity", 0).remove();
    }

    /**
     * Gets updated data from reference dataset
     */
    updateReferenceData(){
      this.#dataset = this.#ref.getDataset();  
      this.#mergedDataset = this.#ref.getMergedDataset();  
    }

    /**
     * Draws alerts (lines and markers)
     */
    drawAlerts(){

      // get updated dataset from reference
      this.updateReferenceData();

      this.drawLines();

      this.drawMarkers();

      this.#alertContainer
        .style("opacity", 0)
        .transition().duration(this.#transitionTime).style("opacity",  this.#ref.getConfigParam("opacity.alert"));
    }

    getY(d){
      const alertReference = SysUtility.getAlertReference(d)
      const y =  this.#ref.getYScaleLeft()(alertReference);
      return y;
    }

    /**
     * Draws alert lines
     */
    drawLines(){

      // alert line
      this.#alertContainer.selectAll("alertContainer")
        .data(this.#dataset)
        .enter()
        .append("g")
        .selectAll("alertLineContainer")
        .data(d => d.alerts)
        .enter()
        .append("line")// attach a line
        .attr("class", "sys-lc-alertLine")  // colour the line
        .attr("x1", this.#ref.getXScale()( d3.min(this.#mergedDataset, (d) => +d.date ) ))     // x position of the first end of the line
        .attr("y1", d=> this.getY(d))           // y position of the first end of the line
        .attr("x2", this.#ref.getXScale()( d3.max(this.#mergedDataset, (d) => +d.date) ))     // x position of the second end of the line
        .attr("y2", d=> this.getY(d))     
      ;
    }

    /**
     * Draws alert markers
     */
    drawMarkers(){
      
      this.#alertContainer.selectAll("alertContainer")
        .data(this.#dataset)
        .enter()
        .append("g")
        .selectAll("alertLineContainer")
        .data(d => d.alerts)
        .enter()
        .append('path')
        .attr("data-group", d => d.group )
        .attr("data-name", d => d.name)
        .attr("data-property", d => d.property )
        .attr("data-operator", d => d.operator)
        .attr("data-min", d => d.min)
        .attr("data-max", d => d.max)
        .attr("data-limit", d => d.limit)
        .attr("data-value", d => d.value)
        .attr("class", "sys-lc-alertPoint")
        .attr("d", d3.symbol(d3.symbols[5], 100))
        // +6px used to translate the base of the trinagle on the y line
        .attr("transform", d => `translate( ${ (this.#ref.getXScale()( d3.min(this.#mergedDataset, (d) => +d.date ) )) + 6 }, 
                                            ${this.getY(d)}) rotate(-30)` )
        .on("mouseover", this.#tooltip.pointMouseover)
        .on("mousemove", this.#tooltip.alertPointMousemove)
        .on("mouseleave", this.#tooltip.pointMouseleave)
        .style("stroke", this.#ref.axisMode === 3 ? "red" : d => this.#ref.getGroupColor(d.group));

    }

    /**
     *  Updates alerts
     */
    updateAlerts(){

      // get updated dataset from reference
      this.updateReferenceData();

      this.updateLines();

      this.updateMarkers();
      
    }

    /**
     * Updates alert lines
     */
    updateLines(){
      this.#alertContainer.selectAll(".sys-lc-alertLine")
        .transition()
        .duration(this.#transitionTime)
        .attr("y1", d => this.#ref.getYScaleLeft()(SysUtility.getAlertReference(d)))      // y position of the first end of the line
        .attr("y2", d => this.#ref.getYScaleLeft()(SysUtility.getAlertReference(d)))
        ;
    }

    /**
     * Updates alert markers
     */
    updateMarkers(){
      this.#alertContainer.selectAll(".sys-lc-alertPoint")
        .transition()
        .duration(this.#transitionTime)
        .attr("transform", d => `translate(${this.#ref.getXScale()( d3.min(this.#mergedDataset, (d) => +d.date ) )},${this.#ref.getYScaleLeft()(SysUtility.getAlertReference(d))}) rotate(-30)` )
    }

    /**
     * Updates alert border color
     * @param {String} group 
     * @param {String} color 
     */
    updateColor(group, color){
      this.#alertContainer.selectAll(`path[data-group=${group}]`)
      .transition().duration(this.#transitionTime)
      .attr("style", () => "stroke:" + color )
    }

  }
  
import SysUtility from './SysUtility.js'
import * as d3 from 'd3'

/**
 * Creates a custom tooltip component
 * @class
 */
export default class SysTooltip {

  #ref;
  #tooltip;
  #transitionTime;
  #xMargin;
  #yMargin;
  #width;
  #height;  

  /**
   * 
   * @param {*} params - allowed params: 
   *  ref {Object} - graph object
   *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects 
   *  width {Number} - width in px
   *  height {Number} - height in px
   */
  constructor(params){

    this.#tooltip = null;
    this.#ref = params.ref;
    this.#transitionTime = params.transitionTime || 500;
    this.#xMargin = 10;
    this.#yMargin = 0;
    this.#width = params.width || 210;
    this.#height = params.height || 140;

    this.init();

  }

  /**
   * Creates the tooltip container
   */
  init(){

    this.#tooltip = d3.select(`#${this.#ref.getTarget()}`)
      .append("div")
      .attr("class", "sys-lc-tooltip")
      .style("width", "0px")
      .style("height", "0px")
      .style("left", "-500px")
      .style("top", "-500px");
  }

  /**
   * Manages mouse over event, showing it
   * @param {*} d 
   */
  pointMouseover = (d) => {

      this.#tooltip
      .style("width", `${this.#width}px`)
      .style("height", `${this.#height}px`)
      .transition().duration(this.#transitionTime).style("opacity", 0.8);

      // keep in this position: needs to render html before
      let coords = SysUtility.getTooltipPosition(d, this.#tooltip, this.#ref, this.#xMargin, this.#yMargin, this.#width, this.#height);

      this.#tooltip
        .style("left", `${coords.x}px`)
        .style("top", `${coords.y}px`);
  };

  /**
   * Shows tooltip content, getting data from data markers
   * @param {Event} d 
   */
  dataPointMousemove = (d) => {
    let group = d.target.attributes["data-group"].value;
    let color = this.#ref.getGroupColor(group);

    if(group[0] === "_"){
      group = group.slice(-5);
    }

    let htmlContent = `
    <table>
      <tr>
        <td class="sys-lc-table-label">Group:</td>
        <td class="sys-lc-table-data"><span id="ens-color">${group}</span></td>
      </tr>
      <tr>
        <td class="sys-lc-table-label">Date:</td>
        <td class="sys-lc-table-data">${d.target.attributes["data-date"].value}</td>
      </tr>
      <tr>
        <td class="sys-lc-table-label">Value:</td>
        <td class="sys-lc-table-data">${d.target.attributes["data-value"].value}</td>
      </tr>
      <tr>
        <td class="sys-lc-table-label">Type:</td>
        <td class="sys-lc-table-data">${d.target.attributes["data-type"].value}</td>
      </tr>
    </table>`;

    this.#tooltip.html(htmlContent);

    // keep in this position: needs to render html before
    let coords = SysUtility.getTooltipPosition(d, this.#tooltip, this.#ref, this.#xMargin, this.#yMargin, this.#width, this.#height);

    this.#tooltip
      .style("left", `${coords.x}px`)
      .style("top", `${coords.y}px`)
      .style("border-bottom", `4px solid ${color}`);

    this.#tooltip.select("#ens-color").style("color", color);

  };

  /**
   * Shows tooltip content, getting data from alert markers
   * @param {Event} d 
   */
  alertPointMousemove = (d) => {

    let axisMode = this.#ref.getAxisMode();
    let group = axisMode === 3 ? "" : d.target.attributes["data-group"].value;
    let color = axisMode === 3 ? "silver" : this.#ref.getGroupColor(group);

    let htmlContent = `<table>`;
    
    htmlContent += `
      <tr>
        <td class="sys-lc-table-label">Group:</td>
        <td class="sys-lc-table-data"><span id="ens-color">${group}</span></td>
      </tr>
      <tr>
        <td class="sys-lc-table-label">Name:</td>
        <td class="sys-lc-table-data">${d.target.attributes["data-name"].value}</td>
      </tr>
      <tr>
        <td class="sys-lc-table-label">Property:</td>
        <td class="sys-lc-table-data">${d.target.attributes["data-property"].value}</td>
      </tr>
      <tr>
        <td class="sys-lc-table-label">Operator:</td>
        <td class="sys-lc-table-data">${d.target.attributes["data-operator"].value}</td>
      </tr>
    `;
      
    if(d.target.attributes["data-min"] !== undefined && !isNaN(d.target.attributes["data-min"].value)){
      htmlContent += `
        <tr>
          <td class="sys-lc-table-label">Min:</td>
          <td class="sys-lc-table-data">${d.target.attributes["data-min"].value}</td>
        </tr>        
      `; 
    }

    if(d.target.attributes["data-max"] !== undefined && !isNaN(d.target.attributes["data-max"].value)){
      htmlContent += `
        <tr>
          <td class="sys-lc-table-label">Max:</td>
          <td class="sys-lc-table-data">${d.target.attributes["data-max"].value}</td>
        </tr>        
      `; 
    }

    if(d.target.attributes["data-limit"] !== undefined && !isNaN(d.target.attributes["data-limit"].value)){
      htmlContent += `
        <tr>
          <td class="sys-lc-table-label">Limit:</td>
          <td class="sys-lc-table-data">${d.target.attributes["data-limit"].value}</td>
        </tr>        
      `; 
    }

    if(d.target.attributes["data-value"] !== undefined && !isNaN(d.target.attributes["data-value"].value)){
      htmlContent += `
        <tr>
          <td class="sys-lc-table-label">Value:</td>
          <td class="sys-lc-table-data">${d.target.attributes["data-value"].value}</td>
        </tr>        
      `; 
    }

    htmlContent += `</table>`;

    this.#tooltip.html(htmlContent);

    // keep in this position: needs to render html before
    let coords = SysUtility.getTooltipPosition(d, this.#tooltip, this.#ref, this.#xMargin, this.#yMargin, this.#width, this.#height);

    this.#tooltip
      .style("left", (`${coords.x}px`))
      .style("top", (`${coords.y}px`))
      .style("border-bottom", `4px solid ${color}`);

    this.#tooltip.select("#ens-color").style("color", color);
  };

  /**
   * Shows tooltip content, getting data from event markers
   * @param {Event} d 
   */
  eventPointMousemove = (d) => {

    let axisMode = this.#ref.getAxisMode();
    let group = axisMode === 3 ? "" : d.target.attributes["data-group"].value;
    let color = this.#ref.getAxisMode() === 3 ? "silver" : this.#ref.getGroupColor(group);

    let htmlContent = `
    <table>
      <tr>
        <td class="sys-lc-table-label">Group:</td>
        <td class="sys-lc-table-data"><span id="ens-color">${group}</span></td>
      </tr>
      <tr>
        <td class="sys-lc-table-label">Date:</td>
        <td class="sys-lc-table-data">${d.target.attributes["data-date"].value}</td>
      </tr>
      <tr>
        <td class="sys-lc-table-label">Type:</td>
        <td class="sys-lc-table-data">${d.target.attributes["data-type"].value}</td>
      </tr>
    </table>`;

    this.#tooltip.html(htmlContent);

    // keep in this position: needs to render html before
    let coords =  SysUtility.getTooltipPosition(d, this.#tooltip, this.#ref, this.#xMargin, this.#yMargin, this.#width, this.#height);

    this.#tooltip
      .style("left", (`${coords.x}px`))
      .style("top", (`${coords.y}px`))
      .style("border-bottom", `4px solid ${color}`);

    this.#tooltip.select("#ens-color").style("color", color);
  };

  /**
   * Closes tooltip
   * @param {Event} d 
   */
  pointMouseleave = () => {

    this.locked = true;

    this.#tooltip.transition().duration(this.#transitionTime).style("opacity", 0).on("end", ()=>{
      this.#tooltip
        .style("width", "0px")
        .style("height", "0px")
        .style("left", "-500px")
        .style("top", "-500px")
        ;
        this.locked = false;
    });
  };

}
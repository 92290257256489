import * as d3 from 'd3'
/**
 * Static class cointaining some utility functions
 * @class
 */
 export default class SysUtility {

  /**
   * Parses datetime to a defined format
   * @param {String} outputFormat - chosen date output format
   * @param {Date} date 
   * @returns 
   */
  static formatTime(outputFormat, date){
    return d3.timeFormat(outputFormat)(date);
  }

  /**
   * Parses a string and returns the corresponding date
   * @param {String} inputFormat 
   * @param {Date} date 
   * @returns 
   */
  static parseTime(inputFormat, date){
      return d3.timeParse(inputFormat)(date);
  }

  /**
   * Parses data to a defined format
   * @param {*} value 
   * @returns {*}
   */
  static parseValue(value){
    return value;
  }

  /**
   * Returns tooltip position on graph. If tooltip goes over graph area, it changes direction 
   * @param {Event} e 
   * @param {String} target - id of the graph area used as ground for the tooltip
   * @param {Obect} ref - graph object 
   * @param {Number} xMargin - graph x limit in pixels
   * @param {Number} yMargin - graph y limit in pixels
   * @param {Number} boxWidth - tooltip width in pixels
   * @param {Number} boxHeight - tooltip height in pixels
   * @returns 
   */
   static getTooltipPosition(e, target, ref, xMargin, yMargin, boxWidth, boxHeight){

    // const event = d3.pointer(e, ref.getSVG().node());

    // let x = event[0]*1 + xMargin;
    // let y = event[1]*1;

    // related to container
    let x = e.offsetX + xMargin; 
    let y = e.offsetY;           

    // related to viewport
    // const tooltipBox = target.node().getBoundingClientRect();
    const targetBox = ref.getSVG().select("#gridContainer").node().getBoundingClientRect();

    // related to viewport
    // I need to consider mouse, not tooltip position, to avoid flipping issue
    const tooltipBottomLimit = e.clientY + boxHeight;
    const tooltipRightLimit = e.clientX + boxWidth;
    const targetBottomLimit = targetBox.y + targetBox.height;
    const targetRightLimit = targetBox.x + targetBox.width;
    
    // get x position
    if(tooltipRightLimit > targetRightLimit){
      x = x - boxWidth - xMargin*2;
    } else {
      x = x + xMargin;
    }
    // get y position
    if(tooltipBottomLimit > targetBottomLimit){
      y = y - boxHeight - yMargin;
    } else {
      y = y + yMargin;
    }

    return {x,y}
  }

  /**
   * Define which value has to be returned for a specific alert
   * @param {*} d 
   * @returns {number} 
   */
  static getAlertReference = (d) => {

    let reference = 0;

    switch (d.operatorKey) {
      case 'LT':
      case 'GT':
      case 'LTE':
      case 'GTE':
        reference = d.limit
        break;
      case 'EQ':
      case 'NOTEQ':
        reference = d.value
        break;
      case 'BTW':
      case 'NOTBTW':
        reference = d.min !== undefined ? d.min : d.max;
        break;
      default:
        reference = 0;
        break;
    }

    return reference;
  }

  /**
   * Math.random should be unique because of its seeding algorithm.
   * Convert it to base 36 (numbers + letters), and grab the first 9 characters
   * after the decimal.
   * @returns {String} alphanumeric string made by 10 chars
   */
  static randomId(){
    return '_' + Math.random().toString(36).substr(2, 9);
  }


}
// import Class instead of instance, to permit extends
import { Axios } from "axios";
import store from "@/stores";

/**
 * Extends Axios object to get a wrapper upon Middleware results
 *
 */
class ApiManager extends Axios {
  constructor(params) {
    super(params);
  }

  async call(params) {
    try {
      let url = params.url;
      let method = params.method || "get";
      let headers = params.headers || {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      let requestAuth =
        params.requestAuth === undefined ? true : params.requestAuth;
      if (requestAuth) {
        headers["Authorization"] = `Bearer ${store.state.user.token}`;
      }
      let body = params.data || {};
      let success = params.success;
      let error = params.error;
      let debug = params.debug || false;

      const hideMsg = params.hideMsg || false;

      if (debug) {
        console.log("apiParams", params);
      }

      if(params.responseType === "blob"){
        const response = await this.request({
          method,
          url,
          data: JSON.stringify(body),
          params: params.query,
          headers,
          withCredentials: true,
          responseType: params.responseType,
        });

        if(response.status !== 200){
          return {error: response};
        }
  
        return response.data;
      }

      const response = await this.request({
        method,
        url,
        data: JSON.stringify(body),
        params: params.query,
        headers,
        withCredentials: true,
        responseType: params.responseType,
        transformResponse: [
          function transformResponse(data) {
            return JSON.parse(data);
          },
        ],
      });
      

      const { msg, result } = response.data;

      if (msg && !hideMsg) {
        if(!result){
          console.error(msg);
        }
        // show snackbar message ,
        // se in errore messaggio generico altrimenti quello ricevuto
        store.dispatch("_snackbarMessage", {
          message: result? msg : "error on get data",
          status: result ? "success" : "error",
        });
      }

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          store.dispatch("_logout");
        }

        if (typeof error === "function") {
          error(response.data);
        }

        if (debug) {
          console.error(response.data);
        }
      }

      if (debug) {
        console.log("api response data", response.data);
      }

      if (typeof success === "function") {
        success(response.data);
      }

      return response.data;
    } catch (ex) {
      console.error("errore api", ex);
      store.dispatch("_snackbarMessage", {
        message: ex.message,
        status: "error",
      });
    }
  }
}

const baseURL = window.shbox2?.baseUrl ?? process.env.VUE_APP_API_URL;

// create and export new instance
export default new ApiManager({
  baseURL,
  responseType: "json",
});

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.plantsDialog),callback:function ($$v) {_vm.plantsDialog=$$v},expression:"plantsDialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"indigo","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("plants")))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":[
            {
                value: 'customers.name',
            }, 
            {
                value: 'label',
            },
            ],"items":_vm.$store.state.plants,"items-per-page":10,"hide-default-header":"","hide-default-footer":_vm.$store.state.plants && _vm.$store.state.plants.length <= 5,"single-select":""},on:{"click:row":_vm.selectPlant},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<style>
.user-container .form-description{
  margin-top: 2em;
}

.user-container .form-required-sign{
  color: red;
}
.btn-container-inline-right{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: initial;
}

.tab-item{
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  display:none;
}

.tab-item.active{
  opacity: 1;
  height: auto;
  display:block;
}
</style>
<template>
  <v-container class="px-0 user-container">
  <v-card flat>
      <v-card-title primary-title>
        <v-icon left color="info">mdi-account</v-icon>
        {{ $t('User') }}
      </v-card-title>

      <v-card-text>
          <v-tabs v-model="tab">
              <v-tab key="anagraphic">
                {{ $t('anagraphic') }}
              </v-tab>
              <v-tab key="regional-settings">
                {{ $t('regional_settings') }}
              </v-tab>
            </v-tabs>

            <v-form ref="userForm">
              <v-row>
                <v-col>
                    <section :class="`tab-item ${tab===0? 'active': ''}`" key="anagraphic">
                      <v-row>
                        <v-col class="form-description">
                          <span class="form-required-sign">{{$t('form.required_sign')}}</span>  {{$t('form.required_description')}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field :label="`${$t('user.first_name')} ${$t('form.required_sign')}`" v-model="userInfo.firstName" :rules="formRules.required" validate-on-blur></v-text-field>
                          <v-text-field :label="`${$t('user.last_name')} ${$t('form.required_sign')}`" v-model="userInfo.lastName" :rules="formRules.required" validate-on-blur></v-text-field>
                          <v-text-field :label="`${$t('user.email')} ${$t('form.required_sign')}`" type="email" v-model="userInfo.email1" :rules="formRules.email" validate-on-blur></v-text-field>
                          <v-text-field :label="$t('user.alt_email')" type="email" v-model="userInfo.email2" :rules="formRules.altEmail" validate-on-blur></v-text-field>

                          <!-- <v-btn color="secondary"  @click="showChangePassword()">
                            <v-icon left>mdi-lock-outline</v-icon>
                            {{ $t('user.bnts.change_password') }}
                          </v-btn> -->
                        </v-col>
                        <v-col cols="6">
                          <v-text-field :label="$t('user.phone')" type="tel" v-model="userInfo.phone1" :rules="formRules.phone" validate-on-blur></v-text-field>
                          <v-text-field :label="$t('user.alt_phone')" type="tel" v-model="userInfo.phone2" :rules="formRules.phone" validate-on-blur></v-text-field>
                          <!-- <v-text-field :label="$t('user.address')" v-model="userInfo.address" validate-on-blur></v-text-field>
                          <v-text-field :label="$t('user.city')" v-model="userInfo.city" validate-on-blur></v-text-field>
                          <v-text-field :label="$t('user.zip_code')" v-model="userInfo.zipCode" validate-on-blur></v-text-field>
                          <v-text-field :label="$t('user.country')" v-model="userInfo.country" validate-on-blur></v-text-field> -->
                        </v-col>
                      </v-row>
                    </section>

                    <section :class="`tab-item ${tab===1? 'active': ''}`" key="regional-settings">
                      <v-row>
                        <v-col class="form-description">
                          <span class="form-required-sign">{{$t('form.required_sign')}}</span>  {{$t('form.required_description')}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select 
                            :label="`${$t('user.language')} ${$t('form.required_sign')}`"
                            :items="languages"
                            v-model="language"
                            :rules="formRules.language" 
                            validate-on-blur
                            @change="changeLanguage"
                          ></v-select>
                          <v-select
                              :label="`${$t('user.timezone')} ${$t('form.required_sign')}`"
                              :rules="formRules.timezone" validate-on-blur
                              :items="sTimeZones"
                              item-text="name"
                              item-value="name"
                              v-model="timezone"
                              attach
                          >
                            <template v-slot:prepend-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-text-field
                                      v-model="searchTimezone"
                                      label="Search" @input="searchTimezones"
                                      prepend-inner-icon="mdi-magnify"
                                      clearable
                                  ></v-text-field>
                                </v-list-item-content>
                              </v-list-item>

                            </template>
                          </v-select>


                          <v-select :label="`${$t('user.date_format')} ${$t('form.required_sign')}`"
                                    :items="dateFormats"
                                    :rules="formRules.required" validate-on-blur
                                    v-model="dateFormat" ></v-select>
                          <v-select :label="`${$t('user.time_format')} ${$t('form.required_sign')}`"
                                    :items="timeFormats"
                                    :rules="formRules.time_format" validate-on-blur
                                    v-model="timeFormat" ></v-select>
                        </v-col>
                      </v-row>
                    </section>
                </v-col>
              </v-row>
            </v-form>

      </v-card-text>
    <v-card-actions>
      <div class="btnContainer">
        <v-btn color="success" @click="save" >
          <v-icon left>mdi-floppy</v-icon>{{$t('user.bnts.save')}}
        </v-btn>
      </div>
    </v-card-actions>
    </v-card>


    <v-dialog
        v-model="formPasswordDisplay"
        persistent
        max-width="600px"
    >
      <v-form ref="userPasswordForm">
      <v-card>
        <v-card-title class="text-h5 blue darken-4 white--text">
          <span class="text-h5">{{ $t('user.change_password_title') }}</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="hideChangePassword">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                {{ $t('user.change_password_desc') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="form-required-sign">{{$t('form.required_sign')}}</span>  {{$t('form.required_description')}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field :label="`${$t('user.password_current')} ${$t('form.required_sign')}`" type="password" v-model="userPassowords.current" :rules="formRules.required" validate-on-blur></v-text-field>
                <v-text-field :label="`${$t('user.password_new')} ${$t('form.required_sign')}`" type="password" v-model="userPassowords.new" :rules="formRules.password" validate-on-blur></v-text-field>
                <v-text-field :label="`${$t('user.password_confirm')} ${$t('form.required_sign')}`" type="password" v-model="userPassowords.confirm" :rules="formRules.password_confirm" validate-on-blur></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="hideChangePassword"
          >
          <v-icon left>mdi-close</v-icon>
            {{ $t('user.bnts.close_dialog') }}
          </v-btn>
          <v-btn color="success" @click="savePassword"
          >
            <v-icon left>mdi-floppy</v-icon>{{ $t('user.bnts.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
  import { getTimeZones} from "@vvo/tzdb";
  import { mapState } from 'vuex';
  import { setLanguage } from '@/lang/config';

  export default {
    name: 'User',
    computed: {
      ...mapState( [
        'user',
      ]),
    },
    data () {
      const rules = {
        required: v => { return (v || '').length > 0 || this.$t('rules.required_field'); },
        email: v => {
          const regex = new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,4})+$', '');
          return (!v) || (regex.test(v)) || this.$t('rules.not_email_field'); },
        phone: v => {
          const regex = new RegExp('^([\\.\\- \\+0-9])+$', '');
          return (!v) || (regex.test(v)) || this.$t('rules.not_phone_field'); },
        selectCheck: (options, field) => {
          return v => {
            let filtered = options.filter(x => x[field] === v);
            return filtered.length > 0;
          }
        },
        password: v => {
          const regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[!"£$%&\\/\\(\\)\\=\\?^\\|/\\\\[\\]+\\-*_;,`~:.<>#§@\'{}]).{14,128}$', '');
          return (!v) || (regex.test(v)) || this.$t('rules.not_password_field');
        },
        password_not_equal_current: (v) => {
          return (this.userPassowords.current !== v) ||  this.$t('rules.not_password_equal_current');
        },
        password_new_confirm: (v) => {
          return (this.userPassowords.new === v) ||  this.$t('rules.not_password_new_confirm');
        }
      };

      const timeFormats = [
        {text: 'HH:mm:ss', value:'HH:mm:ss'},
        {text: 'HH:mm', value:'HH:mm'},
      ];

      const  dateFormats = [
        {text: 'dd/MM/yyyy', value:'dd/MM/yyyy'},
        {text: 'MM/dd/yyyy', value:'MM/dd/yyyy'},
        {text: 'd/M/yy', value:'d/M/yy'},
        {text: 'M/d/yy', value:'M/d/yy'},
      ];

      const tzList = getTimeZones()
      const timeZones = tzList.sort((a, b)=> {
                if ( a.name < b.name || typeof b.name === 'undefined'){
                    return -1;
                }
                if ( a.name > b.name ){
                    return 1;
                }
                return 0;
            });

      const languages = [
        {text:'Italiano', value:'it'},
        {text:'English', value:'en'},
      ];

      return {
        breadcrumbsItems: [ 
          { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home'},
          { href: '/user', disabled: true, text: this.$t("user").toUpperCase()}
        ], 
        tab: null,
        languages:languages,
        timezones: timeZones,
        dateFormats:dateFormats,
        timeFormats:timeFormats,
        formRules:{
          required: [rules.required],
          email: [rules.required, rules.email],
          altEmail: [rules.email],
          phone: [rules.phone],
          language:  [rules.required, rules.selectCheck(languages, 'value')],
          date_format:  [rules.required, rules.selectCheck(dateFormats, 'value')],
          time_format:  [rules.required, rules.selectCheck(timeFormats, 'value')],
          timezone : [rules.required, rules.selectCheck(timeZones, 'name')],
          password: [rules.required, rules.password, rules.password_not_equal_current],
          password_confirm: [rules.required, rules.password_new_confirm]
        },
        userInfo: {configParams: {}},
        userInfoInitial: {},
        userPassowords:{
          current: '',
          new: '',
          confirm: ''
        },
        formPasswordDisplay: false,
        searchTimezone: '',
        sTimeZones: [...timeZones],
        sTimeZonesCopy: [...timeZones],
        language: null,
        timezone: null,
        dateFormat: null,
        timeFormat: null
      }
    },
    created: async function() {
      
      await this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);

      this.userInfo = {configParams: {}, ...this.user}
      this.language = this.user.configParams.language;
      this.timezone =this.user.configParams.timezone;
      this.dateFormat =this.user.configParams.dateFormat;
      this.timeFormat = this.user.configParams.timeFormat;

      await this.$store.dispatch('_setLoading', true);

      let res = {configParams: {}};
      try{
         res = await this.$store.dispatch('_getUser')
      }catch(e){
        this.$store.dispatch('_snackbarMessage', {
          status: 'error',
          message: 'Error loading user data'
        });
        await this.$store.dispatch('_setLoading', false);
      }

      this.userInfo = res;
      this.language = this.user.configParams.language;
      this.timezone =this.user.configParams.timezone;
      this.dateFormat =this.user.configParams.dateFormat;
      this.timeFormat = this.user.configParams.timeFormat;

      await this.$store.dispatch('_setLoading', false);
    },
    methods: {
      searchTimezones() {
        if (!this.searchTimezone) {
          this.sTimeZones = this.sTimeZonesCopy;
          return;
        }

        this.sTimeZones = this.sTimeZonesCopy.filter((zone) => {
          return zone.name.toLowerCase().indexOf(this.searchTimezone.toLowerCase()) > -1;
        });
      },
      async save(){
          if(!this.$refs.userForm.validate()){
            this.$store.dispatch('_snackbarMessage', {
              status: 'error',
              message: this.$t('user.form.not_valid')
            });
          return false;
        }

        await this.$store.dispatch('_updateUser', {
          data: {
          ...this.userInfo,
          configParams : {
            ...this.user.configParams || {},
            language: this.language,
            timezone: this.timezone,
            dateFormat: this.dateFormat,
            timeFormat: this.timeFormat,
            dateTimeFormat: `${this.dateFormat} ${this.timeFormat}` }
          }
        })

      },
      async savePassword(){
        if(!this.$refs.userPasswordForm.validate()){
          return false;
        }

        await this.$store.dispatch('_changePassword',{
          currentPwd: this.userPassowords.current,
          newPwd: this.userPassowords.new,
          confirmNewPwd: this.userPassowords.confirm
       })
      },
      showChangePassword(){
        this.formPasswordDisplay = true;
      },
      hideChangePassword(){
        this.formPasswordDisplay = false;
        this.$refs.userPasswordForm.reset();
      },
      changeLanguage(){
        setLanguage(this.language)
      }
    }
  }
</script>

<template>
  <v-container class="px-0">
  </v-container>
</template>

<script>
  export default {
    name: '3DEditor',

    data () {
      return {
        breadcrumbsItems: [ 
          { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home'},
          { href: '/3d_editor', disabled: true, text: this.$t("3d_editor").toUpperCase()}
        ]  
      }  
    },
    created: function() {
      this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);
    },
    methods: {}
  }
</script>

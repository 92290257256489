<template>
  <div class="buttonContainer">   

    <v-tooltip left v-for="(item, i) in dataset[routeFrom]" :key="i" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab 
          small
          :to="item.route" 
          @click="onClick ? onClick(): undefined" 
          :color="item.color"
          v-bind="attrs"
          v-on="on"
          :disabled="item.disabled"
        >
          <v-icon>{{item.icon}}</v-icon>
        </v-btn>
      </template>
      <span>{{item.tooltip}}</span>
    </v-tooltip>
    
  </div>
</template>

<style scoped>

  .buttonContainer{
    position: fixed;
    top: 76px;
    right: 10px;
    width: 64px;
    text-align: center;
    line-height: 48px;
  }
</style>

<script>
export default {
  name:'SecondaryMenu',
   props: [
    'routeFrom',
    'entityId',
    'onClick',
  ],
  data: function() { 
    return {
      dataset: {}
  }},
  methods: {
    updateDataset: function(){
      this.dataset = {
        '/dashboard': [
          {color: "success",  route:`/dashboard/area/0/new`, icon:'mdi-plus', tooltip: "New Area"},
          {color: "primary",  route:`/dashboard/area/${this.entityId}/edit`, icon:'mdi-pencil', tooltip: "Edit Area" },
        ],
        '/dashboard/area': [
          {color: "success",  route:`/dashboard/area/0/new`, icon:'mdi-plus', tooltip: "New Area"},
          {color: "primary",  route:`/dashboard/area/${this.entityId}/edit`, icon:'mdi-pencil', tooltip: "Edit Area" },
        ],
        '/dashboard/structure': [
          {color: "success",  route:`/dashboard/structure/0/new`, icon:'mdi-plus', tooltip: "New Structure"},
          {color: "primary",  route:`/dashboard/structure/${this.entityId}/edit`, icon:'mdi-pencil', tooltip: "Edit Structure" },
          {color: "success",  route:`/alerts/edit/structure/${this.entityId}`, icon:'mdi-alert-plus', tooltip: this.$t('secondary_menu.new_alert') },
          {color: "success",  route:`/ensembles/list/structure/${this.entityId}`, icon:'mdi-folder-multiple-plus-outline', tooltip: "New Ensemble" },
        ],
        '/dashboard/element': [
          {color: "success",  route:`/dashboard/element/0/new`, icon:'mdi-plus', tooltip: "New Element"},
          {color: "primary",  route:`/dashboard/element/${this.entityId}/edit`, icon:'mdi-pencil', tooltip: "Edit Element" },
          {color: "success",  route:`/alerts/edit/element/${this.entityId}`, icon:'mdi-alert-plus', tooltip: this.$t('secondary_menu.new_alert') },
          {color: "success",  route:`/ensembles/list/element/${this.entityId}`, icon:'mdi-folder-multiple-plus-outline', tooltip: "New Ensemble" },
        ],
        '/dashboard/point': [
          {color: "primary",  route:`/dashboard/point/${this.entityId}/edit`, icon:'mdi-pencil', tooltip: "Edit Point" },
        ],
        '/alerts/list':[
          {color: "success", icon:'mdi-plus', tooltip: "New Alert" },
        ],
        '/ensembles/list':[
          {color: "success", icon:'mdi-plus', tooltip: "New Ensemble" },
        ]
      };
    }
  },
  created: function(){
    this.updateDataset();
  },
  watch: {
    // every change on entity id, must be passed to private variable
    entityId: function () {
      this.updateDataset();
    } 
  }
}
</script>

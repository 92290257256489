import api from "@/_helpers/api";

export default {
    _get2DInspectionData: async (context, args) => {

        console.log(context);

        const response = await api.call({
            url: `plants/2dview/${args}`,
            method: "get",
        });

        if (!response) return;

        const { result, data } = response;

        if (!result) return;

        return data;
    },

}
<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        clearable
        @click:clear="clearDate()"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable no-title @input="menu = false">
      <v-btn text color="primary" @click="clearDate">Clear </v-btn>
    </v-date-picker>
  </v-menu>
  
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    id: String,
    label: String,
    timestamp: Number,
  },

  data: function () {
    return {
      dateFormat: this.$store.state.user.configParams.dateFormat || 'dd/MM/yyyy',
      datePickerFormat: 'yyyy-MM-dd', // date picker accepts only this format 
      menu: false,
      date: null,
      lock: false,
    };
  },

  computed: {
    
    computedDateFormatted (){
      return this.formatDate(this.date);
    }

  },

  created() {
    this.date = this.formatDateToPicker(this.timestamp);
  },

  watch: {
    
    date(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.$emit("update:timestamp", {
          id: this.id,
          timestamp: this.formatDateToMillis(newVal)
        });
      }      
    },
    
    timestamp(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.date = this.formatDateToPicker(newVal);
      }
    },
  },

  methods: {
 
    formatDateToPicker(timestamp) {
      if (!timestamp) {
        return null;
      }
      return this.$date.fromMillis(timestamp*1).toFormat(this.datePickerFormat);
    },

    formatDate(date) {
      if (!date) {
        return null;
      }      
      return this.$date.fromFormat(date, this.datePickerFormat).toFormat(this.dateFormat);
    },

    formatDateToMillis(date) {
      if (!date) {
        return null;
      }      
      return this.$date.fromFormat(date, this.datePickerFormat).toMillis();
    },

    clearDate() {
      this.date = null;
    },

  },
};
</script>

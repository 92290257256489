<template>
  <div>
    <v-card flat >
      <v-card-title primary-title>
        <v-icon left color="green darken-2">mdi-battery-10</v-icon>
        {{ $t('batteries') }}
      </v-card-title>
      
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="batteryHeaders"
          :items="batteryItems" 
          :server-items-length="totalItems"
          item-key="id"
          :items-per-page.sync="take"
          class="tableRow"
          :page="pageNum"
          @update:page="pageChange"
          @update:items-per-page="takeChange"
        > 
        </v-data-table>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
export default {
  data: function() {
    return {        
       batteryItems:[],
       batteryHeaders: [  
          { text: this.$t("Structure"),   value: "structureLabel",  sortable: true, },
          { text: this.$t("Point"),       value: "pointLabel",      sortable: true, },
          { text: this.$t("Voltage"),     value: "voltage",         sortable: true, }
        ],
        pageNum: 1,
        totalItems: 0,
        take: 5
      }
  },
  methods: {
    async loadData() {
      this.loading = true
      const { batteryItems, totalItems } = await this.$store.dispatch('_getBatteriesStatus', {page: this.pageNum, take: this.take})

      this.batteryItems = batteryItems;
      this.totalItems = totalItems;
      this.loading = false
    },
    async pageChange(newPage) {
      this.pageNum = newPage;
      await this.loadData();
    },
    async takeChange(newVal){
      this.take = newVal;
      await this.loadData();
    }
  },
  created: async function() {
    await this.loadData();
  }
}
</script>

<template>
      <v-card flat>
        
        <v-card-title primary-title>
          <v-icon left color="info">mdi-information</v-icon>
          {{ $t('info') }}
        </v-card-title>

        <v-card-text>

         <v-simple-table :loading="loading"  loading-text="Loading... Please wait" class="tableRow">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in infoItems"
                  :key="item.id"
                >
                  <td>{{ item.text }}:</td>
                  <td>
                    <span v-if="item.type == 'alerts' && item.value > 0">{{ item.value }} <v-icon color="red" >mdi-circle</v-icon>
                      &nbsp;
                      <v-btn fab small color="primary" :to="item.route">
                        <v-icon>mdi-alert-outline</v-icon>
                      </v-btn>                      
                    </span>
                    <span v-else-if="item.type == 'alerts' && item.value == 0">{{ item.value }} <v-icon color="green">mdi-circle</v-icon></span>                        
                    <span v-else>{{ item.value }}</span>            
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>
</template>

<script>

export default {
 props: [
    'loading',
    'infoItems',
  ],
  components: {
    // InfoEdit,
  },
}
</script>

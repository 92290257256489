<template>
  <v-container class="px-0">
  </v-container>
</template>

<script>
  export default {
    name: 'SensorManagement',

    data () {
      return {
        breadcrumbsItems: [ 
          { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home'},
          { href: '/sensor_management', disabled: true, text: this.$t("sensor_management").toUpperCase()}
        ]  
      }  
    },
    created: function() {
      this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);
    },
    methods: {}
  }
</script>

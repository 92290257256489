<template>
  <div>
    <v-card flat>
      <v-card-title style="margin-bottom: 58px" primary-title>
        <v-icon left color="info">mdi-message-text</v-icon>
        {{ $t("last_messages") }}
      </v-card-title>

      <v-card-text>
        <v-data-table
          :items="messages"
          :headers="headers"
          :items-per-page="5"
          :loading="loading"
          loading-text="Loading... Please wait"
          >
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: [
    'messages',
    'loading'
  ],
  data () {
    return {
      headers: [
          {
            text: 'Date',
            value: 'messageTime',
          },
          {
            text: 'Message Type',
            value: 'messageType',
          },
       ],
    }
  }
};
</script>

<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab key="structure-alerts"> Structure Alerts </v-tab>
      <v-tab key="system-alerts"> System Alerts </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="structure-alerts">
        <v-card flat>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="alertsStructure"
              class="tableRow"
              :loading="loading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  v-bind="item"
                  @click="ackAlert(item)"
                  text
                  icon
                  color="primary"
                >
                  <v-icon>mdi-message-question</v-icon>
                </v-btn>
                <v-btn
                  v-bind="item"
                  @click="viewItem(item)"
                  text
                  icon
                  color="primary"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <div class="text-truncate">
                  {{ ($date.fromISO(item.date)).toFormat(CONF.dateTimeFormat || 'dd/MM/yyyy HH:mm:ss')  }}
                </div>
              </template>
              <template v-slot:[`item.ack`]="{ item }">
                <v-icon color="green" v-if="item.ack">mdi-check-underline</v-icon>
              </template>
              <template v-slot:[`item.ackNote`]="{ item }">
                <div class="text-truncate" style="max-width: 130px">
                  {{ item.ackNote }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog
          v-model="showAck"
          overlay-opacity="0.4"
          overlay-color="#ffffff"
          persistent
          width="500"
        >
          <v-card flat>
            <v-card-title class="text-h5 blue darken-4">
              <v-toolbar-title>{{ selectedItem.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="onCancel()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-textarea
                rows="10"
                label="Note"
                v-model="selectedItem.ackNote"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="loadingAck" @click="saveAck()" color="success">
                <v-progress-circular
                  v-if="loadingAck"
                  indeterminate
                  color="info"
                  :size="20"
                />
                <span>Acknowledge</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item key="system-alerts">
        <v-card flat>
          <v-card-text>
            <v-data-table :headers="headers" :items="alertsSystem">
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  v-bind="item"
                  @click="viewItem(item)"
                  text
                  icon
                  color="primary"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <div class="text-truncate">
                  {{ ($date.fromISO(item.date)).toFormat(CONF.dateTimeFormat || 'dd/MM/yyyy HH:mm:ss')  }}
                </div>
              </template>
              <template v-slot:[`item.ack`]="{ item }">
                <v-icon color="green" v-if="item.ack">mdi-check-underline</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <Active-alert-view
      :show="isView"
      :isView="isView"
      :selectedItem="selectedItem"
      :onCancel="() => (isView = false)"
      @refresh="loadList"
    />
  </div>
</template>

<script>
import ActiveAlertView from "./ActiveAlertView.vue";
import { mapState } from 'vuex';

export default {
  components: { ActiveAlertView },
  data() {
    return {
      tab: null,
      CONF: this.$store.state.user.configParams,
      headers: [
        { text: "Name", value: "name" },
        // { text: 'Type', value: 'type' },
        { text: "Description", value: "description" },
        { text: "Object", value: "sourceName" },
        { text: "Structure", value: "structure" },
        { text: "Element", value: "element" },
        { text: "Ack", value: "ack" },
        { text: "Ack by", value: "ackBy" },
        { text: "Ack Note", value: "ackNote", width: "200px" },
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        {
          text: "Counter",
          value: "raiseCounter",
        },
        { text: "Actions", value: "actions" },
      ],
      alertsStructure: [],
      alertsSystem: [],
      selectedItem: {},
      showAck: false,
      loadingAck: false,
      isView: false,
    };
  },
  computed: {
    ...mapState(['plantId'])
  },
  watch: {
    plantId(newVal, oldVal) {
      if(newVal !== oldVal){
        this.loadList()
      }
    }
  },
  methods: {
    async loadList() {
      this.loading = true;
      let data = await this.$store.dispatch("_listAlertActive");
      this.alertsStructure = data.filter((x) => x.alertType === "STRUCTURE");
      this.alertsSystem = data.filter((x) => x.alertType === "SYSTEM");
      console.log("struct", this.alertsStructure);
      console.log("sys", this.alertsSystem);
      this.loading = false;
    },
    ackAlert(item) {
      this.selectedItem = item;
      this.showAck = true;
    },
    viewItem(item) {
      this.selectedItem = item;
      this.isView = true;
    },
    onCancel() {
      this.selectedItem = {};
      this.showAck = false;
    },
    async saveAck() {
      this.loadingAck = true;

      await this.$store.dispatch("_ackAlert", {
        alertId: this.selectedItem.alertId,
        data: {
          message: this.selectedItem.ackNote,
          property: this.selectedItem.property,
        },
      });

      this.loadingAck = false;
      this.onCancel();
      this.loadList();
    },
  },
  created: function () {
    this.loadList();
  },
};
</script>

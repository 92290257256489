import api from "@/_helpers/api";

export default {
  _getElement: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/elements/${args.elementId}`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getElementEnsemble: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/elements/${args.id}/ensembles`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getElements: async (context) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/elements`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _createElement: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/elements/`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _updateElement: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/elements/${args.elementId}`,
      method: "put",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _deleteElement: async (context, args) => {
    const response = await api.call({
      url: `plant/${context.state.plantId}/dash/elements/${args.elementId}`,
      method: "DELETE",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

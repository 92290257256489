import api from "@/_helpers/api";

export default {
  _getArea: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/dash/areas/${args.entityId}`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getAreas: async (context) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/dash/areas`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _createArea: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/dash/areas`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _updateArea: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/dash/areas/${args.areaId}`,
      method: "put",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _deleteArea: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/dash/areas/${args.areaId}`,
      method: "delete",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getAreaAlerts: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/object/${args.areaId}/alerts`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

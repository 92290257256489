<template>
  <v-list class="menuList">
    <v-list-item style="background-color: #3f51b5; height: 64px">
      <v-list-item-content>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-title
              v-bind="attrs"
              v-on="on"
              style="text-align: center"
            >
              <v-img
                alt="SHbox"
                :src="require('../assets/shbox_logo_cube_32x32.png')"
                height="32"
                width="32"
              />
            </v-list-item-title>
          </template>
          <span>SHbox Rev. x.x.x.x</span>
        </v-tooltip>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list-item-group v-model="selectedItem" color="primary">
      <v-list-item v-for="(item, i) in menuItems" :key="i" :to="item.route">
        <v-list-item-content>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title
                v-bind="attrs"
                v-on="on"
                style="text-align: center"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-title>
            </template>
            <span>{{ item.tooltip }}</span>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<style scoped>
.menuList {
  height: 100vh;
  border-right: 1px solid silver !important;
  background-color: #f1f0ff !important;
}
.v-list-item--active {
  border-right: 2px solid #3f51b5;
  padding-left: 19px;
}

.v-list {
  padding: 0px;
}
</style>

<script>
export default {
  data: function () {
    return {
      selectedItem: 1,
      menuItems: [
        {
          title: this.$t('home'),
          icon: 'mdi-home-outline',
          tooltip: this.$t('home_tooltip'),
          route: '/home',
        },
        // { title: this.$t("tree_management"),    icon:"mdi-file-tree-outline",       tooltip: this.$t("tree_management_tooltip"),    route: '/tree_management', },
        {
          title: this.$t('dashboard'),
          icon: 'mdi-monitor-dashboard',
          tooltip: this.$t('dashboard_tooltip'),
          route: '/dashboard',
        },
        {
          title: this.$t('widgets_graphs'),
          icon: 'mdi-chart-line',
          tooltip: this.$t('widgets_graphs_tooltip'),
          route: '/widgets_graphs',
        },
        {
          title: this.$t('ensembles'),
          icon: 'mdi-folder-multiple-outline',
          tooltip: this.$t('ensembles_tooltip'),
          route: '/ensembles/list',
        },
        {
          title: this.$t('alerts'),
          icon: 'mdi-alert-outline',
          tooltip: this.$t('alerts_tooltip'),
          route: '/alerts',
        },
        {
          title: this.$t('sensor_management'),
          icon: 'mdi-access-point',
          tooltip: this.$t('sensor_management_tooltip'),
          route: '/sensorManagement/setup',
        },
        {
          title: this.$t('export'),
          icon: 'mdi-file-excel-box-outline',
          tooltip: this.$t('export_import_tooltip'),
          route: '/sensorManagement/export',
        },
        /*
        {
          title: this.$t('plant_view'),
          icon: 'mdi-factory',
          tooltip: this.$t('plant_view_tooltip'),
          route: '/plant_view',
        },
        */
        // { title: this.$t("3d_editor"),          icon:"mdi-cube-outline",            tooltip: this.$t("3d_editor_tooltip"),          route: '/3d_editor', },
      ],
      menuModel: 0,
    };
  },
};
</script>

import { mapState } from "vuex";

const walker = (id, currentTree) => {
  for (let i = 0, lenI = currentTree.length; i < lenI; i++) {
    if (currentTree[i].id === id) {
      return [currentTree[i]];
    }

    if (typeof currentTree[i].children !== "undefined") {
      let child = walker(id, currentTree[i].children);

      if (child) {
        if (!Array.isArray(child)) {
          child = [child];
        }
        return [currentTree[i], ...child];
      }
    }
  }
  return null;
};

export default {
  computed: mapState(["dashboardTree"]),
  watch: {
    dashboardTree() {
      this.loadBreadcrumbs();
    },
  },
  methods: {
    /**
     * Updates breadcrumbs
     * @param id - entityId
     */
    loadBreadcrumbs(id) {
      const breadcrumbsId = id || this.$route.params.entityId;
      this.breadcrumbsItems = this.generateBreadcrumbs(breadcrumbsId);
      this.$store.dispatch("_updateBreadcrumbs", this.breadcrumbsItems);
    },
    /**
     * Check if is edit mode
     * @returns {boolean}
     */
    isEdit() {
      if (typeof this.$route.params.action !== "undefined") {
        return this.$route.params.action === "edit";
      }

      let parts = window.location.href.split("/");
      let lastSegment = parts.pop() || parts.pop(); //Note - gestisce potenziali / finali
      return lastSegment === "edit";
    },
    isDashboardHome() {
      return typeof this.$route.params.entityId !== "undefined";
    },
    generateBreadcrumbs(id) {
      const isEdit = this.isEdit();

      const path = [
        {
          href: "/home",
          disabled: false,
          text: this.$t("home").toUpperCase(),
          to: "/home",
        },
        //Note - per visualizzare il link disabled: false,con to:/dashboard bisogna aggiungere exact: true
        //       in questo casi si utilizza questo stratagemma per resettar eil parametro entityId
        {
          disabled: !this.isDashboardHome(),
          text: this.$t("dashboard").toUpperCase(),
          to: "/dashboard/.",
        },
      ];

      if (
        !Array.isArray(this.$store.state.dashboardTree) ||
        this.$store.state.dashboardTree.length === 0
      ) {
        return path;
      }

      const breadcrumbsArray = walker(id, this.$store.state.dashboardTree);

      if (breadcrumbsArray) {
        for (let i = 0, lenI = breadcrumbsArray.length; i < lenI; i++) {
          path.push({
            exact: true,
            disabled: i === lenI - 1 && !isEdit,
            text: breadcrumbsArray[i].name,
            to: breadcrumbsArray[i].to,
          });
        }

        if (isEdit) {
          path.push({ disabled: true, text: this.$t("edit").toUpperCase() });
        }
      }

      return path;
    },
  },
};

import SysDataManager from './SysDataManager.js'
import * as d3 from 'd3'

/**
 * Creates a custom filter component
 * @class
 */
export default class SysFilter {

  #ref;
  #filter;
  #mode;
  #transitionTime;
  #width;
  #dataset;
  #dm;
  #filterUrl;
  #filterCallback;
  #searchUrl;
  #searchCallback;
  #handleFilterCallback
  #ensembleFamiliesSelect;
  #ensemblesSelect;
  #measureSelect;
  #filters;
  #path;
  #_measuresLimit;
  #_ensemblesLimit;

  /**
   * 
   * @param {Object} params - allowed params: 
   *  ref {Object} - graph object
   *  transitionTime {Number}  
   *  mode {String} - allowed params: MULTIPLE_PARAMS, MULTIPLE_ENSEMBLES
   *  filterUrl {String} - url used to get filters data
   *  searchUrl {String} - url used to search graph data
   *  searchCallback
   *  handleFilterCallback
   */
  constructor(params) {
    this.#ref = params.ref;
    this.#mode = params.mode || "MULTIPLE_PARAMS" // MULTIPLE_PARAMS vs MULTIPLE_ENSEMBLES
    this.#transitionTime = params.transitionTime || 500;
    this.#width = params.width || 250;
    this.#filterUrl = params.filterUrl || null;
    this.#filterCallback = params.filterCallback || null;
    this.#searchUrl = params.searchUrl || null;
    this.#searchCallback = params.searchCallback || null;
    this.#handleFilterCallback = params.handleFilterCallback || null;
    this.#dm = new SysDataManager();
    this.#ensembleFamiliesSelect = null;
    this.#ensemblesSelect = null;
    this.#measureSelect = null;
    this.#filters = {};
    this.#path = this.#ref.getPath();
    this.#_measuresLimit = 2;
    this.#_ensemblesLimit = 10;

    // load main filter data
    this.queryFilter();

  }

  /**
   * Sets search mode
   * @param {String} mode 
   */
  setMode(mode) {
    this.#mode = mode;
  }

  /**
   * Does the call to get filtered data
   */
  async queryFilter() {
    if (this.#filterCallback) {
      const data = await this.#filterCallback()
      this.#dataset = data;
      this.render();
    } else {
      this.#dm.remoteCall("POST", this.#filterUrl)
        .then(data => {
          this.#dataset = data;
          this.render();
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  /**
   * Renders family select
   */
  renderFamily() {
    this.fillSelect(this.#ensembleFamiliesSelect, this.parseEnsembleFamily());

    // if there's a saved param, select it
    if (this.#filters.familyList !== null && this.#filters.familyList !== undefined) {
      this.#ensembleFamiliesSelect.property('value', this.#filters.familyList[0]).dispatch('change');
    }
  }

  /**
   * Renders ensemble select
   * @param {Number} familyId 
   */
  renderEnsemble(familyId) {

    if (familyId !== "" && familyId !== null && familyId !== undefined) {

      this.fillSelect(this.#ensemblesSelect, this.parseEnsemble(familyId));

      // if there's a saved param, select it
      // can be a multiple select
      if (this.#filters.ensembleList !== null && this.#filters.ensembleList !== undefined) {
        this.#ensemblesSelect.selectAll("option").nodes().forEach(element => {
          if (this.#filters.ensembleList.indexOf(element.value * 1) > -1) {
            element.selected = true;
          }
        });
      }
      this.#ensemblesSelect.dispatch('change');
    }
  }

  /**
   * Renders measures select
   * @param {String} ensembleId 
   */
  renderMeasures(ensembleId) {
    if (ensembleId !== "" && ensembleId !== null && ensembleId !== undefined) {

      this.fillSelect(this.#measureSelect, this.parseMeasures(ensembleId));

      // if there's a saved param, select it
      // can be a multiple select
      if (this.#filters.measureList !== null && this.#filters.measureList !== undefined) {
        this.#measureSelect.selectAll("option").nodes().forEach(element => {
          if (this.#filters.measureList.indexOf(element.value * 1) > -1) {
            element.selected = true;
          }
        });
      }
    }
  }

  /**
   * Rebuilds family data in a more useful format
   * @returns {object}
   */
  parseEnsembleFamily() {
    let result = [];

    for (let record in this.#dataset) {
      if (!result.find(x => x.value === this.#dataset[record].algorithmId)) {
        result.push({ value: this.#dataset[record].algorithmId, text: this.#dataset[record].algorithm });
      }
    }
    return result;
  }

  /**
   * Rebuilds ensemble data in a more useful format
   * @param {Number} familyId 
   * @returns {object}
   */
  parseEnsemble(familyId) {
    let result = [];
    for (let record in this.#dataset) {
      if (!result.find(x => x.value === this.#dataset[record].ensembleId) && this.#dataset[record].algorithmId * 1 === familyId * 1) {
        result.push({ value: this.#dataset[record].ensembleId, text: this.#dataset[record].label });
      }
    }

    // sort by label
    result.sort((a, b) => {
      return ((a.text < b.text) ? -1 : ((a.text > b.text) ? 1 : 0));
    })

    return result;
  }

  /**
   * Rebuilds measure data in a more useful format
   * @param {String} ensembleId 
   * @returns 
   */
  parseMeasures(ensembleId) {

    let result = [];
    let tmp = this.#dataset[ensembleId].measures;

    // sort alphabetically
    function compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }

    tmp.sort(compare);

    for (let i = 0, l = tmp.length; i < l; i++) {
      result.push({ value: tmp[i].id, text: tmp[i].label })
    }



    return result;
  }

  /**
   * Fills target select with right data
   * @param {String} target - HTML target id 
   * @param {Object} data 
   */
  fillSelect(target, data) {

    // remove old options
    target.selectAll("option").remove();

    // prepend "select" option
    data.unshift({ value: "", text: "--Select--" });

    // add new options
    target.selectAll("option")
      .data(data)
      .enter()
      .append("option")
      .text(function (d) {
        return d.text;
      })
      .attr("value", function (d) {
        return d.value;
      });
  }

  /**
   * Creates HTML fragment to fill filter menu
   * @returns HTML string 
   */
  createHTML() {

    if (this.#mode === "MULTIPLE_PARAMS") {

      return `
<div id="lineChartFilter" class="sys-lc-filter" style="width: 0px, height 0px">
  <div class="sys-lc-btn-container sys-lc-close-btn">
    <img src="${this.#path}/images/ic_clear_24px_white.svg" class="sys-lc-btn" title="Close">
  </div>
  <div id="lineChartActionFilter">
    <label><img class="sys-lc-menu-icon" src="${this.#path}/images/ic_search_24px.svg">Filter</label>
    <hr>
    <table>
      <tr>
        <td class="column_label">Family</td>
        <td>
          <select id="slc_${this.#ref.getExternalId()}_ensembleFamilies"></select>
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="column_label">Ensemble</td>
        <td>
          <select id="slc_${this.#ref.getExternalId()}_ensembles"></select>
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="column_label">Measures</td>
        <td>
          <select id="slc_${this.#ref.getExternalId()}_measures" size="5" multiple></select>
        </td>
        <td></td>
      </tr>
    </table>
    <div>
      <div class="sys-lc-btn-container">
        <img id="btn-filter" src="${this.#path}/images/ic_find_replace_24px_white.svg" class="sys-lc-btn" title="Find">
      </div>
    </div>

  </div>
</div>
    `;

    } else {

      return `
<div id="lineChartFilter" class="sys-lc-filter" style="width: 0px, height 0px">
  <div class="sys-lc-btn-container sys-lc-close-btn">
    <img src="${this.#path}/images/ic_clear_24px_white.svg" class="sys-lc-btn" title="Close">
  </div>
  <div id="lineChartActionFilter">
    <label><img class="sys-lc-menu-icon" src="${this.#path}/images/ic_search_24px.svg">Filter</label>
    <hr>
    <table>
      <tr>
        <td class="column_label">Family</td>
        <td>
          <select id="slc_${this.#ref.getExternalId()}_ensembleFamilies"></select>
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="column_label">Ensembles</td>
        <td>
          <select id="slc_${this.#ref.getExternalId()}_ensembles" size="5" multiple></select>
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="column_label">Measure</td>
        <td><select id="slc_${this.#ref.getExternalId()}_measures"></select></td>
      </tr>
    </table>
    <div>
      <div class="sys-lc-btn-container">
        <img id="btn-filter" src="${this.#path}/images/ic_find_replace_24px_white.svg" class="sys-lc-btn" title="Find">
      </div>
    </div>

  </div>
</div>
    `;

    }

  }

  /**
   * Renders filter
   */
  render() {

    let that = this;
    let target = d3.select(`#${this.#ref.getTarget()}`);

    let filterBtn = target.select('.sys-lc-filter-btn');
    let filterContainer = target.select('#sys-filter');

    // create open filter button
    if (filterBtn.size() === 0) {
      filterBtn = target
        .append("div")
        .attr("class", "sys-lc-filter-btn")
        .attr("title", "Filter menu")
        .style("top", `${target.nodes()[0].offsetTop + 2}px`)
        .style("left", `${target.nodes()[0].offsetLeft + 2}px`)
        .append("img")
        .attr("src", `${this.#path}/images/ic_search_24px_white.svg`)
        .attr("class", "sys-lc-btn")
        .on("click", () => {
          that.openFilter.call(that);
        });
    }

    // create filter container
    if (filterContainer.size() === 0) {
      filterContainer = target.append("div").attr("id", "sys-filter");
    }

    // update html content
    filterContainer.html(this.createHTML());


    // set filter position
    this.#filter = target.select('#lineChartFilter')
      .style("height", `${this.#ref.outerHeight}px`)
      .style("width", "0px")
      .style("top", `${target.nodes()[0].offsetTop}px`)
      .style("left", `${target.nodes()[0].offsetLeft}px`)
      .style("opacity", 0)

    // set close filter button
    this.#filter.select('img[title="Close"]').on("click", () => {
      that.closeFilter.call(that);
    });


    // save selects
    this.#ensembleFamiliesSelect = this.#filter.select(`#slc_${this.#ref.getExternalId()}_ensembleFamilies`);
    this.#ensemblesSelect = this.#filter.select(`#slc_${this.#ref.getExternalId()}_ensembles`);
    this.#measureSelect = this.#filter.select(`#slc_${this.#ref.getExternalId()}_measures`);

    // SET EVENTS
    this.#ensembleFamiliesSelect.on("change", e => {
      if (e.target.options[e.target.selectedIndex] !== undefined) {
        this.renderEnsemble(e.target.options[e.target.selectedIndex].value);
      }
      // clear also params select
      // don't clear, to avoid concurrence issue with saved params
      // this.#measureSelect.selectAll("option").remove();
    });

    this.#ensemblesSelect.on("change", e => {
      if (e.target.options[e.target.selectedIndex] !== undefined) {
        this.renderMeasures(e.target.options[e.target.selectedIndex].value);
      }
    });

    //FILTER BUTTON
    this.#filter.select('#btn-filter').on("click", (e) => {

      e.preventDefault();

      let params = this.collectFilter();

      if (this.checkFilter(params)) {

        if (params) {

          // filter data and save filters
          this.#ref.handleFilter(this.#searchUrl, params, this.#handleFilterCallback || this.#searchCallback);

          // then close filter panel
          this.closeFilter.call(that);

        }
      }

    });

    // render first select
    this.renderFamily();

  }

  /**
   * Collects filters and prepare them for search
   * {"ensembleList":["OnePoint_hqr5die5gtg"],"measureList":["Direction_VerticalTilt","Temperature_Measure"]}
   * @returns {Object} params object
   */
  collectFilter() {

    let familyList = [];
    let ensembleList = [];
    let measureList = [];
    let obj = {
      familyList,
      ensembleList,
      measureList
    }

    this.#ensembleFamiliesSelect.selectAll("option:checked").each((opt) => {
      familyList.push(opt.value);
    });

    this.#ensemblesSelect.selectAll("option:checked").each((opt) => {
      ensembleList.push(opt.value);
    });

    this.#measureSelect.selectAll("option:checked").each((opt) => {
      measureList.push(opt.value);
    });

    return obj;

  }

  /**
   * Checks if filter params are selected in the right way
   * @params {Object} params filter params : familyList, ensembleList, measureList
   * @returns {Boolean}
   */
  checkFilter(params) {

    if (this.#mode === "MULTIPLE_PARAMS") {
      if (!params.familyList.length) {
        alert(`No family set`);
        return false;
      }

      if (!params.ensembleList.length || typeof params.ensembleList[0] !== "number") {
        alert(`No ensemble set`);
        return false;
      }

      if (!params.measureList.length || params.measureList[0] === "") {
        alert(`No measures set`);
        return false;
      }

      if (params.measureList.length > this.#_measuresLimit) {
        alert(`Max ${this.#_measuresLimit} measures allowed`);
        return false;
      }

    } else {

      if (params.ensembleList.length > this.#_ensemblesLimit) {
        alert(`Max ${this.#_ensemblesLimit} ensembles allowed`);
        return false;
      }
    }

    return true;
  }

  /**
   * Shows filter panel
   */
  openFilter() {
    this.#filter.transition().duration(this.#transitionTime).style("opacity", 0.9).style("width", `${this.#width}px`);
  }

  /**
   * Hides filter panel
   */
  closeFilter() {
    this.#filter.transition().duration(this.#transitionTime).style("opacity", 0).style("width", "0px");
  }

  /**
   * Stores previously saved filters
   * Filters must be stored before being applied, because the update process is called async from main chart, 
   * and at that time filter component couldn't be ready yet
   * @param {Object} params filters to be saved 
   */
  updateFilters(params) {

    this.#filters = params;

    // if there's a saved param, select it
    if (this.#filters.familyList !== null && this.#filters.familyList !== undefined) {

      this.#ensembleFamiliesSelect.property('value', this.#filters.familyList[0]).dispatch('change');
    }


  }

}
import api from "@/_helpers/api";

export default {
  _getEnsembles: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/ensembles`,
      method: "get",
      query: args,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getEnsemble: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/ensembles/${args.ensembleId}`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getEnsembleMeasure: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/ensembles/${args.ensembleId}/measures`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _deleteEnsemble: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/ensembles/${args.ensembleId}`,
      method: "delete",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  getEnsembleEntities: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/ensembles/entities`,
      method: "get",
      query: args.params,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  getEnsembleFamilies: async () => {
    const response = await api.call({
      url: `/ensembles/families`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  getEnsembleAlgorithms: async (context, args) => {
    const response = await api.call({
      url: `/ensembles/algorithms`,
      method: "get",
      query: args.params,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _updateEnsemble: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/ensembles/${args.ensembleId}`,
      method: "put",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _createEnsemble: async (context, args) => {
    const response = await api.call({
      url: `/plant/${context.state.plantId}/ensembles`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _getEnsembleTsStatus: async (context, args) => {
    const response = await api.call({
      url: `/ensembles/ts/status/${args.ensembleId}`,
      method: "get",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _setEnsembleTs: async (context, args) => {
    const response = await api.call({
      url: `/ensembles/ts/save/${args.ensembleId}`,
      method: "post",
      data: args.data,
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _ensembleTsStop: async (context, args) => {
    const response = await api.call({
      url: `/ensembles/ts/stop/${args.ensembleId}`,
      method: "post",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _ensembleTsApply: async (context, args) => {
    const response = await api.call({
      url: `/ensembles/ts/apply/${args.ensembleId}`,
      method: "post",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },

  _deleteEnsembleTs: async (context, args) => {
    const response = await api.call({
      url: `/ensembles/ts/delete/${args.ensembleId}`,
      method: "post",
    });

    if (!response) return;

    const { result, data } = response;

    if (!result) return;

    return data;
  },
};

<template>
  <v-container class="px-0">
    <Filters :onFilter="onFilter" />

    <v-data-table
      :headers="headers"
      :items="dataset"
      class="tableRow"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-bind="item" @click="viewItem(item)" text icon color="primary">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        &nbsp;
        <v-btn v-bind="item" @click="deleteItem(item)" text icon color="error">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <SecondaryMenu routeFrom="/alerts/list" :onClick="newItem" />
    <Confirm
      :show="showDeleteConfirm"
      message="Do you really want to delete this item?"
      title="Delete Alert"
      :onConfirm="onConfirmDelete"
      :onCancel="() => (showDeleteConfirm = false)"
    />
    <Alert-edit
      :show="showEdit || isView"
      :isView="isView"
      :onSave="() => (showEdit = false)"
      :onCancel="() => (showEdit = false, isView = false)"
      :selectedItem="selectedItem"
      :entityId="entityId"
      :entityType="entityType"
      @refresh="loadList"
    />
  </v-container>
</template>


<script>
import Filters from "@/components/shared/Filters";
import SecondaryMenu from "@/components/shared/SecondaryMenu";
import Confirm from "@/components/shared/Confirm";
import AlertEdit from "@/components/alerts/AlertEdit";
import { mapState } from 'vuex';

export default {
  name: "AlertList",
  components: {
    Filters,
    SecondaryMenu,
    Confirm,
    AlertEdit,
  },
  props: ["showEditDialog", "entityType", "entityId"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Name", value: "name", align: "start" },
        // { text: "Ensemble", value: "ensemble", align: "start" },
        { text: "Area", value: "area" },
        { text: "Structure", value: "structure" },
        { text: "Element", value: "element" },
        { text: "Actions", value: "actions" },
      ],
      dataset: [],
      tab: null,
      showDeleteConfirm: false,
      selectedItem: null,
      showEdit: false,
      isView: false,
    };
  },
  computed: {
    ...mapState(['plantId'])
  },
  watch: {
    plantId(newVal, oldVal) {
      if(newVal !== oldVal){
        this.loadList()
      }
    },
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.dataset = await this.$store.dispatch("_listAlertDefinitions")
      this.loading = false;
      this.allItems = this.dataset;
    },
    async onFilter({ area, element, structure }) {
      let filtered = this.allItems;
      this.loading = true;
      if (area) {
        filtered = filtered.filter((x) => x.area === area.label);
      }
      if (element) {
        filtered = filtered.filter((x) => x.element === element.label);
      }
      if (structure) {
        filtered = filtered.filter((x) => x.structure === structure.label);
      }

      this.dataset = filtered;
      this.loading = false;
    },
    editItem(item) {
      this.selectedItem = item;
      this.showEdit = true;
    },
    viewItem(item) {
      console.log("view item", item);
      this.selectedItem = item;
      this.isView = true;
      console.log("isview", this.isView);
    },
    deleteItem(item) {
      this.showDeleteConfirm = true;
      this.selectedItem = item;
    },
    async onConfirmDelete() {
      this.showDeleteConfirm = false;
      this.loading = true;
      await this.$store.dispatch("_deleteAlert", {
        alertId: this.selectedItem.id,
      });
      this.loading = false;
      await this.loadList();
    },
    newItem() {
      this.selectedItem = {};
      this.showEdit = true;
    },
  },
  mounted: function () {
    this.loadList();
    if (this.entityType) {
      const alert = {};
      if (this.entityType === "structure") {
        alert.structureId = parseInt(this.entityId, 10);
      } else {
        alert.elementId = parseInt(this.entityId, 10);
      }
      this.editItem(alert);
      this.$router.replace("/alerts");
    }
  },
};
</script>

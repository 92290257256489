import SysUtility from './SysUtility.js'
import * as d3 from 'd3'

/**
 * Creates a custom color picker
 * @class
 */
export default class SysColorPicker{

    #ref;
    #picker;
    #closeBtn;
    #content;
    #transitionTime;
    #width;
    #height;
    #group;
    #xMargin;
    #yMargin
    #path;
  
    /**
     * 
     * @param {Object} params - allowed params: 
     * ref {Object} - graph reference
     * transitionTime {Number} - time in milliseconds, used in transition effects 
     */
    constructor(params){
      this.#ref = params.ref;
      this.#transitionTime = params.transitionTime || 500;
      this.#width = 205;
      this.#height = 140;
      this.#group = null;
      this.#xMargin = -10;
      this.#yMargin = 30;
      this.#path = this.#ref.getPath();

      this.create();
      this.render();
  
    }
  
    /**
     * create a DOM container
     */
    create(){
  
      var that = this;
  
      // create popup
      this.#picker = d3.select(`#${this.#ref.getTarget()}`)
        .append("div")
        .attr("class", "sys-color-picker")
        .style("width", 0)
        .style("height", 0)
        .style("opacity", 0)
  
      // add close button
      this.#picker.html(`<img src="${this.#path}/images/ic_clear_24px_white.svg" class="sys-lc-btn-small close" title="Close"></img>`);
      this.#closeBtn = this.#picker.select('img.close')
        .style("right", 0)
        .style("position", "absolute"); 
  
      this.#content = this.#picker
        .append("div")
        .attr("class", "sys-picker-content");
  
      // set close menu button
      this.#closeBtn.on("click", ()=> {
        that.close.call(that);
      });
  
    }
  
    /**
     * Renders colors selectors in color container
     */
    render(){

      const colors = [].concat(["#e41a1c", "#377eb8", "#4daf4a", "#984ea3", "#ff7f00", "#ffff33", "#a65628", "#f781bf", "#999999"], 
                               ["#66c2a5", "#fc8d62", "#8da0cb", "#e78ac3", "#a6d854", "#ffd92f", "#e5c494", "#b3b3b3", "#dc6566"]);
      
      let html = "";

      for(let i=0, l=colors.length; i<l; i++){
        html += `<div class="sys-color-picker-cell" data-color="${colors[i]}" style="background-color: ${colors[i]}"></div>`
      }

      this.#content.html(html);

      // set action on cell
      this.#picker.selectAll(".sys-color-picker-cell").on("click", (e) => {
        
        this.#ref.handleGroupColorChange(this.#group, e.target.attributes["data-color"].value);

        this.close();
      });
    }
  
    /**
     * Shows color picker
     * @param {event} e 
     */
    open(e){
      
      this.#group = e.target.attributes["data-group"].value; 

      // manage popup on graph
      let coords = SysUtility.getTooltipPosition(e, this.#picker, this.#ref, this.#xMargin, this.#yMargin, this.#width, this.#height);

      this.#picker.style("top", `${coords.y}px`)
      this.#picker.style("left", `${coords.x}px`)
      this.#picker.style("width", `${this.#width}px`).style("height", `${this.#height}px`).transition().duration(this.#transitionTime).style("opacity", 0.9);
    }
    
    /**
     * Closes color picker
     */
    close(){
      var that = this;
      
      this.#picker.transition().duration(this.#transitionTime).style("opacity", 0).on("end", () => {
        that.#picker.style("width", 0).style("height", 0);
      });
      
    }
  
  }
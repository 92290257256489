import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify'
import { router } from './_helpers';
import i18n from '@/lang/config';
import store from './stores';
import api from './_helpers/api'
// import Fm from '@/../services/fm';
// for MAP
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css'; // needs css-loader
import script from '@/plugins/script';
import { DateTime } from "luxon";
import imageLoading from '@/assets/loading.gif';

// configurations
Vue.config.productionTip = false;

// extend vue adding axios, using this syntax over Vue.prototype.$http = api prevents overwriting
Object.defineProperty(Vue.prototype, '$http', { value: api });
Object.defineProperty(Vue.prototype, '$script', { value: script });
Object.defineProperty(Vue.prototype, '$date', { value: DateTime });

Vue.directive('src-auth', async (el, binding) => {
  if (el.alt === binding.value) {
    return
  }
  el.alt = binding.value;
  const originalStyle = {...el.style};
  el.style.width = '100%';
  el.style.height = '100%';
  el.style.objectFit = 'contain';

  el.src = imageLoading;
  try {
    const response = await api.call({
      url: binding.value,
      method: 'GET',
      responseType: 'blob',
    });
    if (response.error) {
      el.src = '';
      el.alt = '';
      el.dispatchEvent(new CustomEvent('image-loaded', { detail: { notFound: true } }));
    } else {
      const imageUrl = URL.createObjectURL(response);
      el.style = {...originalStyle};
      el.src = imageUrl;
      el.onload = () => {
        el.dispatchEvent(new CustomEvent('image-loaded'));
      };
    }

  } catch (e) {
    console.error('Error loading src auth image', e);
  }
});

// load plugins
Vue.use(VueLayers);
Vue.use(Vuetify);
// Vue.use(Fm);

// some changes to the default themes
const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#3F51B5',
        secondary: '#2196F3',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FF9800',
      },
      dark: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      }
    },
  },

});


const SHBOX = new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: h => h(App),
  created() { },
}).$mount('#app');

export default SHBOX;
<template>
  <div>
    <v-card flat :loading="loading" >
      <v-card-title primary-title>
        <v-icon left color="light-blue lighten-3">mdi-information-outline</v-icon>
        <span primary >{{ $t('links') }}</span>
      </v-card-title>
      
      <v-card-text>
        <v-data-iterator
          :items="linkItems"
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-list dense>
                <v-list-item
                v-for="item in props.items"
                :key="item.linkId"
                >                            
                  <v-list-item-content >
                    <v-btn color="primary" @click="openLink(item.link)" text>{{ item.text}}</v-btn>                                                    
                  </v-list-item-content>
                </v-list-item>
              </v-list>
          </template>    
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  data: function() { 
    return {
      linkItems:[]
    }
  },
  methods: {
    openLink: function (link) {
        window.open(link, "_blank");  
    }
  },
  created: async function() {
    this.loading = true
    this.linkItems = await this.$store.dispatch('_getLinks')
    this.loading = false
  }
}
</script>
